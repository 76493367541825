<template>
    <div class="flexCol alit" style="padding-left: 25px;">
        <canvas v-if="text" class="qr" ref="qrCanvas"></canvas>
        <img src="https://xlqy.obs.cn-southwest-2.myhuaweicloud.com/hotPotNewApp/%E8%AE%A2%E5%8D%95-%E7%BB%93%E7%AE%97.png" style="width: 200px;height: 200px;" v-else alt="">
        <div class="flex alit justc">
            <button class="css-button-arrow--red" @click="payCheck">已完成支付</button>
            <!-- <button class="css-button-arrow--red bottom-submit">关闭</button> -->
        </div>
    </div>
</template>

<script>
import QRCode from 'qrcode'

export default {
    props: {
        text: {
            type: String,
            required: true
        },
        payId: {
            type: Number
        }
    },
    mounted() {
        this.generateQRCode()
    },
    methods: {
        generateQRCode() {
            const canvas = this.$refs.qrCanvas
            canvas.width = 300
            canvas.height = 300

            QRCode.toCanvas(canvas, this.text, error => {
                if (error) console.error(error)
            })
        },
        payCheck() {
            let that = this
            this.$request('/app-api/pay/order/get', {
                id: this.payId
            }, 'get').then(res => {
                if (res.data.status == 10) {
                    this.$message({
                        message: '支付成功！',
                        type: 'success'
                    });
                    this.$emit('paySuccess')
                } else {
                    this.$message({
                        message: '您还未支付或支付失败,请支付成功后再次点击',
                        type: 'error'
                    });
                    this.$emit('payError')
                }
            })
        }
    }
}
</script>
<style scoped>
.qr {
    width: 300px !important;
    height: 300px !important;

}
</style>