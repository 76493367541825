<template>
    <div class="flex detail">
        <div class="">
            <div class="tabs" style="background-color: rgba(180,94,24,.3);">猜你喜欢</div>
        </div>
        <div class="" style="margin-left: 20px;flex: 1;">
            <div class="flex">
                <div :class="tabbsIndex == 1 ? 'tabs-check' : 'tabs'" @click="tabbsIndex = 1">商品详情</div>
                <div :class="tabbsIndex == 2 ? 'tabs-check' : 'tabs'" @click="tabbsIndex = 2">商品基本信息</div>
                <div :class="tabbsIndex == 3 ? 'tabs-check' : 'tabs'" @click="tabbsIndex = 3">商品评论</div>
                <div :class="tabbsIndex == 4 ? 'tabs-check' : 'tabs'" @click="tabbsIndex = 4">商品问答</div>
            </div>
            <div class="goods-desc" v-if="tabbsIndex == 1">
                <div v-html="goodInfo.description" style="width: 100%;"></div>
            </div>
            <div class="" v-if="tabbsIndex == 2" style="padding: 20px;">
                <el-table :data="JSON.parse(goodInfo.attributeJson)" :show-header="false" :cell-style="cellStyle">
                    <el-table-column prop="name" style="background-color: #ccc" label="name" width="180" />
                    <el-table-column prop="value" label="value" width="480" />
                </el-table>
            </div>
            <div class="flexCol alit justc" v-if="tabbsIndex == 3">
                <img src="https://xlqy.obs.cn-southwest-2.myhuaweicloud.com/hotpotNew/goods/%E6%9A%82%E6%97%A0%E4%BF%A1%E6%81%AF-02.png"
                    style="width: 400px;margin: 20px 0;" alt="">
                    <div class="">暂无评论信息~~</div>
            </div>
        </div>


    </div>
</template>
<script>
export default {
    props: {
        goodInfo: {
            type: Object
        }
    },
    data() {
        return {
            tabbsIndex: 1
        }
    },
    methods: {
        cellStyle({
            row,
            column,
            rowIndex,
            columnIndex
        }) {
            if (columnIndex == 1) {
                return {
                    backgroundColor: "#fcfcfc",
                    color: "#5f5f5f",
                    fontSize: '14px',
                };
            } else {
                return {
                    backgroundColor: "#e7e7e7",
                    color: "#000",
                    borderRadius: "15px 0 0 15px",
                    fontSize: '14px'
                };
            }

        }
    }
}
</script>
<style lang="scss">
.goods-desc{
img{
    width: 100%;
    height: auto
}
}
.detail {
    width: 1230px;
    background-color: #fff;
    margin-top: 20px;
    padding: 20px;
}

.tabs-check {
    width: 200px;
    height: 50px;
    margin-right: 3px;
    padding: 0;
    line-height: 50px;
    text-align: center;
    font-size: 14px;
    color: #424242;
    background: #c5aa7b;
    border: none;
}

.tabs {
    width: 200px;
    height: 50px;
    margin-right: 3px;
    padding: 0;
    line-height: 50px;
    text-align: center;
    font-size: 14px;
    color: #424242;
    background: rgba(226, 119, 31, .3);
    border: none;
    cursor: pointer;
}
</style>