<!--  -->
<template>
    <div class=''>
        <div class="" style="height: 70vh;overflow: scroll;overflow-x: hidden" v-if="wlInfo.queryPoll">
            <el-timeline>
                <el-timeline-item v-for="(activity, index) in wlInfo.queryPoll.data" :key="index"
                    :timestamp="activity.time">
                    {{ activity.context }}
                </el-timeline-item>
            </el-timeline>
        </div>
        <div class="" v-else>
            暂无物流信息
        </div>
        <div class="flex justc" >
            <button class="canal" @click="canal">关闭</button>
        </div>
    </div>
</template>

<script>

export default {
    components: {},
    props: {
        wlInfo: {
            type: Object
        }
    },
    data() {
        return {

        }
    },
    // 监听属性 类似于data概念
    computed: {},
    // 监控data中的数据变化
    watch: {},
    // 方法集合
    methods: {
        canal(){
            this.$emit('canal')
        }
    },
    // 生命周期 - 创建完成（可以访问当前this实例）
    created() {

    },
    // 生命周期 - 挂载完成（可以访问DOM元素）
    mounted() {

    },
    beforeCreate() { }, // 生命周期 - 创建之前
    beforeMount() { }, // 生命周期 - 挂载之前
    beforeUpdate() { }, // 生命周期 - 更新之前
    updated() { }, // 生命周期 - 更新之后
    beforeDestroy() { }, // 生命周期 - 销毁之前
    destroyed() { }, // 生命周期 - 销毁完成
    activated() { } // 如果页面有keep-alive缓存功能，这个函数会触发
}
</script>
<style lang='scss' scoped>
.canal {
    width: 200px;
    height: 50px;
    background: #f3f4f5;
    border-radius: 0;
    border: none;
    cursor: pointer;
    margin-bottom: 0px !important;
    margin-top: 10px;
}
</style>