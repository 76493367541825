<template>
	<div class="category" ref="content">
		<div style="position: relative" class="no-copy">
			<img src="https://qgst.obs.cn-southwest-2.myhuaweicloud.com/hg/home/%E7%BB%84%2012426%402x.png"
				style="width: 100%" />
			<div class="flex alit justc top-center-qiu">
				<img src="https://qgst.obs.cn-southwest-2.myhuaweicloud.com/hg/home/_1earth.gif" class="" />
			</div>
			<div class="flex alit">
				<!-- <input type="file" ref="fileInput" @change="upImage"> -->
				<div class="top-style flexCol alit" style="width:100%;">
					<div class="top-title flex alit">重 庆 <div style="margin-top:-35px;margin:-35px 20px 0 20px">.</div> 向 世 界 火 锅 之 都 进 军</div>
					<div class="top-desc">千亿级火锅消费、千亿级火锅底料生产、千亿级食材供应、千亿级预制菜加工、千亿级火锅酒饮甜品及餐具器皿</div>
					<div class="flex justb" style="width:65%;margin-top: 40px;">
						<div class="flexCol alit">
							<img src="https://qgst.obs.cn-southwest-2.myhuaweicloud.com/hg/home/%E5%AE%B9%E5%99%A8%402x.png"
								style="width:80px;height:80px;" />
							<div class="top-num">30+</div>
							<div class="top-num-desc">国家分布</div>
						</div>
						<div class="flexCol alit">
							<img src="https://qgst.obs.cn-southwest-2.myhuaweicloud.com/hg/home/%E5%AE%B9%E5%99%A8%402x%281%29.png"
								style="width:80px;height:80px;" />
							<div class="top-num">1700+</div>
							<div class="top-num-desc">企业会员</div>
						</div>
						<div class="flexCol alit">
							<img src="https://qgst.obs.cn-southwest-2.myhuaweicloud.com/hg/home/%E5%AE%B9%E5%99%A8%402x%282%29.png"
								style="width:80px;height:80px;" />
							<div class="top-num">100万+</div>
							<div class="top-num-desc">就业人数</div>
						</div>
						<div class="flexCol alit">
							<img src="https://qgst.obs.cn-southwest-2.myhuaweicloud.com/hg/home/%E5%AE%B9%E5%99%A8%402x%283%29.png"
								style="width:80px;height:80px;" />
							<div class="top-num">720亿+</div>
							<div class="top-num-desc">火锅门店消费额</div>
						</div>
						<div class="flexCol alit">
							<img src="https://qgst.obs.cn-southwest-2.myhuaweicloud.com/hg/home/%E5%AE%B9%E5%99%A8%402x%284%29.png"
								style="width:80px;height:80px;" />
							<div class="top-num">3000亿+</div>
							<div class="top-num-desc">全产业链规模</div>
						</div>
					</div>
				</div>
			</div>
			<div class="top-bottom flexCol alit">
				<div class="name">构建万亿级火锅产业新格局</div>
				<img src="https://qgst.obs.cn-southwest-2.myhuaweicloud.com/hg/home/tiaoxian2.png" class="tiaoxian" />
				<div class="flexCol alit" style="margin: 60px 0 75px 0;position: relative;z-index:99">
					<div class="lc-top flexCol alit justb" @click="goNext('/origin')" style="cursor: pointer;">
						<div class="lc-title">原产地直供</div>
						<div class="flex alit">
							<img src="https://qgst.obs.cn-southwest-2.myhuaweicloud.com/hg/home/%E5%AE%B9%E5%99%A8%402x%287%29.png"
								style="width:22px;height:22px;" />
							<div class="lc-yw">Country of origin</div>
						</div>
						<div class="lc-desc" style="margin-bottom: 0;">智慧农业实现全生命周期精准管理</div>
						<div class="lc-more">了解更多...</div>
					</div>
					<div class="flex" style="margin-top: -40px;">
						<div class="lc-center flexCol alit justb">
							<div class="lc-title">供应商优选</div>
							<div class="flex alit">
								<img src="https://qgst.obs.cn-southwest-2.myhuaweicloud.com/hg/home/%E5%AE%B9%E5%99%A8%402x%285%29.png"
									style="width:22px;height:22px;" />
								<div class="lc-yw">Supplier</div>
							</div>
							<div class="lc-desc">供应链金融助力产业建圈强链</div>
							<!-- <div class="lc-more">了解更多...</div> -->
						</div>
						<div class="lc-center flexCol alit justb" style="margin-left: 385px;">
							<div class="lc-title">生产加工精品</div>
							<div class="flex alit">
								<img src="https://qgst.obs.cn-southwest-2.myhuaweicloud.com/hg/home/%E5%AE%B9%E5%99%A8%402x%286%29.png"
									style="width:22px;height:22px;" />
								<div class="lc-yw">Manutacture</div>
							</div>
							<div class="lc-desc">数字化赋能企业高质量发展</div>
							<!-- <div class="lc-more">了解更多...</div> -->
						</div>
					</div>
					<div class="lc-bottom flexCol alit justb">
						<div class="lc-title">重庆火锅产业要素交易平台</div>
						<div class="flex alit">
							<img src="https://qgst.obs.cn-southwest-2.myhuaweicloud.com/hg/home/4abc0ae1bcced13d40bd664503c91fd%402x.png"
								style="width:22px;height:22px;" />
							<div class="lc-yw">Hot pot deals</div>
						</div>
						<div class="lc-desc">一站式产品价值体现平台让每一份火锅食材都可以溯源</div>
						<!-- <div class="lc-more">了解更多...</div> -->
					</div>
				</div>
				<div class="bottom flex alit justb">
					<div>火锅行业标准</div>
					<div>火锅食材标准</div>
					<div>火锅食品溯源</div>
				</div>
			</div>
		</div>

		<!-- <img src="https://qgst.obs.cn-southwest-2.myhuaweicloud.com/hg/WechatIMG18.png" style="width: 100%;" alt="" />
		<img src="https://qgst.obs.cn-southwest-2.myhuaweicloud.com/hg/WechatIMG19.png" style="width: 100%;" alt="" />
		<img src="https://qgst.obs.cn-southwest-2.myhuaweicloud.com/hg/WechatIMG20.png" style="width: 100%;" alt="" /> -->
		<div class="flexCol no-copy" style="padding-left: 20%;background-color: #F6F7FB;padding-bottom: 30px;">
			<div style="position: relative;margin:50px 0 30px 0 ;">
				<div class="title-yw">WHAT CAN SCI DO FOR YOU?</div>
				<div class="title-top">平台能为您做什么？</div>
			</div>
			<div class="flex">
				<div v-for="(item, index) in centerData" :key="index"
					:class="tabbarIndex == index ? 'center-title-check' : 'center-title'"
					:style="{ textAlign: index == 0 ? 'left' : index == 3 ? 'right' : 'center' }"
					@mouseenter="mouseenter(index)" class="flex alit justc">
					<img :src="tabbarIndex == index ? item.checkIcon : item.defaultIcon"
						style="width:38px;height:38px;margin-right: 10px;" />
					{{ item.title }}
				</div>
			</div>
			<div style="background-color: #ECECEC;width: 80%;" v-if="tabbarShow">
				<div class="xian">
				</div>
			</div>

			<div class="xian-hide" v-else></div>

			<div style="margin: 20px 0;" class="content-desc">
				{{ centerData[tabbarIndex].desc }}
			</div>
			<div class="flex">
				<div v-for="(item, index) in centerData[tabbarIndex].content"
					:class="tabbarIndex2 == index ? 'center-title-check2' : 'center-title2'"
					@mouseenter="mouseenter2(index)">
					{{ item.title }}
				</div>
			</div>
			<div style="height:5px">
				<div class="xian2" v-if="tabbarShow2">
				</div>
				<div class="xian-hide" v-else></div>
			</div>
			<div class="flex" style="margin-top: 40px;height:440px;">
				<div style="position: relative;" v-for="item in centerData[tabbarIndex].content[tabbarIndex2].imgList">
					<img :src="item.img" class="content-image" style="width: 320px;height:440px;margin-right: 30px;"
						alt="" />
					<div class="img-bg">
						<div class="bottom-text flexCol alit">
							<div
								style="font-size: 20px;padding: 10px 0;font-weight: 900;letter-spacing:2px;width:100%;text-align: center;">
								{{ item.title }}
							</div>
							<div style="height:200px;width:90%;font-size: 16px;line-height: 30px;text-indent: 2em;">
								{{ item.content }}
							</div>
						</div>
					</div>
					<div class="bottom-bg"></div>
				</div>
			</div>
		</div>

		<div class="shiming no-copy">
			<div style="position: relative;margin:0 0 30px 20% ;">
				<div class="title-yw" style="width:550px;">DIGITAL GUANCXI CENTER</div>
				<div class="title-top">平台使命</div>
			</div>
			<div class="flexCol alit">
				<div class="shiming-title">让每一份火锅食材都可以溯源!</div>
				<div class="flex" style="margin-top: 64px;margin-bottom: 100px;position: relative;">
					<div class="pageGg ">
						<div class="flex" style="width:1200px">
							<img src="https://qgst.obs.cn-southwest-2.myhuaweicloud.com/hotpot/gg/%E9%87%8D%E5%BA%86%E7%81%AB%E9%94%85%402x.png"
								style="width:93px;height:441px;cursor: pointer;" alt="" />
							<img src="https://qgst.obs.cn-southwest-2.myhuaweicloud.com/hotpot/gg/%E5%9B%BE%E5%B1%82%205%402x.png"
								style="width:1107px;height:573px" alt="" />
						</div>
					</div>
					<div class="shiming-left flexCol alit justc">
						<div class="flex alit">
							<img style="width:50px;height:50px;"
								src="https://qgst.obs.cn-southwest-2.myhuaweicloud.com/hg/home/%E5%AE%B9%E5%99%A8%20293%402x.png" />
							<div class="title">重庆市数字火锅产业万亿级总部经济中心</div>
						</div>
						<div class="flex alit" style="margin: 80px 0 30px 0;">
							<div class="flex" style="margin-right: 13px;">
								<div class="shiming-num">1</div>
								<div class="shiming-desc">个平台</div>
							</div>
							<div class="shiming-desc2">重庆火锅产业要素交易平台</div>
						</div>
						<div class="flex alit">
							<div class="flex" style="margin-right: 13px;">
								<div class="shiming-num">2</div>
								<div class="shiming-desc">个中心</div>
							</div>
							<div class="flex">
								<div class="shiming-desc3">火锅产业研究中心</div>
								<div class="shiming-desc3" style="margin-left: 10px;">火锅品牌与产品展示中心</div>
							</div>
						</div>
						<div class="flex alit" style="margin: 30px 0 0  0;">
							<div class="flex" style="margin-right: 13px;">
								<div class="shiming-num">3</div>
								<div class="shiming-desc">个体系</div>
							</div>
							<div class="shiming-desc4 flex alit justb">
								<div class="shiming-desc5">火锅食材标准品控体系</div>
								<div class="shiming-desc5" style="width: 164px;">火锅供应商准入体系</div>
								<div class="shiming-desc5" style="width: 148px;">食品安全溯源体系</div>
							</div>
						</div>
					</div>
					<div class="shiming-right flexCol justc">
						<div class="flex">
							<div class="shuxian" style="margin: 0 30px 0 60px;"></div>
							<div class="flexCol justc">
								<div class="zl">战略定位</div>
								<div class="zl-title">
									把重庆打造成为世界火锅之都、火锅食材集散中心，全国火锅标准制定和发布中心，全国火锅美食文化创新和交流中心，实现重庆火锅产业要素“买全球”“卖全球”，把重庆火锅打造成为世界级品牌。
								</div>
							</div>
						</div>
						<div class="flex" style="margin-top: 60px;">
							<div class="shuxian2" style="margin: 0 30px 0 60px;"></div>
							<div class="flexCol justc">
								<div class="zl">发展战略</div>
								<div class="zl-title">
									以品牌化、连锁化、特色化、绿色化、数字化为发展方向，确立“1234567”的发展战略。即:
									聚焦“一张名片”“两个助推”“三个发展”“四大布局”“五大工程”“六大体系”“七件大事”，坚持国际化战略、标准化引领，智能化服务，着力培育火锅消费市场、食材供应、底料生产、预制菜加工及配套服务协同高效的现代火锅产业体系，为促进火锅产业高质量发展和带动乡村振兴提供有力支撑。
								</div>
							</div>
						</div>
					</div>

				</div>
			</div>


		</div>

	</div>
</template>
<script>
export default {
	data() {
		return {
			centerData: [{
				title: '原产地直供',
				desc: '智慧农业端通过卫星遥感、物联网、农业大数据、AI技术和智能装备等现代信息技术与农业深度融合，实现农业生产全过程的信息感知、定量决策、智能控制、精准投入和个性化定制的订单农业新模式，为农业生产者提供精准化种植、可视化管理、智能化决策，从种植源头端建立农产品溯源数据，助力优质农产品出村进城。',
				checkIcon: 'https://qgst.obs.cn-southwest-2.myhuaweicloud.com/hg/home/%E5%AE%B9%E5%99%A8%402x%288%29.png',
				defaultIcon: 'https://qgst.obs.cn-southwest-2.myhuaweicloud.com/hg/home/%E5%AE%B9%E5%99%A8%402x%288%29-d.png',
				content: [{
					title: '集采供应',
					image: '../../../static/WechatIMG18.png',
					imgList: [{
						img: 'https://qgst.obs.cn-southwest-2.myhuaweicloud.com/hg/home/%E9%A6%96%E9%A1%B5%E5%9B%BE%E7%89%87%E6%96%B0/%E6%BA%90%E4%BA%A7%E5%9C%B0%E7%9B%B4%E4%BE%9B/%E9%9B%86%E9%87%87%E4%BE%9B%E5%BA%94/%E9%9B%86%E9%87%87%E4%BE%9B%E5%BA%94.jpg',
						title: '集采平台',
						content: '通过开展集中采购活动，可将产品的流通环节大大减少，从而有效控制物流和仓储费用，进而降低购销成本，激发企业的创新活力，促进产业的整体升级。'
					}, {
						img: 'https://qgst.obs.cn-southwest-2.myhuaweicloud.com/hg/home/%E9%A6%96%E9%A1%B5%E5%9B%BE%E7%89%87%E6%96%B0/%E6%BA%90%E4%BA%A7%E5%9C%B0%E7%9B%B4%E4%BE%9B/%E9%9B%86%E9%87%87%E4%BE%9B%E5%BA%94/%E9%9B%86%E9%87%87%E4%BE%9B%E5%BA%942.jpg',
						title: '产地直供',
						content: '实行原产地直供，可有效避免因经销商的价格战造成的恶性竞争，从而提高市场竞争力，扩大自己的市场份额。此外，由于减少了中间环节，还能节省大量的流动资金，有利于增强自身的实力。'
					}, {
						img: 'https://qgst.obs.cn-southwest-2.myhuaweicloud.com/hg/home/%E9%A6%96%E9%A1%B5%E5%9B%BE%E7%89%87%E6%96%B0/%E6%BA%90%E4%BA%A7%E5%9C%B0%E7%9B%B4%E4%BE%9B/%E9%9B%86%E9%87%87%E4%BE%9B%E5%BA%94/%E9%9B%86%E9%87%87%E4%BE%9B%E5%BA%943.jpg',
						title: '企业画像',
						content: '平台选择优秀的合作伙伴，与协会、企业共同制定准入标准，通过全国企业工商登记、企业风险、信用、财务、经营管理等数据，为客户提供准确、直观、时效的数据服务。'
					}]
				}, {
					title: '产品溯源',
					image: '../../../static/WechatIMG19.png',
					imgList: [{
						img: 'https://qgst.obs.cn-southwest-2.myhuaweicloud.com/hg/home/%E9%A6%96%E9%A1%B5%E5%9B%BE%E7%89%87%E6%96%B0/%E6%BA%90%E4%BA%A7%E5%9C%B0%E7%9B%B4%E4%BE%9B/%E4%BA%A7%E5%93%81%E6%BA%AF%E6%BA%90/%E7%A7%91%E6%8A%80%E5%86%9C%E4%B8%9A.jpg',
						title: '产品溯源',
						content: '通过追踪和记录产品从农场的生产、加工、运输到餐桌各环节信息，实现对产品全生命周期的数据管理和溯查，消费者可以清楚地了解产品的来源及相关质量认证信息，企业借助溯源技术来管理和保障产品的质量安全。'
					}, {
						img: 'https://qgst.obs.cn-southwest-2.myhuaweicloud.com/hg/home/%E9%A6%96%E9%A1%B5%E5%9B%BE%E7%89%87%E6%96%B0/%E6%BA%90%E4%BA%A7%E5%9C%B0%E7%9B%B4%E4%BE%9B/%E4%BA%A7%E5%93%81%E6%BA%AF%E6%BA%90/%E7%89%A9%E8%81%94%E6%84%9F%E7%9F%A5.jpg',
						title: '农田管控',
						content: '利用物联网、云计算、大数据等技术，实现农业生产信息化、数据化管理，实时监测农作物生长、病虫害情况、气象变化等数据，真正做到源头管控。'
					}, {
						img: 'https://qgst.obs.cn-southwest-2.myhuaweicloud.com/hg/home/%E9%A6%96%E9%A1%B5%E5%9B%BE%E7%89%87%E6%96%B0/%E6%BA%90%E4%BA%A7%E5%9C%B0%E7%9B%B4%E4%BE%9B/%E4%BA%A7%E5%93%81%E6%BA%AF%E6%BA%90/%E5%85%A8%E8%BF%87%E7%A8%8B%E6%BA%AF%E6%BA%90.jpg',
						title: '田间链接餐桌',
						content: '充分利用区块链技术，对农产品种植、加工、销售的产业链各节点通过溯源码，实现农产品的基地出产、流畅、农残检测等信息的全过程追溯，打造农产品品牌，实现知根溯源。'
					}]
				}, {
					title: '农场监督',
					image: '../../../static/WechatIMG20.png',
					imgList: [{
						img: 'https://qgst.obs.cn-southwest-2.myhuaweicloud.com/hg/home/%E9%A6%96%E9%A1%B5%E5%9B%BE%E7%89%87%E6%96%B0/%E6%BA%90%E4%BA%A7%E5%9C%B0%E7%9B%B4%E4%BE%9B/%E5%86%9C%E5%9C%BA%E7%9B%91%E7%AE%A1/%E5%8F%AF%E8%A7%86%E5%8C%96%E5%86%9C%E5%9C%BA.jpg',
						title: '智慧农场',
						content: '现代农场包括农业产业化、种植业监管、生态循环监管、质量安全监管、农机监管等，在园区网格化地图上展示生产主体、生产区域、主导产业、监测信息等可视化数据。'
					}, {
						img: 'https://qgst.obs.cn-southwest-2.myhuaweicloud.com/hg/home/%E9%A6%96%E9%A1%B5%E5%9B%BE%E7%89%87%E6%96%B0/%E6%BA%90%E4%BA%A7%E5%9C%B0%E7%9B%B4%E4%BE%9B/%E5%86%9C%E5%9C%BA%E7%9B%91%E7%AE%A1/%E6%99%BA%E8%83%BD%E6%8E%A7%E5%88%B6.jpg',
						title: '产业赋能',
						content: '农业生产方式与现代科技完美结合，实现农业生产方式的数据化、标准化，和可视化，更好地链接了产、供、销三个环节。'
					}, {
						img: 'https://qgst.obs.cn-southwest-2.myhuaweicloud.com/hg/home/%E9%A6%96%E9%A1%B5%E5%9B%BE%E7%89%87%E6%96%B0/%E6%BA%90%E4%BA%A7%E5%9C%B0%E7%9B%B4%E4%BE%9B/%E5%86%9C%E5%9C%BA%E7%9B%91%E7%AE%A1/%E7%B2%BE%E5%87%86%E5%86%9C%E4%B8%9A.jpg',
						title: '精准农业',
						content: '通过分析农田土壤、作物营养需求等信息，采用精准施肥、精准灌溉、精准喷药等技术，提高作物产量、降低生产成本。'
					}]
				}, {
					title: '市场行情',
					image: '../../../static/WechatIMG18.png',
					imgList: [{
						img: 'https://qgst.obs.cn-southwest-2.myhuaweicloud.com/hg/home/%E9%A6%96%E9%A1%B5%E5%9B%BE%E7%89%87%E6%96%B0/%E6%BA%90%E4%BA%A7%E5%9C%B0%E7%9B%B4%E4%BE%9B/%E5%B8%82%E5%9C%BA%E8%A1%8C%E6%83%85/%E7%81%AB%E9%94%85%E5%92%A8%E8%AF%A2.jpg',
						title: '咨询信息',
						content: '汇聚大宗商品产能分布、产能产量变化、装置状态的纸质产能全景图，帮助您了解市场供应结构与分布。'
					}, {
						img: 'https://qgst.obs.cn-southwest-2.myhuaweicloud.com/hg/home/%E9%A6%96%E9%A1%B5%E5%9B%BE%E7%89%87%E6%96%B0/%E6%BA%90%E4%BA%A7%E5%9C%B0%E7%9B%B4%E4%BE%9B/%E5%B8%82%E5%9C%BA%E8%A1%8C%E6%83%85/%E4%BE%9B%E5%BA%94%E4%BB%B7%E6%A0%BC.jpg',
						title: '价格走势',
						content: '立足大宗商品市场交易价格与信息报道，包含价格数据、供需数据、市场动态、中短期价格分析、行业分析，帮助客户及时全面跟踪价格变化，了解市场动态，助力决策制定与时机判断。'
					}, {
						img: 'https://qgst.obs.cn-southwest-2.myhuaweicloud.com/hg/home/%E9%A6%96%E9%A1%B5%E5%9B%BE%E7%89%87%E6%96%B0/%E6%BA%90%E4%BA%A7%E5%9C%B0%E7%9B%B4%E4%BE%9B/%E5%B8%82%E5%9C%BA%E8%A1%8C%E6%83%85/%E5%B9%B4%E6%8A%A5.jpg',
						title: '行业研究',
						content: '利用及时准确的行业信息与丰富的专业知识，为客户企业提供高效且可行的供应链优化方案，旨在帮助客户识别优质供应商，设计并实施合适的采购模式，规划合理的物流运输方案，核算供应链成本等，通过优化现有供应链模式，从而为客户带来更高的企业利润。'
					}]
				}]
			},
			{
				title: '金融赋能',
				desc: '火锅企业、供应链企业信用评级，构建企业、平台、银行信用评价体系，实现火锅企业线上下单，供应链企业按订单配送产品，银行按供应链企业配送金额付款，火锅企业按银行帐期还款的闭环管理模式，打通金融“最后一公里”，为火锅产业的发展提供全方位的金融支持。',
				checkIcon: 'https://qgst.obs.cn-southwest-2.myhuaweicloud.com/hg/home/%E5%AE%B9%E5%99%A8%402x%289%29-check.png',
				defaultIcon: 'https://qgst.obs.cn-southwest-2.myhuaweicloud.com/hg/home/%E5%AE%B9%E5%99%A8%402x%289%29.png',
				content: [{
					title: '供应链金融',
					image: '../../../static/WechatIMG18.png',
					imgList: [{
						img: 'https://qgst.obs.cn-southwest-2.myhuaweicloud.com/hg/home/%E9%A6%96%E9%A1%B5%E5%9B%BE%E7%89%87%E6%96%B0/%E4%BE%9B%E5%BA%94%E9%93%BE%E9%87%91%E8%9E%8D/%E4%BE%9B%E5%BA%94%E9%93%BE%E9%87%91%E8%9E%8D/%E4%BE%9B%E5%BA%94%E9%93%BE%E9%87%91%E8%9E%8D4.jpg',
						title: '创新金融',
						content: '平台与银行合作，根据企业经营情况和平台的信用评估，提供贷款或信用额度，为供应链上的各个环节提供资金支持，以满足企业运营和扩张的资金需求。'
					}, {
						img: 'https://qgst.obs.cn-southwest-2.myhuaweicloud.com/hg/home/%E9%A6%96%E9%A1%B5%E5%9B%BE%E7%89%87%E6%96%B0/%E4%BE%9B%E5%BA%94%E9%93%BE%E9%87%91%E8%9E%8D/%E4%BE%9B%E5%BA%94%E9%93%BE%E9%87%91%E8%9E%8D/%E4%BE%9B%E5%BA%94%E9%93%BE%E9%87%91%E8%9E%8D.jpg',
						title: '供应商融资',
						content: '银行通过企业画像和供应链画像，全面分析企业风控认证，对与优质企业以供应链融资方式直接付款给供应商，解决了供应商收现款和企业回笼资金慢的矛盾点，为产业良性发展提供数字化的创新服务。'
					}, {
						img: 'https://qgst.obs.cn-southwest-2.myhuaweicloud.com/hg/home/%E9%A6%96%E9%A1%B5%E5%9B%BE%E7%89%87%E6%96%B0/%E4%BE%9B%E5%BA%94%E9%93%BE%E9%87%91%E8%9E%8D/%E4%BE%9B%E5%BA%94%E9%93%BE%E9%87%91%E8%9E%8D/%E4%BE%9B%E5%BA%94%E9%93%BE%E9%87%91%E8%9E%8D2.jpg',
						title: '产业金融',
						content: '数字化赋能三产融合发展，从源头保障了农担资产的采收、仓储、物流等环节的数据场景，充分为金融平台提供了放心可查的资产存证模型，为银行助力供应链金融提供了可视化的农担保障。'
					},]
				}, {
					title: '融资授信',
					image: '../../../static/WechatIMG19.png',
					imgList: [{
						img: 'https://qgst.obs.cn-southwest-2.myhuaweicloud.com/hg/home/%E9%A6%96%E9%A1%B5%E5%9B%BE%E7%89%87%E6%96%B0/%E4%BE%9B%E5%BA%94%E9%93%BE%E9%87%91%E8%9E%8D/%E8%9E%8D%E8%B5%84%E6%8E%88%E4%BF%A1/%E4%BA%A4%E6%98%93%E6%8E%88%E4%BF%A1.jpg',
						title: '融资授信',
						content: '平台实现与银行信管系统、票据系统、资金业务系统、贷记卡审批系统等数据交互，将各授信业务数据进行整合，通过进行顶层管控，构建符合监管要求和业务管理需要的统一授信管理体系。'
					}, {
						img: 'https://qgst.obs.cn-southwest-2.myhuaweicloud.com/hg/home/%E9%A6%96%E9%A1%B5%E5%9B%BE%E7%89%87%E6%96%B0/%E4%BE%9B%E5%BA%94%E9%93%BE%E9%87%91%E8%9E%8D/%E8%9E%8D%E8%B5%84%E6%8E%88%E4%BF%A1/%E4%BC%81%E4%B8%9A%E4%BF%A1%E7%94%A811.jpg',
						title: '平台赋能',
						content: '以技术、资金、资源等方式将企业与企业之间进行交融、联动,促进各方共赢,提供更好的服务,创造更大的价值，达到稳定产业链、供应链的创新举措。'
					}, {
						img: 'https://qgst.obs.cn-southwest-2.myhuaweicloud.com/hg/home/%E9%A6%96%E9%A1%B5%E5%9B%BE%E7%89%87%E6%96%B0/%E4%BE%9B%E5%BA%94%E9%93%BE%E9%87%91%E8%9E%8D/%E8%9E%8D%E8%B5%84%E6%8E%88%E4%BF%A1/%E4%BC%81%E4%B8%9A%E4%BF%A1%E7%94%A82.jpg',
						title: '企业评级',
						content: '企业主体信用分析的主要内容包括：产业、企业素质、经营管理、财务状况和偿债能力等方面，特别是企业素质中的企业综合情况评级，对企业领导群体的素质的评级，企业经营管理能力的评级，以及企业竞争能力的评级。'
					}]
				}, {
					title: '交易额融资',
					image: '../../../static/WechatIMG20.png',
					imgList: [{
						img: 'https://qgst.obs.cn-southwest-2.myhuaweicloud.com/hg/home/%E9%A6%96%E9%A1%B5%E5%9B%BE%E7%89%87%E6%96%B0/%E4%BE%9B%E5%BA%94%E9%93%BE%E9%87%91%E8%9E%8D/%E4%BA%A4%E6%98%93%E9%A2%9D%E8%9E%8D%E8%B5%84/%E4%BA%A4%E6%98%93%E8%9E%8D%E8%B5%84.jpg',
						title: '链路优化',
						content: '优化库存管理是供应链企业竞争力和经营效率的重点，精细化的需求预测和订单管理方法，可以减少库存成本，提高资金周转率，对供应链的成本、质量、服务等方面进行量化分析和评价。'
					}, {
						img: 'https://qgst.obs.cn-southwest-2.myhuaweicloud.com/hg/home/%E9%A6%96%E9%A1%B5%E5%9B%BE%E7%89%87%E6%96%B0/%E4%BE%9B%E5%BA%94%E9%93%BE%E9%87%91%E8%9E%8D/%E4%BA%A4%E6%98%93%E9%A2%9D%E8%9E%8D%E8%B5%84/%E4%BA%A4%E6%98%93%E8%9E%8D%E8%B5%8422.jpg',
						title: '交易额融资',
						content: '根据核心企业在平台历年的交易额流水，由平台和银行共同给予企业融资额度，共建资金池做风险担保，成为企业供应链快速拓展的金融赋能平台。'
					}, {
						img: 'https://qgst.obs.cn-southwest-2.myhuaweicloud.com/hg/home/%E9%A6%96%E9%A1%B5%E5%9B%BE%E7%89%87%E6%96%B0/%E4%BE%9B%E5%BA%94%E9%93%BE%E9%87%91%E8%9E%8D/%E4%BA%A4%E6%98%93%E9%A2%9D%E8%9E%8D%E8%B5%84/%E4%BA%A4%E6%98%93%E9%A2%9D%E8%9E%8D%E8%B5%84.jpg',
						title: '链融优化',
						content: '供应链金融是在物流金融基础上将融资从商品销售阶段延生到采购和生产阶段，整合优化“产-供-销”链条上的所有资源，对供应链上的各企业提供全面金融服务，以促进供应链核心企业及上下游配套企业链条稳固和流转顺畅。'
					}]
				}]
			},
			{
				title: '展示体验',
				desc: '建立火锅食材全品类展销和一站式甄选展示、体验、交易中心，统一供应商及产品准入标准，通过SAAS系统智能分发引擎链接物流，形成线上线下产品标准统一、交易数据同步的火锅产业展示中心、推广中心、发布中心、交易中心、结算中心，深度洞察消费终端数据变化，为企业提供前瞻性的决策依据，用数据从宏观和微观双维度呈现机遇和风险，推动产业模式与监管方式变革，促进产业高质量发展。',
				checkIcon: 'https://qgst.obs.cn-southwest-2.myhuaweicloud.com/hg/home/%E5%AE%B9%E5%99%A8%402x%2810%29-check.png',
				defaultIcon: 'https://qgst.obs.cn-southwest-2.myhuaweicloud.com/hg/home/%E5%AE%B9%E5%99%A8%402x%2810%29.png',
				content: [{
					title: '一站式产品体验',
					image: '../../../static/WechatIMG18.png',
					imgList: [{
						img: 'https://qgst.obs.cn-southwest-2.myhuaweicloud.com/hg/home/%E9%A6%96%E9%A1%B5%E5%9B%BE%E7%89%87%E6%96%B0/%E5%B1%95%E7%A4%BA%E4%BD%93%E9%AA%8C/%E4%BA%A7%E5%93%81%E5%B1%95%E7%A4%BA/%E4%B8%80%E7%AB%99%E5%BC%8F%E5%B1%95%E7%A4%BA.jpg',
						title: '一站式体验',
						content: '在解放碑产品展示中心甄选优质食材，全品类原材料详细参数、实时报价、行情走势、优质商品批发/供应信息等统一标准发布,为客户提供一站式选品服务。'
					}, {
						img: 'https://qgst.obs.cn-southwest-2.myhuaweicloud.com/hg/home/%E9%A6%96%E9%A1%B5%E5%9B%BE%E7%89%87%E6%96%B0/%E5%B1%95%E7%A4%BA%E4%BD%93%E9%AA%8C/%E4%BA%A7%E5%93%81%E5%B1%95%E7%A4%BA/%E4%BA%A7%E5%8C%BA%E5%B1%95%E7%A4%BA.jpg',
						title: '精准获客',
						content: '通过科学的市场定位、优质的内容营销、有效的社交媒体广告和合伙伴营销，企业才能获得成功，并与精准客户建立长期稳定的合关系。'
					}
						// , {
						// 	img: 'https://qgst.obs.cn-southwest-2.myhuaweicloud.com/hg/home/%E9%A6%96%E9%A1%B5%E5%9B%BE%E7%89%87%E6%96%B0/%E5%B1%95%E7%A4%BA%E4%BD%93%E9%AA%8C/%E4%BA%A7%E5%93%81%E5%B1%95%E7%A4%BA/a9480d36c77df1f044cc40c3d732414.jpg',
						// 	title: '平台裂变',
						// 	content: '通过平台奖励机制、推广工具和社交互动等手段，平台能够吸引更多用户参与推广行为，并将产品面向所有的客户，从而形成一个良性循环，推动平台用户群体的不断扩大。'
						// },
					]
				}, {
					title: '检测标准',
					image: '../../../static/WechatIMG19.png',
					imgList: [{
						img: 'https://qgst.obs.cn-southwest-2.myhuaweicloud.com/hg/home/%E9%A6%96%E9%A1%B5%E5%9B%BE%E7%89%87%E6%96%B0/%E5%B1%95%E7%A4%BA%E4%BD%93%E9%AA%8C/%E6%A3%80%E6%B5%8B%E6%A0%87%E5%87%86/%E6%A0%87%E5%87%86%E5%88%B6%E5%AE%9A2.jpg',
						title: '准入标准',
						content: '根据供应能力、产品质量、诚信度和企业管理等要素对供应商进行考察、尽调、评级和考核，汇集多维度数据形成企业全景画像，选择优质供应商准入平台为企业服务。'
					}, {
						img: 'https://qgst.obs.cn-southwest-2.myhuaweicloud.com/hg/home/%E9%A6%96%E9%A1%B5%E5%9B%BE%E7%89%87%E6%96%B0/%E5%B1%95%E7%A4%BA%E4%BD%93%E9%AA%8C/%E6%A3%80%E6%B5%8B%E6%A0%87%E5%87%86/%E6%A0%87%E5%87%86%E6%89%A7%E8%A1%8C2.jpg',
						title: '行业标准',
						content: '由协会发起制定火锅麻度辣度标准及主要食材品控标准，运用数字农业技术和物联网技术，共享基地种植过程数据，监督和规范用药标准降低农残，真正实现从种植源头抓质量、控标准。'
					}, {
						img: 'https://qgst.obs.cn-southwest-2.myhuaweicloud.com/hg/home/%E9%A6%96%E9%A1%B5%E5%9B%BE%E7%89%87%E6%96%B0/%E5%B1%95%E7%A4%BA%E4%BD%93%E9%AA%8C/%E6%A3%80%E6%B5%8B%E6%A0%87%E5%87%86/%E9%A3%9F%E6%9D%90%E6%A3%80%E6%B5%8B2.jpg',
						title: '食品安全',
						content: '对食品从农产品种植到加工、生产、运输等环节的全程跟踪和记录，形成企业送检存档和协会抽检比对的监督机制，有效防止产品质量问题和食品安全事件的发生。'
					}]
				}, {
					title: '企业展示',
					image: '../../../static/WechatIMG20.png',
					imgList: [{
						img: 'https://qgst.obs.cn-southwest-2.myhuaweicloud.com/hg/home/%E9%A6%96%E9%A1%B5%E5%9B%BE%E7%89%87%E6%96%B0/%E5%B1%95%E7%A4%BA%E4%BD%93%E9%AA%8C/%E4%BC%81%E4%B8%9A%E5%B1%95%E7%A4%BA/%E4%BC%81%E4%B8%9A%E5%B1%95%E7%A4%BA.jpg',
						title: '平台展示',
						content: '平台汇聚火锅行业全品类企业进行选品交易，帮助企业快速将优质产品和服务通过可视化呈现展示，让优秀供应商一站式面向全行业供应，同时满足采购者原产地直采供应。'
					}, {
						img: 'https://qgst.obs.cn-southwest-2.myhuaweicloud.com/hg/home/%E9%A6%96%E9%A1%B5%E5%9B%BE%E7%89%87%E6%94%B9/%E5%B1%95%E7%A4%BA%E4%BD%93%E9%AA%8C/%E4%BC%81%E4%B8%9A%E5%B1%95%E7%A4%BA/%E5%B1%95%E7%A4%BA.jpg',
						title: '信息交流',
						content: '通过新闻、访谈、论坛、展会等形式发布产品和交流信息，为火锅产业链提供全方位服务，在火锅名片和助力乡村振兴的背景下精准推广重庆火锅产业。'
					}, {
						img: 'https://qgst.obs.cn-southwest-2.myhuaweicloud.com/hg/home/%E9%A6%96%E9%A1%B5%E5%9B%BE%E7%89%87%E6%94%B9/%E5%B1%95%E7%A4%BA%E4%BD%93%E9%AA%8C/%E4%BC%81%E4%B8%9A%E5%B1%95%E7%A4%BA/%E5%B1%95%E7%A4%BA1.jpg',
						title: '展会发布',
						content: '火锅节做为重庆最盛大的全产业链展销盛会，汇集企业推广、新品发布、品牌展示及数据共享，把交易平台打造成国内具有影响力的火锅产业信息发布平台与行业展会发布平台。'
					}]
				}]
			},
			{
				title: '消费引流',
				desc: '构建企业信息化管理体系，规范企业流程，提高工作效率和准确性，加强企业在产品、服务、资金和人才方面的信息化水平和精细化管理，平台通过链接抖音、快手等互联网新媒体，培育私域流量，降低企业经营成本，提高企经营效益。',
				checkIcon: 'https://qgst.obs.cn-southwest-2.myhuaweicloud.com/hg/home/%E5%AE%B9%E5%99%A8%402x%2811%29-check.png',
				defaultIcon: 'https://qgst.obs.cn-southwest-2.myhuaweicloud.com/hg/home/%E5%AE%B9%E5%99%A8%402x%2811%29.png',
				content: [{
					title: '品牌曝光',
					image: '../../../static/WechatIMG19.png',
					imgList: [{
						img: 'https://qgst.obs.cn-southwest-2.myhuaweicloud.com/hg/home/%E9%A6%96%E9%A1%B5%E5%9B%BE%E7%89%87%E6%96%B0/%E6%B6%88%E8%B4%B9%E5%BC%95%E6%B5%81/%E5%93%81%E7%89%8C%E6%9B%9D%E5%85%89/%E4%BA%A7%E5%8C%BA%E5%B1%95%E7%A4%BA.jpg',
						title: '供应展示',
						content: '重庆火锅是火锅产业的主力品种，影响力大，品牌知名度高且自带流量，通过平台提供筛选对比功能，用户可快速找到满足需求的产品，并与供应商直接下单交易，平台对接产区供应商及时发部原材料产能、供应方式等数据，保障交易顺利进行。'
					}, {
						img: 'https://qgst.obs.cn-southwest-2.myhuaweicloud.com/hg/home/%E9%A6%96%E9%A1%B5%E5%9B%BE%E7%89%87%E6%96%B0/%E6%B6%88%E8%B4%B9%E5%BC%95%E6%B5%81/%E5%93%81%E7%89%8C%E6%9B%9D%E5%85%89/%E5%93%81%E7%89%8C%E6%8E%A8%E5%B9%BF.jpg',
						title: '品牌推广',
						content: '平台建立了完善的供应链推广体系，整合产业链各环节资源，优化供应链管理，让优质产品通过平台面向全体火锅企业，促进行业合作和发展，共同提升整个火锅产业的竞争力和高质量发展水平。'
					}, {
						img: 'https://qgst.obs.cn-southwest-2.myhuaweicloud.com/hg/home/%E9%A6%96%E9%A1%B5%E5%9B%BE%E7%89%87%E6%96%B0/%E6%B6%88%E8%B4%B9%E5%BC%95%E6%B5%81/%E5%93%81%E7%89%8C%E6%9B%9D%E5%85%89/%E4%BC%81%E4%B8%9A%E5%B1%95%E7%A4%BA2.jpg',
						title: '提升品质',
						content: '平台可以对供应商进行资质认证和评级，确保供应商的产品质量和安全可靠，帮助采购方降低风险，为消费者提供可溯源的优质火锅食材。'
					}]
				}, {
					title: '精准获客',
					image: '../../../static/WechatIMG18.png',
					imgList: [{
						img: 'https://qgst.obs.cn-southwest-2.myhuaweicloud.com/hg/home/%E9%A6%96%E9%A1%B5%E5%9B%BE%E7%89%87%E6%96%B0/%E6%B6%88%E8%B4%B9%E5%BC%95%E6%B5%81/%E7%B2%BE%E5%87%86%E8%8E%B7%E5%AE%A2/%E9%A4%90%E5%8E%85%E5%BC%95%E6%B5%81.jpg',
						title: '营销体系',
						content: '围绕顾客开展营销计划，在营销过程中密切关注与应对顾客线与竞争线这两条平行线的动态变化，适时对产品柱、价格柱、渠道柱和促销柱这四根支撑柱进行调整，实现企业的营销目标。'
					}, {
						img: 'https://qgst.obs.cn-southwest-2.myhuaweicloud.com/hg/home/%E9%A6%96%E9%A1%B5%E5%9B%BE%E7%89%87%E6%96%B0/%E6%B6%88%E8%B4%B9%E5%BC%95%E6%B5%81/%E7%B2%BE%E5%87%86%E8%8E%B7%E5%AE%A2/%E8%8E%B7%E5%AE%A2%E6%96%B9%E6%B3%95.jpg',
						title: '获客模型',
						content: '拥有被市场认可的产品和服务是企业生存的关键，构建规模化获客的营销体系则是支撑企业可持续增长的保证，针对企业自身的特点和发展阶段，建立适合的规模化获客体系确保企业健康发展。'
					}, {
						img: 'https://qgst.obs.cn-southwest-2.myhuaweicloud.com/hg/home/%E9%A6%96%E9%A1%B5%E5%9B%BE%E7%89%87%E6%96%B0/%E6%B6%88%E8%B4%B9%E5%BC%95%E6%B5%81/%E7%B2%BE%E5%87%86%E8%8E%B7%E5%AE%A2/%E6%B6%88%E8%B4%B9%E8%A1%8C%E4%B8%BA%E7%A0%94%E7%A9%B6.jpg',
						title: '平台促销',
						content: '平台打通企业公众号、企业微信、小程序等多营销触点，支持营销活动创建后多渠道一站式推广，全面触达企业目标客群，加持企业全域营销，提升店铺销量排名，吸引新客浏览下单，增加实体店客流，形成线上线下结合的推广活动，让顾客轻松找到门店位置。'
					}]
				}, {
					title: '消费卷引流',
					image: '../../../static/WechatIMG19.png',
					imgList: [{
						img: 'https://qgst.obs.cn-southwest-2.myhuaweicloud.com/hg/home/%E9%A6%96%E9%A1%B5%E5%9B%BE%E7%89%87%E6%96%B0/%E6%B6%88%E8%B4%B9%E5%BC%95%E6%B5%81/%E6%B6%88%E8%B4%B9%E5%8D%B7%E5%BC%95%E6%B5%81/%E6%B6%88%E8%B4%B922.jpg',
						title: '消费券引流',
						content: '消费券是一个行之有效的引流工具，能提高复购，提高转化率，吸引新顾客到店，消费券具有可调节性，定向性，可操作空间很大，政府、企业通过发卷不断刺激消费拉动经济，做好链接会大幅度提高营收。'
					}, {
						img: 'https://qgst.obs.cn-southwest-2.myhuaweicloud.com/hg/home/%E9%A6%96%E9%A1%B5%E5%9B%BE%E7%89%87%E6%96%B0/%E6%B6%88%E8%B4%B9%E5%BC%95%E6%B5%81/%E6%B6%88%E8%B4%B9%E5%8D%B7%E5%BC%95%E6%B5%81/%E6%B6%88%E8%B4%B9%E5%88%B8.jpg',
						title: '社群引流',
						content: '社群引流也被称为精准引流，顾客群体精准，能有拉新、留存、促活、转化的作用，很多餐饮店都利用社群做引流，通过趣味活动、裂变引流，给群里的人带去服务和满足需求，做到高效、精准的引流。'
					}, {
						img: 'https://qgst.obs.cn-southwest-2.myhuaweicloud.com/hg/home/%E9%A6%96%E9%A1%B5%E5%9B%BE%E7%89%87%E6%96%B0/%E6%B6%88%E8%B4%B9%E5%BC%95%E6%B5%81/%E6%B6%88%E8%B4%B9%E5%8D%B7%E5%BC%95%E6%B5%81/%E6%B6%88%E8%B4%B9%E7%BB%93%E6%9E%84.jpg',
						title: '爆款引流',
						content: '用一款味道佳，成本低的菜品，限额或免费和促销来为门店引流，设置好规则，能将暴款引流发挥到极致。'
					}]
				}, {
					title: '流量互动',
					image: '../../../static/WechatIMG20.png',
					imgList: [{
						img: 'https://qgst.obs.cn-southwest-2.myhuaweicloud.com/hg/home/%E9%A6%96%E9%A1%B5%E5%9B%BE%E7%89%87%E6%96%B0/%E6%B6%88%E8%B4%B9%E5%BC%95%E6%B5%81/%E6%B5%81%E9%87%8F%E4%BA%92%E5%8A%A8/%E6%B5%81%E9%87%8F%E4%BA%92%E5%8A%A8.jpg',
						title: '线上引流',
						content: '餐饮业通过发布短视频，在短视频做促销、放团购券的方式刺激消费者进店消费，同时结合本店特色起到快速引流的效果。'
					}, {
						img: 'https://qgst.obs.cn-southwest-2.myhuaweicloud.com/hg/home/%E9%A6%96%E9%A1%B5%E5%9B%BE%E7%89%87%E6%96%B0/%E6%B6%88%E8%B4%B9%E5%BC%95%E6%B5%81/%E6%B5%81%E9%87%8F%E4%BA%92%E5%8A%A8/%E7%A7%81%E5%9F%9F%E6%B5%81%E9%87%8F.jpg',
						title: '联盟引流',
						content: '平台联合景区、电影院等进行商业资源互换，与其他行业的商家联合起来做引流，不同行业相互扶持合作，匹配契合度高的商家做为引流互动联盟。'
					}, {
						img: 'https://qgst.obs.cn-southwest-2.myhuaweicloud.com/hg/home/%E9%A6%96%E9%A1%B5%E5%9B%BE%E7%89%87%E6%96%B0/%E6%B6%88%E8%B4%B9%E5%BC%95%E6%B5%81/%E6%B5%81%E9%87%8F%E4%BA%92%E5%8A%A8/%E4%BC%9A%E8%AE%AE%E7%94%9F%E6%80%81%E5%9C%88.jpg',
						title: '会议引流',
						content: '通过主题演讲、行业研讨、互动交流等方式，对目标人群和参会都互动增加参与感，餐饮创业者可以通过发布短视频，在短视频做促销、放团购券的方式刺激消费者进店消费。'
					}]
				}]
			}
			],
			tabbarIndex: 0,
			tabbarIndex2: 0,
			colorobj2: {
				left: "0px",
				right: "0px"
			},
			tabbarShow2: true, //控制滚动条显示  目的为了让动画再次触发
			colorobj: {
				left: "0px",
				right: "0px"
			},
			tabbarShow: true //控制滚动条显示  目的为了让动画再次触发
		}
	},
	methods: {
		goOrigin(){
			this.$router.push('/origin')
		},
		goNext(url) {
			// 获取滚动位置信息
			window.scrollTo({
				top: 0
			})
			this.$router.push(url)
		},
		upImage(e) {
			console.log(e.target.files[0]);
			getAction(e.target.files[0]).then(res => {
				console.log(res);
			})
			// console.log();
		},
		// 跳转到类别主页
		jumpCategory(item) {
			window.scrollTo(0, 0)
			this.$router.push({
				name: 'category',
				query: {
					classifyData: JSON.stringify(item)
				}
			})
		},
		mouseenter(e) {
			let that = this
			if (this.tabbarIndex == e) {
				return;
			}
			this.tabbarShow = false
			this.colorobj['left'] = this.colorobj.right
			this.colorobj['right'] = e * 25 + '%'
			this.tabbarIndex = e
			setTimeout(function () {
				that.tabbarShow = true
			}, 0);
			this.mouseenter2(0)
		},
		mouseenter2(e) {
			let that = this
			this.tabbarShow = false
			this.colorobj2['left'] = this.colorobj2.right
			this.colorobj2['right'] = e * 150 + 'px'
			this.tabbarIndex2 = e
			setTimeout(function () {
				that.tabbarShow2 = true
			}, 0);
		}
	}
}
</script>
<style lang="scss" scoped>
.bottom-bg {
	height: 40px;
	width: 91.7%;
	position: absolute;
	bottom: 0;
	background-color: rgba(198, 198, 198, 0.6);
}

.no-copy {
	-webkit-user-select: none;
	/* Safari */
	-moz-user-select: none;
	/* Firefox */
	-ms-user-select: none;
	/* IE/Edge */
	user-select: none;
	/* 标准语法 */
}

.top-num-desc {
	font-size: 16px;
	font-weight: 500;
	color: #FFFFFF;
}

.top-num {
	font-size: 30px;
	font-weight: 700;
	color: #FFFFFF;
	margin: 20px 0;
}

.top-desc {
	font-size: 16px;
	font-weight: 700;
	color: #FFFFFF;
	margin-top: 50px;
	text-align: center;
}

.top-title {
	font-size: 56px;
	font-weight: 700;
	color: #FFFFFF;
	text-align: center;
}

.top-style {
	position: absolute;
	z-index: 4;
	top: 100px;
}

.shiming {
	background-image: url('https://qgst.obs.cn-southwest-2.myhuaweicloud.com/hg/home/%E7%9F%A9%E5%BD%A2%204322%402x.png');
	background-size: 100% 100%;
	background-color: #fff;
	padding-top: 20px;

	.zl-title {
		width: 487px;
		font-size: 18px;
		font-weight: 400;
		color: #666666;
		line-height: 40px;
		text-indent: 2em;
		margin-top: 20px;
		text-align: left;
	}

	.zl {
		font-size: 24px;
		font-weight: 900;
		color: #1A1A1A;
		line-height: 40px;
	}

	.shuxian2 {
		width: 10px;
		height: 340px;
		background: #B20E1F;
		border-radius: 0px 10px 10px 0px;
		opacity: 1;
	}

	.shuxian {
		width: 10px;
		height: 240px;
		background: #B20E1F;
		border-radius: 0px 10px 10px 0px;
		opacity: 1;
	}

	.shiming-desc4 {
		width: 535px;
		height: 80px;
		background: #FFFFFF;
		box-shadow: 0px 3px 8px 0px rgba(0, 0, 0, 0.16);
		border-radius: 0px 0px 0px 0px;
		padding: 0 10px;

		.shiming-desc5 {
			width: 180px;
			height: 60px;
			border: 2px solid #B20E1F;
			text-align: center;
			font-size: 16px;
			font-weight: 700;
			color: #B20E1F;
			line-height: 60px;
		}
	}

	.shiming-desc3 {
		width: 262px;
		height: 80px;
		background: #FFFFFF;
		box-shadow: 0px 3px 8px 0px rgba(0, 0, 0, 0.16);
		border-radius: 0px 0px 0px 0px;
		text-align: center;
		line-height: 80px;
		font-size: 22px;
		font-weight: 700;
		color: #B20E1F;
	}

	.shiming-desc2 {
		width: 535px;
		height: 80px;
		background: #FFFFFF;
		box-shadow: 0px 3px 8px 0px rgba(0, 0, 0, 0.16);
		border-radius: 1px 1px 1px 1px;
		opacity: 1;
		text-align: center;
		font-size: 28px;
		font-weight: 700;
		color: #B20E1F;
		line-height: 80px;
	}

	.shiming-num {
		font-size: 50px;
		font-weight: 900;
		color: #1A1A1A;
		font-style: italic;
		margin-right: 5px;
	}

	.shiming-desc {
		font-size: 24px;
		font-weight: 500;
		color: #1A1A1A;
		line-height: 70px;
	}

	.shiming-left {
		width: 810px;
		height: 658px;
		background: rgba(255, 255, 255, 0.4);
		border-radius: 0px 0px 0px 0px;
		opacity: 1;
		border: 1px solid #B20E1F;

		.title {
			font-size: 30px;
			font-weight: 700;
			color: #014099;
		}
	}

	.shiming-title {
		background-image: url('https://qgst.obs.cn-southwest-2.myhuaweicloud.com/hg/home/%E5%AE%B9%E5%99%A8%20317%402x.png');
		background-size: 100% 100%;
		width: 800px;
		height: 74px;
		line-height: 74px;
		text-align: center;
		font-size: 36px;
		font-weight: 700;
		color: #FFFFFF;
	}
}

.title-top {
	font-size: 36px;
	font-weight: 700;
	color: #1A1A1A;
	position: absolute;
	top: 50px;
	left: 10px;
}

.title-yw {
	width: 500px;
	font-size: 52px;
	font-weight: 500;
	color: #F2E9EE;
	line-height: 68px;
	letter-spacing: 6px;
}

.top-bottom {
	background-image: url('https://qgst.obs.cn-southwest-2.myhuaweicloud.com/hg/home/%E5%AE%B9%E5%99%A8%20321%402x.png');
	background-size: 100% 100%;
	width: 100%;
	height: 980px;
	margin-top: -220px;
	position: relative;
	z-index: 3;

	.tiaoxian {
		position: absolute;
		top: 260px;
		width: 730px;
	}

	.lc-yw {
		font-size: 16px;
		font-weight: 400;
		color: #B20E1F;
		margin-left: 10px;
	}

	.lc-more {
		font-size: 16px;
		font-weight: 400;
		color: #B20E1F;
		cursor: pointer;
	}

	.lc-desc {
		font-size: 18px;
		font-weight: 900;
		color: #080808;
		margin-bottom: 20px;
	}

	.lc-title {
		font-size: 24px;
		font-weight: 700;
		color: #B20E1F;
	}

	.bottom {
		background-image: url('https://qgst.obs.cn-southwest-2.myhuaweicloud.com/hg/home/%E7%BB%84%2012430%402x.png');
		background-size: 100% 100%;
		width: 1017px;
		height: 84px;
		padding: 0 100px;
		font-size: 18px;
		font-weight: 700;
		color: #B20E1F;
	}

	.lc-bottom {
		background-image: url('https://qgst.obs.cn-southwest-2.myhuaweicloud.com/hg/home/%E7%BB%84%2012428%402x.png');
		background-size: 100% 100%;
		width: 580px;
		height: 140px;
		padding: 30px 0 20px 0;
		margin-top: 40px;
	}

	.lc-center {
		background-image: url('https://qgst.obs.cn-southwest-2.myhuaweicloud.com/hg/home/%E7%BB%84%2012429%402x.png');
		background-size: 100% 100%;
		width: 305px;
		height: 140px;
		padding: 30px 0 20px 0;
	}

	.lc-top {
		background-image: url('https://qgst.obs.cn-southwest-2.myhuaweicloud.com/hg/home/%E7%BB%84%2012405%402x.png');
		background-size: 100% 100%;
		width: 360px;
		height: 170px;
		padding: 30px 0 20px 0;
	}

	.name {
		font-size: 30px;
		font-weight: 900;
		color: #014099;
		padding-top: 60px;
	}
}

.top-center-qiu {
	position: absolute;
	top: -150px;
	width: 100%;
	z-index: 1;
	opacity: 0.3;

	img {
		width: 1200px;
	}
}

.bottom-text {
	position: absolute;
	bottom: -200px;
	width: 100%;
}

.img-bg {
	width: 320px;
	height: 440px;
	position: absolute;
	z-index: 999;
	top: 0;
	overflow: hidden;
	animation: showBg2 2s;
	animation-fill-mode: forwards;

	.bottom-text {
		animation: showText2 1s;
		animation-fill-mode: forwards;
		color: #fff;
	}
}

.img-bg:hover {
	// transform: scale(1.1);
	// cursor: pointer;
	animation: showBg 1s;
	animation-fill-mode: forwards;

	/* 保留动画结束时的状态 */
	/* 应用动画 */
	.bottom-text {
		animation: showText 1s;
		animation-fill-mode: forwards;
		color: #fff;
	}
}

@keyframes showText2 {
	from {
		bottom: 100px;
		/* 动画开始时的位置 */
	}

	to {
		bottom: -200px;
		/* 动画结束时的位置 */
	}
}

@keyframes showBg2 {
	from {
		background-color: rgba(0, 0, 0, 0.5);

		/* 动画开始时的位置 */
	}

	to {
		background-color: rgba(0, 0, 0, 0);
		/* 动画结束时的位置 */
	}
}

@keyframes showText {
	from {
		bottom: -200px;
		/* 动画开始时的位置 */
	}

	to {
		bottom: 100px;
		/* 动画结束时的位置 */
	}
}

@keyframes showBg {
	from {
		background-color: rgba(0, 0, 0, 0);
		/* 动画开始时的位置 */
	}

	to {
		background-color: rgba(17, 68, 111, 0.9);
		/* 动画结束时的位置 */
	}
}

.content-desc {
	text-indent: 2em;
	width: 80%;
	height: 120px;
	font-size: 18px;
	font-weight: 400;
	color: #999999;
	line-height: 36px;
	text-align: left
}

@keyframes myimate {
	from {
		left: v-bind('colorobj.left');
	}

	to {
		left: v-bind('colorobj.right');
	}
}

.xian {
	width: 25%;
	height: 3px;
	background: #014099;
	left: 0px;
	position: relative;
	left: 100px;
	animation-name: myimate;
	animation-duration: 0.5s;
	animation-delay: 0s;
	animation-iteration-count: infinite;
	animation-iteration-count: 1;
	animation-fill-mode: forwards;
}

.center-title {
	width: 20%;
	cursor: pointer;

	font-size: 24px;
	font-weight: 500;
	color: #999999;
	margin-bottom: 20px;
}

.center-title-check {
	width: 20%;
	cursor: pointer;

	font-size: 24px;
	font-weight: 700;
	color: #014099;
	margin-bottom: 20px;
}

@keyframes myimate2 {
	from {
		left: v-bind('colorobj2.left');
	}

	to {
		left: v-bind('colorobj2.right');
	}
}

.xian2 {
	width: 150px;
	height: 4px;
	background: #B20E1F;
	left: 0px;
	position: relative;
	left: 100px;
	animation-name: myimate2;
	animation-duration: 0.5s;
	animation-delay: 0s;
	animation-iteration-count: infinite;
	animation-iteration-count: 1;
	animation-fill-mode: forwards;
	min-width: 100px;
	margin-top: 5px;
}

.xian-hide {
	width: 100px;
	height: 4px;
}

.center-title2 {
	width: 150px;
	height: 30px;
	cursor: pointer;
	text-align: center;
	min-width: 120px;

	font-size: 20px;
	font-weight: 500;
	color: #999999;
}

.center-title-check2 {
	width: 150px;
	height: 30px;
	cursor: pointer;
	text-align: center;
	min-width: 120px;

	font-size: 20px;
	font-weight: 700;
	color: #B20E1F;
}

.bg2 {
	background: rgba(203, 228, 183, 0.3);
}

.class-child2 {
	cursor: pointer;
}

.class-child2:hover {
	color: red;
}

.text3 {
	font-family: PingFangSC-Regular;
	font-size: 12px;
	color: #646464;
	letter-spacing: 0;
	line-height: 20px;
	margin-right: 10px;
}

.text2 {
	font-weight: 900;
	font-family: PingFangSC-Medium;
	font-size: 14px;
	color: #444;
	letter-spacing: 0;
}

.text1 {
	font-weight: 900;
	font-family: PingFangSC-Medium;
	font-size: 18px;
	color: rgb(34, 34, 34);
	letter-spacing: 0;
	margin-left: 5px;
}

.class-child3 {
	margin-right: 10px;
	margin-top: 10px;
	cursor: pointer;
}

.class-child3:hover {
	color: red;
}

.img {
	width: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
}

.swiper-button-prev,
.swiper-button-next {
	outline: none;
	color: red;
}

.category {
	background: #f5f5f5;
}
</style>