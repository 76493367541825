<!--  -->
<template>
    <div class=''>
        <div class="flex justb alit" style="background-color: #fafafa;padding: 10px 50px;">
            <el-tabs v-model="activeName" @tab-click="handleClick">
                <el-tab-pane label="所有订单" name="first"></el-tab-pane>
                <el-tab-pane label="待付款" name="0"></el-tab-pane>
                <el-tab-pane label="待发货" name="10"></el-tab-pane>
                <el-tab-pane label="待收货" name="20"></el-tab-pane>
                <el-tab-pane label="已完成" name="30"></el-tab-pane>
            </el-tabs>
            <el-input placeholder="商品名称/订单号" suffix-icon="el-icon-search" style="width: 300px;" v-model="searchInput">
            </el-input>
        </div>

        <div class="order-title flex">
            <div class="tt" style="width: 28%;">商品</div>
            <div class="tt" style="width: 12%;">单价</div>
            <div class="tt" style="width: 12%;">数量</div>
            <div class="tt" style="width: 12%;">实付款</div>
            <div class="tt" style="width: 12%;">收货人</div>
            <div class="tt" style="width: 12%;">状态</div>
            <div class="tt" style="width: 12%;">操作</div>
        </div>
        <div class="order-title" style="height: auto;" v-for="(item, index) in orderList" :key="index">
            <div class="flex justb order-top">
                <div class="flex">{{ item.shopName }} {{ utils.changeTime(item.createTime) }} <a
                        style="margin-left: 40px">订单号：{{ item.no }}</a>
                </div>
                <div class="">
                    <i class="iconfont icon-shanchu" style="cursor: pointer;margin-right: 35px;"
                        v-if="item.status == 0"></i>
                </div>
            </div>
            <div class="flex alit">
                <div class="" style="flex: 1;">
                    <div class="flex alit bord" style="font-size: 14px;" v-for="ite in item.items">
                        <div style="width: 35%;padding: 1%;">
                            <div class="flex">
                                <img :src="ite.picUrl" alt="">
                                <div class="flexCol justb" style="text-align: left;">
                                    <div class="">{{ ite.spuName }}</div>
                                    <div class="" style="color: #999;">{{ ite.properties[0].valueName }}</div>
                                </div>
                            </div>
                        </div>
                        <div style="width: 15%; text-align: center;">￥{{ ite.price / 100 }}</div>
                        <div style="width: 16%; text-align: center;">{{ ite.count }}</div>
                        <div style="width: 15%; text-align: center;">￥{{ ite.payPrice / 100 }}</div>
                    </div>
                </div>
                <div style="width: 12%; text-align: center;">
                    <div class="" style="color: #999;">{{ changeStatus(item.status) }}</div>
                    <div class="" style="cursor: pointer;margin-top: 5px;" @click="goOrderInfo(item.id)">订单详情</div>
                </div>
                <div style="width: 12%; text-align: center;">
                    <div class="" style="cursor: pointer;" v-if="item.status == 0" @click="payOrderCheck(item)">立即支付
                    </div>
                    <div class="" style="cursor: pointer;" v-if="item.status == 20" @click="showTaskOver(item)">确认收货
                    </div>
                </div>
            </div>
        </div>
        <div class="flex justb" style="width:1150px;margin: 20px 0;">
            <div></div>
            <div><el-pagination :page-size="pageSize3" small="small" layout="prev, pager, next, jumper"
                    :total="pageTotal" @current-change="handleCurrentChange" /></div>
        </div>
        <el-dialog title="支付" :visible.sync="qrcodeShow" width="400px">
            <qrcode v-if="qrcodeShow" :text="submitQrcode" :payId="payId" style="width: 300px;"
                @paySuccess="paySuccess"></qrcode>
        </el-dialog>

        <el-dialog title="请选择支付方式" :visible.sync="payShow">
            <orderPay @close="payShow = false" @goPay="payOrder"></orderPay>
        </el-dialog>
        <el-dialog title="确认收货" :visible.sync="taskOverShow">
            <task-over :orderInfo="orderInfo" @canal="taskOverShow = false" @taskSuccess="taskSuccess"></task-over>
        </el-dialog>
    </div>
</template>

<script>
import Qrcode from '@/components/qrcode.vue'
import OrderPay from '@/views/order/orderPay.vue'
import TaskOver from '@/views/order/taskOver.vue'

export default {
    components: { Qrcode, OrderPay, TaskOver },
    data() {
        return {
            taskOverShow: false,
            activeName: 'first',
            orderList: [],
            searchInput: '',
            page: 1,
            submitQrcode: null,
            payId: '',
            qrcodeShow: false,
            payShow: false,
            payData: null,
            pageTotal: 1,
            pageSize3: 10,
            orderInfo: null
        }
    },
    // 监听属性 类似于data概念
    computed: {},
    // 监控data中的数据变化
    watch: {},
    // 方法集合
    methods: {
        taskSuccess() {
            this.getOrderPage()
            this.taskOverShow = false
        },
        showTaskOver(item) {
            for (let i = 0; i < item.items.length; i++) {
                item.items[i].select = false
            }
            this.orderInfo = item
            this.taskOverShow = true
        },
        handleCurrentChange(e) {
            console.log(e);
            this.page = e
            this.getOrderPage()
            window.scrollTo({
                top: 0,
                left: 0,
            });
        },
        paySuccess() {
            this.page = 1
            this.payId = ''
            this.qrcodeShow = false
            this.submitQrcode = null
            this.getOrderPage()
        },
        payOrder(index) {
            this.submitQrcode = null
            if (index == 1 || index == 2) {
                //微信支付
                this.$request('/app-api/pay/order/submit', {
                    id: this.payData.payOrderId,
                    channelCode: 'yl_h5'
                }, 'post').then(submit => {
                    this.payId = this.payData.payOrderId
                    this.submitQrcode = submit.data.displayContent
                    this.qrcodeShow = true
                })
            }
            if (index == 3) {
                //个人支付
                this.$request('/app-api/pay/order/submit', {
                    id: this.payData.payOrderId,
                    channelCode: 'yl_b2c'
                }, 'post').then(submit => {
                    this.payId = this.payData.payOrderId
                    window.open(submit.data.displayContent)
                    this.qrcodeShow = true
                })
            }
            if (index == 4) {
                //个人支付
                this.$request('/app-api/pay/order/submit', {
                    id: this.payData.payOrderId,
                    channelCode: 'yl_b2b'
                }, 'post').then(submit => {
                    this.payId = this.payData.payOrderId
                    window.open(submit.data.displayContent)
                    this.qrcodeShow = true
                })
            }
        },
        payOrderCheck(e) {
            this.payData = e
            this.payShow = true
        },
        goOrderInfo(id) {
            this.$router.push('/orderDetail?id=' + id)
        },
        changeStatus(e) {
            switch (e) {
                case 0:
                    return '待付款'
                    break;
                case 10:
                    return '待发货'
                    break;
                case 20:
                    return '待收货'
                    break;
                case 30:
                    return '已完成'
                    break;
                case 40:
                    return '已取消'
                    break;
                default:
                    break;
            }
        },
        //获取订单
        getOrderPage() {
            this.$request('/app-api/trade/order/page', {
                pageSize: 10,
                pageNo: this.page,
                status: this.activeName == 'first' ? '' : this.activeName
            }, 'get').then(res => {
                console.log(res);
                this.orderList = res.data.list
                this.pageTotal = res.data.total
            })
        },
        handleClick(e) {
            this.page = 1
            this.getOrderPage()
        }
    },
    // 生命周期 - 创建完成（可以访问当前this实例）
    created() {
        this.getOrderPage()
    },
    // 生命周期 - 挂载完成（可以访问DOM元素）
    mounted() {

    },
    beforeCreate() { }, // 生命周期 - 创建之前
    beforeMount() { }, // 生命周期 - 挂载之前
    beforeUpdate() { }, // 生命周期 - 更新之前
    updated() { }, // 生命周期 - 更新之后
    beforeDestroy() { }, // 生命周期 - 销毁之前
    destroyed() { }, // 生命周期 - 销毁完成
    activated() { } // 如果页面有keep-alive缓存功能，这个函数会触发
}
</script>
<style lang='scss' scoped>
.bord {}

.order-title {
    width: 100%;
    height: 50px;
    margin-bottom: 10px;
    background-color: #fff;
    border: 2px solid #f3f4f5;
    margin-top: 10px;
    font-size: 14px;

    div {
        // border: 1px solid;
    }

    img {
        width: 100px;
        height: 100px;
        margin-right: 10px;
    }

    .tt {
        text-align: center;
        line-height: 50px;
    }

    .order-top {
        background-color: rgba(226,119,31,.3);
        height: 40px;
        line-height: 40px !important;
        padding: 0 30px;
        font-size: 13px;
    }
}

::v-deep .el-tabs__item:hover {
    color: #C5AA7B !important;
}

::v-deep .el-tabs__item.is-active {
    color: #C5AA7B !important;
}

::v-deep .el-tabs__active-bar {
    background-color: #C5AA7B
}

::v-deep .el-tabs__nav-wrap::after {
    background: none;
}

::v-deep .el-tabs__header {
    margin: 0;
}
</style>