<template>
    <div class="flex alit justc">
        <div class="login-style flexCol alit justc">
            <div class="flex" style="margin-bottom: 22px;">
                <div :class="loginIndex == 1 ? 'login-check' : 'login-default'" @click="loginIndex = 1">密码登录</div>
                <div :class="loginIndex == 2 ? 'login-check' : 'login-default'" style="margin-left: 25px;"
                    @click="loginIndex = 2">短信登录</div>
            </div>
            <div class="login-input-style" v-if="loginIndex == 1">
                <el-input type="text" v-model="passwordLogin.mobile" style="background-color: rgba(255, 255, 255, 0);flex: 1;"
                    placeholder="请输入账号" />
            </div>
            <div class="login-input-style" style="margin: 22px 0;" v-if="loginIndex == 1">
                <el-input :type="inputPassword" v-model="passwordLogin.password"
                    style="background-color: rgba(255, 255, 255, 0);flex: 1;" placeholder="请输入密码">
                    <i slot="suffix" class="el-input__icon el-icon-view" style="cursor: pointer;"
                        @click="inputPassword = (inputPassword == 'password' ? 'text' : 'password')"></i>
                </el-input>
            </div>
            <div class="login-input-style" v-if="loginIndex == 2">
                <el-input type="text" v-model="codeLogin.mobile" style="background-color: rgba(255, 255, 255, 0);flex: 1;"
                    placeholder="请输入手机号码" />
            </div>
            <div class="login-input-style send" style="margin: 22px 0;" v-if="loginIndex == 2">
                <el-input type="text" v-model="codeLogin.code" style="background-color: rgba(255, 255, 255, 0);flex: 1;"
                    placeholder="请输入验证码">
                    <template slot="append">
                        <div class="" @click="send">{{ sendMessage }}</div>
                    </template>
                </el-input>
            </div>


            <div class="login-submit" @click="login">登录</div>
            <div class="flex justb" style="width: 276px;margin-top: 20px;">
                <div class="login-desc" @click="goRegister">免费注册</div>
                <div class="login-desc"></div>
                <!-- 忘记密码 -->
            </div>
        </div>


    </div>
</template>
<script>
export default {
    data() {
        return {
            loginIndex: 1, //1账号密码登录  2验证码登录
            inputPassword: 'password',
            sendMessage: '发送验证码',
            isSendStatus: 1, //1为未发送中 2为发送中
            codeLogin: {
                mobile: '',
                code: '',
                socialType: 31,
                socialCode: '123',
                socialState: '123'
            },//验证码登录信息
            passwordLogin: {
                mobile: '',
                password: '',
                socialType: 31,
                socialCode: '123',
                socialState: '123'
            } //密码登录
        }
    },
    methods: {
        login() {
            if (this.loginIndex == 1) {
                //这里是密码登录
                this.$request('/app-api/member/auth/login', this.passwordLogin, 'post').then(res => {
                    localStorage.setItem('accessToken', res.data.accessToken)
                    localStorage.setItem('refreshToken', res.data.refreshToken)
                    this.$request('/app-api/member/user/get', {}, 'get').then(res => {
                        localStorage.setItem('userInfo', JSON.stringify(res.data))
                        this.$emit('getUser')
                        this.$emit('changeCar')
                        this.$notify({
                            message: "登录成功",
                            duration: 2000,
                        });
                        this.$router.go(-1)
                    })

                })
            } else {
                //这里是手机验证码登录
                this.$request('/app-api/member/auth/sms-login', this.codeLogin, 'post').then(res => {
                    localStorage.setItem('accessToken', res.data.accessToken)
                    localStorage.setItem('refreshToken', res.data.refreshToken)
                    this.$request('/app-api/member/user/get', {}, 'get').then(res => {
                        localStorage.setItem('userInfo', JSON.stringify(res.data))
                        this.$emit('getUser')
                        this.$emit('changeCar')
                        
                        this.$notify({
                            message: "登录成功",
                            duration: 2000,
                        });
                        this.$router.go(-1)
                    })
                })
            }
        },
        send() {
            if (this.isSendStatus == 2) {
                return;
            }
            this.sendMessage = 60
            this.isSendStatus = 2
            let interval = setInterval(() => {
                this.sendMessage -= 1
                if (this.sendMessage == 0) {
                    clearInterval(interval)
                    this.isSendStatus = 1
                    this.sendMessage = '发送验证码'
                }
            }, 1000);

            this.$request('/app-api/member/auth/send-sms-code', { mobile: this.codeLogin.mobile, scene: 31 }, 'post')
        },
        goRegister() {
            this.$router.push('register')
        }
    }
}
</script>
<style scoped lang="scss">
.login-desc {
    color: #8F8F8F;
    font-size: 15px;
    cursor: pointer;
}

.login-input-style {
    width: 276px;
    height: 39px;
    background-color: #F3F3F3;
    border-radius: 19px;

    ::v-deep .el-input {
        background-color: #F3F3F3;
        border-radius: 19px;
        width: 276px !important;
        .el-input__inner {
            background-color: #F3F3F3;
            border-radius: 19px;
            border: none;
        }
    }

}

.send {
    ::v-deep .el-input {
        .el-input__inner {
            background-color: #F3F3F3;
            border-radius: 19px 19px 0 19px !important;
            border: none;
        }

        .el-input-group__append {
            background-color: #F3F3F3 !important;
            border: none !important;
            border-radius: 19px 19px 19px 0 !important;
            cursor: pointer;
        }
    }

}

.login-default {
    font-size: 21px;
    color: #000;
    font-weight: 900;
    cursor: pointer;
}

.login-check {
    font-size: 21px;
    color: #F22022;
    font-weight: 900;
    cursor: pointer;
}

.login-style {
    width: 447px;
    box-shadow: 0px 3px 24px -4px rgba(150, 150, 150, 0.24);
    margin: 50px 0;
    padding: 60px 0;

    .login-submit {
        cursor: pointer;
        width: 276px;
        height: 39px;
        background-image: url('https://xlqy.obs.cn-southwest-2.myhuaweicloud.com/hotpotNew/login/%E7%99%BB%E5%BD%95%402x.png');
        background-size: 100% 100%;
        text-align: center;
        line-height: 39px;
        color: #fff;
        font-size: 17px;
        margin-top: 20px;
    }
}
</style>