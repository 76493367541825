<template>
	<div class="flexCol alit" style="margin: 50px 0">
		<div class="flex" v-if="type == 1">
			<div class="register" @click="type = 2">
				<img src="https://xlqy.obs.cn-southwest-2.myhuaweicloud.com/hotpot/web/register/%E4%B8%AA%E4%BA%BA.png"
					style="width: 560px; height: 320px" alt="" />
				<div class="zc">个人账户注册</div>
			</div>
			<div class="register" style="margin-left: 70px" @click="type = 3">
				<img src="https://xlqy.obs.cn-southwest-2.myhuaweicloud.com/hotpot/web/register/%E4%BC%81%E4%B8%9A.png"
					style="width: 560px; height: 320px" alt="" />
				<div class="zc">企业账户注册</div>
			</div>
		</div>

		<div class="flex justc" style="border-bottom: 2px solid #e6e6e6; width: 50%" v-else>
			<div class="flex alit justc" :class="type == 2 ? 'zh-type-check' : 'zh-type'" @click="type = 2">
				<i class="el-icon-user"></i>个人账户注册
			</div>
			<div class="flex alit justc" :class="type == 3 ? 'zh-type-check' : 'zh-type'" @click="type = 3">
				<i class="el-icon-office-building"></i> 企业账户注册
			</div>
		</div>

		<div class="" v-if="type == 2">
			<el-form ref="ruleFormRef" style="max-width: 600px; margin-top: 20px" :model="ruleForm" :rules="rules"
				label-width="auto" class="demo-ruleForm">
				<el-form-item label="会员昵称:" prop="memberName">
					<el-input v-model="ruleForm.memberName" type="text" placeholder="请输入会员昵称" autocomplete="off" />
				</el-form-item>
				<el-form-item label="登录密码:" prop="loginPassword">
					<el-input v-model="ruleForm.loginPassword" type="password" placeholder="请输入登录密码"
						autocomplete="off" />
				</el-form-item>
				<el-form-item label="密码确认:" prop="checkPass">
					<el-input v-model="ruleForm.checkPass" type="password" placeholder="请再次输入您的密码" autocomplete="off" />
				</el-form-item>
				<el-form-item label="手机号码:" prop="phone">
					<el-input v-model="ruleForm.phone" maxlength="11" placeholder="请输入您的手机号码" />
				</el-form-item>
				<el-form-item label="验证码:" prop="verificationCode">
					<el-input v-model="ruleForm.verificationCode" maxlength="6" placeholder="请输入验证码">
						<template #append>
							<span class="codeBtn" style="cursor: pointer" v-show="accountFormSetting.visibleGetCode"
								@click="getCode">获取验证码</span>
							<span v-show="!accountFormSetting.visibleGetCode" class="codeBtn codeCount">重新获取({{
								accountFormSetting.codeCountNum }}s)</span>
						</template>
					</el-input>
				</el-form-item>
				<el-form-item label="服务工号:" prop="pushCode">
					<el-input v-model="ruleForm.pushCode" type="text" placeholder="请输入服务工号" autocomplete="off" />
				</el-form-item>
				<div class="" style="display: flex; margin-top: 15px">
					<el-checkbox v-model="haveRead" style="margin-right: 10px; margin-top: -5px" />
					<div class="pointer" @click="">
						<a class="" @click="haveRead = !haveRead">我已阅读并同意</a>
						<a class="hightLight" @click="readAgreement(1)">《重庆火锅产业要素交易平台用户服务协议》</a>
						<a class="hightLight" @click="readAgreement(2)">《重庆火锅产业要素交易平台用户隐私政策》</a>
					</div>
				</div>

				<el-form-item class="">
					<div class="flex alit justc" style="width: 100%; margin-top: 30px">
						<el-button type="primary" class="btn" size="large" @click="submitForm()">
							注册
						</el-button>
						<el-button size="large" class="btn2" @click="resetForm()">重置</el-button>
					</div>
				</el-form-item>
			</el-form>
		</div>
		<div class="" v-if="type == 3">
			<el-form ref="businessForm" style="max-width: 600px; margin-top: 20px" :model="businessForm"
				:rules="businessFormRule" label-width="auto" class="demo-ruleForm" label-position="left">
				<el-form-item label="贸易身份:" prop="tradeStatus">
					<div class="flex alit" style="margin-top: 10px;">
						<el-radio-group v-model="businessForm.tradeStatus" @change="changeRadio">
							<el-radio :label="2">我要采购</el-radio>
							<el-radio :label="1">我要供应</el-radio>
						</el-radio-group>
					</div>
				</el-form-item>
				<el-form-item label="会员昵称:" prop="memberName">
					<el-input v-model="businessForm.memberName" type="text" placeholder="请输入会员昵称" autocomplete="off" />
				</el-form-item>
				<el-form-item label="登录密码:" prop="loginPassword">
					<el-input v-model="businessForm.loginPassword" type="password" placeholder="请输入登录密码"
						autocomplete="off" />
				</el-form-item>
				<el-form-item label="密码确认:" prop="checkPass">
					<el-input v-model="businessForm.checkPass" type="password" placeholder="请再次输入您的密码"
						autocomplete="off" />
				</el-form-item>
				<el-form-item label="企业名称:" prop="enterpriseName">
					<el-input v-model="businessForm.enterpriseName" type="text" placeholder="请输入企业名称"
						autocomplete="off" />
				</el-form-item>
				<el-form-item label="营业执照:" prop="">
					<el-upload class="avatar-uploader" action="" :data="{ type: 'enterpriseLicense' }"
						:show-file-list="false" :http-request="uploadImage" style="margin-left: 0;width: 0px;">
						<img v-if="businessForm.enterpriseLicense" :src="businessForm.enterpriseLicense"
							class="avatar" />
						<i v-else class="el-icon-plus avatar-uploader-icon"></i>
					</el-upload>
				</el-form-item>
				<el-form-item label="纳税人资格证明:" prop="">
					<el-upload class="avatar-uploader" action="" c :show-file-list="false" :http-request="uploadImage"
						style="margin-left: 0;width: 0px;">
						<img v-if="businessForm.taxRegistration" :src="businessForm.taxRegistration" class="avatar" />
						<i v-else class="el-icon-plus avatar-uploader-icon"></i>
					</el-upload>
				</el-form-item>
				<el-form-item label="资质/许可证书:" prop="">
					<div class="flex" style="width: 600px;flex-wrap: wrap;">
						<div v-for="(item, index) in businessForm.qualifications"
							style="position: relative;width: auto;">
							<div class="item-actions flex alit justc">
								<i class="el-icon-search" @click="(preViewImage = item), (viewImage = true)"></i>
								<i class="el-icon-delete" style="margin-left: 20px;"
									@click="businessForm.qualifications.splice(index, 1)"></i>
							</div>
							<img class="" :src="item" style="width: 178px; height: 178px; margin-right: 5px" alt="" />
						</div>
						<el-upload action="" :data="{ type: 'qualifications' }" class="avatar-uploader"
							:show-file-list="false" :http-request="uploadImage" list-type="avatar"
							style="margin-left: 0;width: 0px;">
							<i class="el-icon-plus avatar-uploader-icon"></i>
						</el-upload>
					</div>
				</el-form-item>
				<el-form-item label="法人代表人:" prop="">
					<el-input v-model="businessForm.enterpriseOperator" type="text" placeholder="请输入法人代表人"
						autocomplete="off" />
				</el-form-item>
				<el-form-item label="法人证件照:" prop="">
					<el-upload class="avatar-uploader" action="" :data="{ type: 'enterpriseCardPositive' }"
						:show-file-list="false" :http-request="uploadImage">
						<img v-if="businessForm.enterpriseCardPositive" :src="businessForm.enterpriseCardPositive"
							class="avatar" />
						<i v-else class="el-icon-plus avatar-uploader-icon">证件照正面</i>
					</el-upload>
					<el-upload class="avatar-uploader" action="" :data="{ type: 'enterpriseCardSide' }"
						:show-file-list="false" :http-request="uploadImage">
						<img v-if="businessForm.enterpriseCardSide" :src="businessForm.enterpriseCardSide"
							class="avatar" />
						<i v-else class="el-icon-plus avatar-uploader-icon">证件照反面</i>
					</el-upload>
				</el-form-item>
				<el-form-item label="公司电话(座机):" prop="companyPhone">
					<el-input v-model="businessForm.companyPhone" type="text" placeholder="请输入公司电话(座机)"
						autocomplete="off" />
				</el-form-item>

				<el-form-item label="合作协议:" prop="">
					<el-upload class="avatar-uploader" action="" :data="{ type: 'agreement' }" :show-file-list="false"
						:http-request="uploadImage">
						<img v-if="businessForm.agreement" :src="businessForm.agreement" class="avatar" />
						<i v-else class="el-icon-plus avatar-uploader-icon"></i>
					</el-upload>
				</el-form-item>
				<el-form-item label="公司地址:" prop="">
					<div class="flexCol">
						<el-cascader size="large" :props="{
							value: 'label',
						}" :options="sortedRegionData" v-model="businessForm.enterpriseRegion" @change="addressChange" />
						<el-input v-model="businessForm.enterpriseAdress" style="margin-top: 10px" type="text"
							placeholder="请输入详细地址" autocomplete="off" />
					</div>
				</el-form-item>
				<el-form-item label="联系人:" prop="contactName">
					<el-input v-model="businessForm.contactName" type="text" placeholder="请输入联系人" autocomplete="off" />
				</el-form-item>
				<el-form-item label="联系人证件照:" prop="">
					<el-upload class="avatar-uploader" action="" :data="{ type: 'contactPerson' }"
						:show-file-list="false" :http-request="uploadImage">
						<img v-if="businessForm.contactPerson" :src="businessForm.contactPerson" class="avatar" />
						<i v-else class="el-icon-plus avatar-uploader-icon">证件照正面</i>
					</el-upload>
					<el-upload class="avatar-uploader" action="" :data="{ type: 'contactPersonReverse' }"
						:show-file-list="false" :http-request="uploadImage">
						<img v-if="businessForm.contactPersonReverse" :src="businessForm.contactPersonReverse"
							class="avatar" />
						<i v-else class="el-icon-plus avatar-uploader-icon">证件照反面</i>
					</el-upload>
				</el-form-item>

				<el-form-item label="手机号码:" prop="shopPhone">
					<el-input v-model="businessForm.shopPhone" maxlength="11" placeholder="请输入您的手机号码" />
				</el-form-item>
				<el-form-item label="验证码:" prop="verifyCode">
					<el-input v-model="businessForm.verifyCode" maxlength="6" placeholder="请输入验证码">
						<template #append>
							<span class="codeBtn" style="cursor: pointer" v-show="accountFormSetting.visibleGetCode"
								@click="getCode">获取验证码</span>
							<span v-show="!accountFormSetting.visibleGetCode" class="codeBtn codeCount">重新获取({{
								accountFormSetting.codeCountNum }}s)</span>
						</template>
					</el-input>
				</el-form-item>
				<el-form-item label="服务工号:" v-if="businessForm.tradeStatus == 1" prop="pushCode">
					<el-input v-model="businessForm.pushCode" type="text" placeholder="请输入服务工号" autocomplete="off" />
				</el-form-item>
				<el-form-item label="服务工号:" v-else>
					<el-input v-model="businessForm.pushCode" type="text" placeholder="请输入服务工号" autocomplete="off" />
				</el-form-item>
				<div class="" style="display: flex; margin-top: 15px">
					<el-checkbox v-model="haveRead" style="margin-right: 10px; margin-top: 4px" />
					<div class="pointer" @click="">
						<a class="" @click="haveRead = !haveRead">我已阅读并同意</a>
						<a class="hightLight" @click="readAgreement(3)">《重庆火锅产业要素交易平台供应商平台入驻协议》</a>
						<a class="hightLight" @click="readAgreement(4)">《重庆火锅产业要素交易平台采购商入驻协议》</a>
					</div>
				</div>
				<el-form-item class="">
					<div class="flex alit justc" style="width: 100%; margin-top: 30px">
						<el-button type="primary" class="btn" size="large" @click="submitBusinessForm()">
							注册
						</el-button>
						<el-button size="large" class="btn2" @click="resetBusinessForm()">重置</el-button>
					</div>
				</el-form-item>
			</el-form>
		</div>

		<el-dialog :visible.sync="viewImage">
			<img :src="preViewImage" style="width: 100%" alt="Preview Image" />
		</el-dialog>

		<el-dialog :title="nowCheckIndex" :visible.sync="dialogShow" width="80%">
			<div class="" style="height: 60vh; overflow-y: scroll">
				<div class="ys-content" v-html="wordText"></div>
			</div>
		</el-dialog>

		<el-image-viewer v-if="showImagePreview" :zoom-rate="1.2" :onClose="closeViewImage"
			:url-list="['https://xlqy.obs.cn-southwest-2.myhuaweicloud.com/hotpot/app/register/%E4%BE%9B%E5%BA%94%E5%95%86%E6%8F%90%E7%A4%BA.jpg']" />



	</div>
</template>

<script>
// import {
// 	getTheCode,
// 	login,
// 	enterpriseCheck,
// 	verifyPhone
// } from "@/api/login.js";
// import {
// 	ref,
// 	watch
// } from "vue";
import upload from "../../utils/upload";
import {
	regionData,
	CodeToText
} from "element-china-area-data";
import mammoth from "mammoth";
// import Cookie from "js-cookie";
// import {    
// 	useUser
// } from "@/store/user";
import ElImageViewer from 'element-ui/packages/image/src/image-viewer.vue'
import { log } from "util";
export default {
	components: { ElImageViewer },
	data() {
		const validatePass = (rule, value, callback) => {
			if (value === "") {
				callback(new Error("请输入密码"));
			} else {
				callback();
			}
		};
		const validatePass2 = (rule, value, callback) => {
			if (value === "") {
				callback(new Error("请再次输入密码"));
			} else if (
				value !==
				(this.type == 2 ? this.ruleForm.loginPassword : this.businessForm.loginPassword)
			) {
				console.log(value);
				console.log(value);
				callback(new Error("两次密码输入不一致"));
			} else {
				callback();
			}
		};
		const checkPhone = (rule, value, callback) => {
			if (value === "") {
				callback(new Error("请输入手机号码"));
			} else {
				let reg = /^1[13456789]\d{9}$/;
				if (!reg.test(value)) {
					callback(new Error("手机号格式错误"));
				} else {
					callback();
				}
			}
		};

		const checkEnterpriseCardPositive = (rule, value, callback) => {
			//法人证件照判断
			if (
				this.businessForm.enterpriseCardPositive &&
				this.businessForm.enterpriseCardSide
			) {
				callback();
			} else {
				callback(new Error("请上传完整法人证件照"));
			}
		};
		const checkContactPerson = (rule, value, callback) => {
			//法人证件照判断
			if (this.businessForm.contactPerson && this.businessForm.contactPersonReverse) {
				callback();
			} else {
				callback(new Error("请上传完整联系人证件照"));
			}
		};
		return {
			showImagePreview: false,
			haveRead: false,
			viewImage: false,
			preViewImage: "",
			areaCode: "",
			sortedRegionData: regionData.slice(),
			chongqingIndex: regionData.slice().findIndex((region) => region.label === "重庆市"),
			imageUrl: "",
			accountFormSetting: {
				loadingLogin: false,
				visibleGetCode: true, // 验证码获取cd
				codeCountNum: 0, // 验证码获取倒计时
				dialogAgreement: false, // 服务协议
			},
			type: 1,
			timer: "",
			businessForm2: {
				//企业注册
				loginPassword: "123123", //密码
				checkPass: "123123", //二次密码
				shopPhone: "17353166886", //电话号码
				verifyCode: "7845", //验证码
				memberName: "会员名称", //会员名称
				enterpriseName: "企业名称", //企业名称
				enterpriseLicense: "https://xlqy.obs.cn-southwest-2.myhuaweicloud.com/hotpot/settledMerchant/202465/1717580806366.png", //营业执照
				taxRegistration: "https://xlqy.obs.cn-southwest-2.myhuaweicloud.com/hotpot/settledMerchant/202465/1717580806366.png", //纳税人资格证明
				qualifications: [
					"https://xlqy.obs.cn-southwest-2.myhuaweicloud.com/hotpot/settledMerchant/202465/1717580806366.png",
				], //资质/许可证书
				enterpriseOperator: "法人", //法人
				enterpriseCardPositive: "https://xlqy.obs.cn-southwest-2.myhuaweicloud.com/hotpot/settledMerchant/202465/1717580806366.png", //法人证件照:(可添加附件) 正面
				enterpriseCardSide: "https://xlqy.obs.cn-southwest-2.myhuaweicloud.com/hotpot/settledMerchant/202465/1717580806366.png", //法人证件照:(可添加附件) 反面
				contactName: "联系人", //联系人
				contactPerson: "https://xlqy.obs.cn-southwest-2.myhuaweicloud.com/hotpot/settledMerchant/202465/1717580806366.png", //联系人证件照正面:
				contactPersonReverse: "https://xlqy.obs.cn-southwest-2.myhuaweicloud.com/hotpot/settledMerchant/202465/1717580806366.png", //联系人证件照反面:
				agreement: "https://xlqy.obs.cn-southwest-2.myhuaweicloud.com/hotpot/settledMerchant/202465/1717580806366.png", //合作协议
				enterpriseRegion: ["北京市", "市辖区", "东城区"], //省市区
				enterpriseAdress: "详细地址", //详细地址
				tradeStatus: 2, //贸易身份 1我要销售 2我要采购  3两者都是 我要采购两者都是
				companyPhone: "0232222222", //公司电话
				pushCode: '', //服务工号
			},
			businessForm: {
				//企业注册
				loginPassword: "", //密码
				checkPass: "", //二次密码
				shopPhone: "", //电话号码
				verifyCode: "", //验证码
				memberName: "", //会员名称
				enterpriseName: "", //企业名称
				enterpriseLicense: "", //营业执照
				taxRegistration: "", //纳税人资格证明
				qualifications: [], //资质/许可证书
				enterpriseOperator: "", //法人
				enterpriseCardPositive: "", //法人证件照:(可添加附件) 正面
				enterpriseCardSide: "", //法人证件照:(可添加附件) 反面
				contactName: "", //联系人
				contactPerson: "", //联系人证件照正面:
				contactPersonReverse: "", //联系人证件照反面:
				agreement: "", //合作协议
				enterpriseRegion: "", //省市区
				enterpriseAdress: "", //详细地址
				tradeStatus: 2, //贸易身份 1我要销售 2我要采购  3两者都是 我要采购两者都是
				companyPhone: "", //公司电话
				pushCode: '', //服务工号
			},
			businessFormRule: {
				enterpriseRegion: [{
					required: true,
					message: "请选择公司地址",
					trigger: "change",
				}],
				pushCode: [{
					required: true,
					message: "请输入服务工号",
					trigger: "pushCode",
				}],
				tradeStatus: [{
					required: true,
					message: "请选择贸易身份",
					trigger: "change",
				},],
				enterpriseOperator: [{
					required: true,
					message: "请输入法人代表人",
					trigger: "blur",
				},],
				agreement: [{
					required: true,
					message: "请选择合作协议",
					trigger: "change",
				},],
				enterpriseLicense: [{
					required: true,
					message: "请选择营业执照",
					trigger: "change",
				},],
				taxRegistration: [{
					required: true,
					message: "请选择纳税人资格证明",
					trigger: "change",
				},],
				qualifications: [{
					required: true,
					message: "请选择资质/许可证书",
					trigger: "change",
				},],
				memberName: [{
					required: true,
					message: "请输入会员名称",
					trigger: "blur",
				},],
				enterpriseName: [{
					required: true,
					message: "请输入企业名称",
					trigger: "blur",
				},],
				loginPassword: [{
					required: true,
					validator: validatePass,
					trigger: "blur",
				},],
				checkPass: [{
					required: true,
					validator: validatePass2,
					trigger: "blur",
				},],
				shopPhone: [{
					required: true,
					validator: checkPhone,
					trigger: "blur",
				},],
				verifyCode: [{
					required: true,
					message: "请输入验证码",
					trigger: "blur",
				},],
				enterpriseCardPositive: [{
					required: true,
					validator: checkEnterpriseCardPositive,
					trigger: "blur",
				},],
				contactPerson: [{
					required: true,
					validator: checkContactPerson,
					trigger: "blur",
				},],
			},
			ruleForm: {
				//个人注册
				memberName: "", //会员名称
				loginPassword: "", //密码
				checkPass: "", //二次密码
				phone: "", //电话号码
				verificationCode: "", //验证码
				type: 1, //1-注册 2-登录
				pushCode: ''
			},
			rules: {
				//个人注册校验
				loginPassword: [{
					required: true,
					validator: validatePass,
					trigger: "blur",
				},],
				checkPass: [{
					required: true,
					validator: validatePass2,
					trigger: "blur",
				},],
				phone: [{
					required: true,
					validator: checkPhone,
					trigger: "blur",
				},],
				verificationCode: [{
					required: true,
					message: "请输入验证码",
					trigger: "blur",
				},],
				memberName: [{
					required: true,
					message: "请输入会员名称",
					trigger: "blur",
				},],
			},
			wordText: "", //协议文本
			dialogShow: false, //协议框
			nowCheckIndex: "", //当前选择的框
		};
	},
	mounted() { },
	methods: {
		changeRadio(e) {
			// if (e == 1) {
			// 	document.body.style.overflow = 'hidden'
			// 	this.showImagePreview = true
			// }
		},
		closeViewImage() {
			document.body.style.overflow = 'auto'
			this.showImagePreview = false
			this.businessForm.tradeStatus = 2
		},
		readAgreement(e) {

			if (e == 1) {
				this.nowCheckIndex = "重庆火锅产业要素交易平台用户服务协议";
				this.getWordText(
					"https://qgst.obs.cn-southwest-2.myhuaweicloud.com/hotpot/privacyPolicy/%E9%87%8D%E5%BA%86%E7%81%AB%E9%94%85%E4%BA%A7%E4%B8%9A%E8%A6%81%E7%B4%A0%E4%BA%A4%E6%98%93%E5%B9%B3%E5%8F%B0--%E5%9C%A8%E7%BA%BF%E4%BA%A4%E6%98%93%E6%9C%8D%E5%8A%A1%E6%9D%A1%E6%AC%BE.docx"
				);
			} else if (e == 2) {
				this.nowCheckIndex = "重庆火锅产业要素交易平台用户隐私政策";
				this.getWordText(
					"https://qgst.obs.cn-southwest-2.myhuaweicloud.com/hotpot/privacyPolicy/%E9%87%8D%E5%BA%86%E7%81%AB%E9%94%85%E4%BA%A7%E4%B8%9A%E8%A6%81%E7%B4%A0%E4%BA%A4%E6%98%93%E5%B9%B3%E5%8F%B0%E7%94%A8%E6%88%B7--%E9%9A%90%E7%A7%81%E6%94%BF%E7%AD%96.docx"
				);
			} else if (e == 3) {
				this.nowCheckIndex = "重庆火锅产业要素交易平台供应商平台入驻协议";
				this.getWordText(
					"https://qgst.obs.cn-southwest-2.myhuaweicloud.com/hotpot/privacyPolicy/%E4%BE%9B%E5%BA%94%E5%95%86%E5%B9%B3%E5%8F%B0%E5%85%A5%E9%A9%BB%E5%8D%8F%E8%AE%AE.docx"
				);
			} else {
				this.nowCheckIndex = "重庆火锅产业要素交易平台采购商入驻协议";
				this.getWordText(
					"https://qgst.obs.cn-southwest-2.myhuaweicloud.com/hotpot/privacyPolicy/%E9%87%87%E8%B4%AD%E5%95%86%E5%85%A5%E9%A9%BB%E5%8D%8F%E8%AE%AE.docx"
				);
			}
		},
		getWordText(e) {
			const xhr = new XMLHttpRequest();


			xhr.open("get", e, true);
			xhr.responseType = "arraybuffer";
			xhr.onload = () => {

				if (xhr.status == 200) {
					mammoth
						.convertToHtml({
							arrayBuffer: new Uint8Array(xhr.response),
						})
						.then((resultObject) => {

							this.wordText = resultObject.value;
							this.dialogShow = true;
							console.log(this.dialogShow);
						});
				}
			};
			xhr.send();
		},
		addressChange(e) {
			console.log(e);
		},
		uploadImage(file) {
			upload(file.file).then((res) => {
				if (file.data.type == "qualifications") {
					this.businessForm.qualifications.push(res);
				} else {
					this.businessForm[file.data.type] = res;
				}
			});
		},
		resetForm() {
			this.$refs.ruleFormRef.resetFields();
		},
		resetBusinessForm() {
			this.$refs.businessForm.resetFields();
		},
		submitForm() {
			if (!this.haveRead) {
				// ElNotification({
				// 	title: "提示",
				// 	message: "请先阅读并同意《重庆火锅产业要素交易平台用户服务协议》《重庆火锅产业要素交易平台用户隐私政策》",
				// 	duration: 2000,
				// });
				this.$notify({
					title: '提示',
					message: "请先阅读并同意《重庆火锅产业要素交易平台用户服务协议》《重庆火锅产业要素交易平台用户隐私政策》",
					duration: 2000,
				});
				return;
			}
			this.$refs.reff.validate((valid) => {
				if (valid) {
					// 在这里处理表单验证通过的逻辑
					login(this.ruleForm).then((response) => {
						console.log(response);
						const res = response.data;
						if (res.code === "200") {
							Cookie.set("token", res.data.token, {
								expires: 7,
							});
							// getUserInfoData();
							ElMessage({
								message: "注册成功",
								type: "success",
								customClass: "messageZindex",
							});
							localStorage.setItem("loginInfo", JSON.stringify(res.data));
							// useUser().setShowLogin()
							useUser().setIsLogin(true);
							this.$router.replace("/index");
						} else {
							ElMessage({
								message: res.message,
								type: "warning",
								customClass: "messageZindex",
							});
						}
					});
				} else {
					// 在这里处理表单验证失败的逻辑
				}
			});
		},
		submitBusinessForm() {
			if (!this.haveRead) {
				this.$notify({
					title: '提示',
					message: "请先阅读并同意《重庆火锅产业要素交易平台供应商平台入驻协议》《重庆火锅产业要素交易平台采购商入驻协议》",
					duration: 2000,
				});
				return;
			}
			let that = this;
			this.$refs.businessForm.validate((valid) => {
				if (valid) {
					this.$request('/app-api/member/user/verify-identity', {
						mobile: this.businessForm.shopPhone,
					}, 'get').then((res) => {
						console.log(res.data, 'identityidentityidentity');

						console.log(res.data.phone, that.businessForm.shopPhone);
						if (res.data && res.data.identity == "企业") {

							this.$notify({
								title: '提示',
								message: "当前账户已为企业账户,可直接登录",
								customClass: "messageZindex",
								duration: 2000,
							});

						} else if (
							res.data &&
							res.data.phone == that.businessForm.shopPhone
						) {
							ElMessageBox.confirm(
								"您当前的账户为普通账户,是否继续申请为企业账户？",
								"提示", {
								confirmButtonText: "确认",
								cancelButtonText: "取消",
								type: "warning",
							}
							)
								.then(() => {
									that.bussSubmit();
								})
								.catch(() => { });
						} else {
							that.bussSubmit();
						}
					});
				} else {
					// 在这里处理表单验证失败的逻辑
				}
			});
		},
		bussSubmit() {
			// 在这里处理表单验证通过的逻辑
			let da = JSON.parse(JSON.stringify(this.businessForm));
			da.qualifications = da.qualifications.join(",");
			if (da.enterpriseRegion.length > 0) {
				da.enterpriseRegion = da.enterpriseRegion.join(",");
			}

			enterpriseCheck(da).then((response) => {
				const res = response.data;
				if (res.code === "200") {
					ElMessage({
						message: "入驻申请提交成功,请等待审核",
						type: "success",
						customClass: "messageZindex",
						duration: 4000,
					});
					this.$router.replace("/index");
				} else {
					ElMessage({
						message: res.message,
						type: "warning",
						customClass: "messageZindex",
						duration: 4000,
					});
				}
			});
		},
		getCode() {
			const TIME_COUNT = 60;
			let reg = /^1[13456789]\d{9}$/;
			// this.type == 2 ? this.ruleForm.loginPassword : this.businessForm.loginPassword
			if (!reg.test(this.type == 2 ? this.ruleForm.phone : this.businessForm.shopPhone)) {
				ElMessage({
					message: "手机号格式错误",
					type: "error",
					customClass: "messageZindex",
				});
				return;
			}
			getTheCode({
				phone: this.type == 2 ? this.ruleForm.phone : this.businessForm.shopPhone,
			}).then((response) => {
				this.accountFormSetting.codeCountNum = TIME_COUNT;
				this.accountFormSetting.visibleGetCode = false;
				this.timer = setInterval(() => {
					if (
						this.accountFormSetting.codeCountNum > 0 &&
						this.accountFormSetting.codeCountNum <= TIME_COUNT
					) {
						this.accountFormSetting.codeCountNum--;
					} else {
						this.accountFormSetting.visibleGetCode = true;
						clearInterval(this.timer);
						this.timer = null;
					}
				}, 1000);
			});
		},
	},
};
</script>

<style scoped>
.btn {
	/* margin: 50px auto 0 auto; */
	height: 42px;

	line-height: 42px;
	text-align: center;
	margin-left: 10px;
	background: #fb2d1f;
	border-radius: 22px;
	font-size: 15px;
	font-weight: 500;
	color: #ffffff;
	width: 40%;
}

.btn2 {
	height: 42px;
	line-height: 42px;
	text-align: center;
	margin-left: 10px;
	background: #fff;
	border-radius: 22px;
	font-size: 15px;
	font-weight: 500;
	color: #000;
	width: 40%;
}

.ys-content {
	width: 100%;
	font-family: Microsoft YaHei;
	font-weight: 400;
	font-size: 13px;
	color: #707070;
	line-height: 32px;
	white-space: pre-wrap;
	margin-bottom: 30px;
	text-align: left;
}

.hightLight {
	color: #c5aa7b;
}

.item-actions {
	position: absolute;
	width: 178px;
	height: 178px;
	left: 0;
	top: 0;
	cursor: pointer;
	text-align: center;
	color: #fff;
	opacity: 0;
	font-size: 20px;
	background-color: rgba(0, 0, 0, 0.5);
	/* transition: opacity .3s; */
	z-index: 9999 !important;
}

.item-actions:hover {
	opacity: 1;
}

/* .avatar-uploader{
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
} */

.avatar-uploader .el-upload {
	border: 1px dashed #d9d9d9;
	border-radius: 6px;
	cursor: pointer;
	position: relative;
	overflow: hidden;
}

.avatar-uploader .el-upload:hover {
	border-color: #409EFF;
}

.avatar-uploader-icon {
	font-size: 28px;
	color: #8c939d;
	width: 178px;
	height: 178px;
	display: flex !important;
	flex-direction: column !important;
	align-items: center !important;
	justify-content: center !important;
	text-align: center;
}

.avatar-uploader {
	margin-left: 0 !important;
	width: 0px !important;
}

.avatar {
	width: 178px;
	height: 178px;
	display: block;
}

.el-input {
	width: 400px;
	height: 40px;
}

.zh-type-check {
	width: 30%;
	text-align: center;
	padding-bottom: 10px;
	font-size: 18px;
	font-weight: 900;
	color: #ff7300;
	border-bottom: 2px solid #ff7300;
	margin-bottom: -1px;
	cursor: pointer;
}

.zh-type {
	width: 30%;
	text-align: center;
	padding-bottom: 10px;
	font-size: 18px;
	font-weight: 900;
	cursor: pointer;
	border-bottom: 2px solid rgba(255, 255, 255, 0);
	margin-bottom: -2px;
}

.register {
	background-color: #f1f1f1;
	cursor: pointer;
	color: #000;
}

.register:hover {
	background-color: #ff6000;
	color: #fff;
}

.zc {
	width: 100%;
	height: 58px;
	line-height: 58px;
	font-size: 18px;
	text-align: center;
}
</style>