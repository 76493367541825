<!--  -->
<template>
    <div class='flexCol alit'>
        <div class="flex justb alit" style="width: 70%;">
            <div class="top-bag">我的订单 > 订单详情</div>
            <div class="back" @click="back">返回上一级</div>
        </div>
        <div class="order-no">
            订单号：{{ orderInfo.no }}
        </div>

        <div class="flex justc" style="width: 70%;margin: 30px 0;">
            <!-- <div class="box" :class="{ colorGlod: data.status >= 1 }">
                <svg-icon style="width: 90px;height: 90px;" name="order-detail-submit" />
                <p>提交订单</p>
            </div>
            <span class="defaultSpot" :class="{ glodSpot: data.status > 0 }"></span>
            <div class="box" :class="{ colorGlod: data.status >= 2 }">
                <svg-icon style="width: 90px;height: 90px;" name="order-detail-loading" />
                <p>订单处理</p>
            </div>
            <span class="defaultSpot" v-if="data.status !== 5"
                :class="{ glodSpot: data.status !== 1 && data.status !== '' }"></span>
            <div class="box" v-if="data.status !== 5" :class="{ colorGlod: data.status >= 3 }">
                <svg-icon style="width: 90px;height: 90px;" name="order-detail-store" />
                <p>商品出库</p>
            </div>
            <span class="defaultSpot" v-if="data.status !== 5"
                :class="{ glodSpot: data.status >= 4, redSpot: data.status === 3 }"></span>
            <div class="box" v-if="data.status !== 5"
                :class="{ colorGlod: data.status >= 4, colorRed: data.status === 3 }">
                <svg-icon style="width: 90px;height: 90px;" name="order-detail-express" />
                <p>等待收货</p>
            </div>
            <span class="defaultSpot" :class="{ glodSpot: data.status >= 4 }"></span>
            <div class="box" :class="{ colorGlod: data.status >= 4 }">
                <svg-icon style="width: 90px;height: 90px;" name="order-detail-finish" />
                <p v-if="data.status !== 5">已完成</p>
                <p v-if="data.status === 5">已关闭</p>
            </div> -->
            <div class="" style="text-align: center;font-size: 26px;color: #999;"> {{ changeStatus(orderInfo.status) }}
            </div>
        </div>
        <div class="flex" style="width: 70%;" v-if="orderInfo">
            <div class="" style="width: 50%;border: 1px solid #F3F4F5;font-size: 14px;padding: 20px;">
                <div class="" style="font-size: 16px;">收货信息</div>
                <div style="margin: 20px 0;">收货地址：{{ orderInfo.receiverAreaName }}{{ orderInfo.receiverDetailAddress }}
                </div>
                <div class="flex">
                    <div>收货人：{{ orderInfo.receiverName }}</div>
                    <div style="margin-left: 140px;">收货电话：{{ orderInfo.receiverMobile }}</div>
                </div>
            </div>
            <div class="" style="width: 50%;border: 1px solid #F3F4F5;font-size: 14px;padding: 20px;">
                <div class="" style="font-size: 16px;">卖家信息</div>
                <p>店铺名称：{{ orderInfo.receiverAreaName }}{{ orderInfo.receiverDetailAddress }}</p>
            </div>
        </div>
        <div class="flex" style="width: 70%;margin: 20px 0;" v-if="orderInfo">
            <div class="" style="width: 100%;border: 1px solid #F3F4F5;font-size: 14px;padding: 20px;">
                <div class="" style="font-size: 16px;">订单信息</div>
                <div class="flex" style="margin: 20px 0;">
                    <div>订单编号： {{ orderInfo.no }}</div>
                    <div style="margin-left: 140px;">创建时间：{{ utils.changeTime(orderInfo.createTime) }}</div>
                </div>
                <div class="flex">
                    <div></div>
                    <div>备注：</div>
                </div>
            </div>
        </div>
        <!-- <div class="flex" style="width: 70%;" v-if="orderInfo">
            <div class="" style="width: 50%;border: 1px solid #F3F4F5;font-size: 14px;padding: 20px;">
                <div class="" style="font-size: 16px;">物流信息</div>
                <div style="margin: 20px 0;">暂无物流信息</div>
            </div>
            <div class="" style="width: 50%;border: 1px solid #F3F4F5;font-size: 14px;padding: 20px;">
                <div class="" style="font-size: 16px;">物流司机信息</div>
            </div>
        </div> -->
        <div class="" style="width: 70%;border: 1px solid #e5e5e5;margin: 20px 0;">
            <div class="flex alit"
                style="width: 100%;background-color: rgba(226,119,31,.3);font-size: 14px;height: 50px;">
                <div class="" style="width: 30%;">&nbsp;&nbsp;&nbsp;商品</div>
                <div class="" style="width: 12%;text-align: center;">商品规格</div>
                <div class="" style="width: 10%;text-align: center;">送达时间</div>
                <div class="" style="width: 10%;text-align: center;">数量</div>
                <div class="" style="width: 10%;text-align: center;">单价</div>
                <div class="" style="width: 10%;text-align: center;">状态</div>
                <div class="" style="flex: 1;text-align: center;">操作</div>
            </div>
            <div class="flex alit" style="width: 100%;font-size: 14px;margin: 10px 0;" v-for="item in orderInfo.items">
                <div class="flex alit" style="width: 30%;">
                    <img :src="item.picUrl" style="width: 100px;height: 100px;margin-right: 10px;margin-left: 10px;"
                        alt="">
                    {{ item.spuName }}
                </div>
                <div class="" style="width: 12%;text-align: center;">{{ item.properties[0].valueName }}</div>
                <div class="" style="width: 10%;text-align: center;">送达时间</div>
                <div class="" style="width: 10%;text-align: center;">{{ item.count }}</div>
                <div class="" style="width: 10%;text-align: center;">￥{{ item.price / 100 }}</div>
                <div class="" style="width: 10%;text-align: center;">{{ changeStatus(item.status) }}</div>
                <div class="" style="flex: 1; text-align: center;" class="flex justc">
                    <div class="order-button" v-if="item.status == 20 || item.status == 30" @click="showWlInfo(item)"
                        style="cursor: pointer;">查看物流</div>
                    <div class="order-button" @click="refund(item)" style="cursor: pointer;">申请售后</div>
                    <!-- v-if="orderInfo.status != 0 && orderInfo.status != 40" -->
                </div>
            </div>
        </div>

        <div class="flex" style="width: 70%;" v-if="orderInfo">
            <div class="" style="width: 100%;border: 1px solid #F3F4F5;font-size: 14px;padding: 20px;">
                <div class="flex">
                    <div>商品总价：<a style="color: #c5aa7b;">￥{{ orderInfo.totalPrice / 100 }}</a> </div>
                    <div style="margin-left: 140px;">运费：<a style="color: #c5aa7b;">￥0</a></div>
                    <div style="margin-left: 140px;">平台优惠：<a style="color: #c5aa7b;">￥0</a></div>
                    <div style="margin-left: 140px;">需付款：<a style="color: #c5aa7b;">￥{{ orderInfo.payPrice / 100 }}</a>
                    </div>
                </div>
            </div>
        </div>

        <div class="flex justc" style="width: 70%;margin-bottom: 60px;">
            <button class="css-button-arrow--red bottom-submit" @click="payShow = true"
                v-if="orderInfo.status == 0">立即支付</button>
            <!-- <button class="css-button-arrow--red bottom-submit"
                v-if="orderInfo.status != 0 && orderInfo.status != 40">申请售后</button> -->
            <button class="css-button-arrow--red bottom-submit" v-if="orderInfo.status == 0"
                @click="canalOrder">取消订单</button>
            <button class="css-button-arrow--red bottom-submit" @click="taskOverShow = true"
                v-if="orderInfo.status == 20">确认收货</button>
        </div>

        <el-dialog title="申请售后" :visible.sync="refundShow" width="40%">
            <refund v-if="refundShow" :optionsData="nowRefundData" @canal="refundShow = false"
                @submitSucc="getOrderInfo"></refund>
        </el-dialog>

        <el-dialog title="支付" :visible.sync="qrcodeShow" width="400px">
            <qrcode v-if="qrcodeShow" :text="submitQrcode" :payId="payId" style="width: 300px;"
                @paySuccess="paySuccess"></qrcode>
        </el-dialog>

        <el-dialog title="请选择支付方式" :visible.sync="payShow">
            <orderPay @close="payShow = false" @goPay="payOrder"></orderPay>
        </el-dialog>
        <el-dialog title="确认收货" :visible.sync="taskOverShow">
            <task-over :orderInfo="orderInfo" @canal="taskOverShow = false" @taskSuccess="taskSuccess"></task-over>
        </el-dialog>

        <el-dialog title="物流信息" :visible.sync="wlShow" top="4vh">
            <wl-info :wlInfo="wlInfo" @canal="wlShow = false"></wl-info>
        </el-dialog>
    </div>
</template>

<script>
import Qrcode from '@/components/qrcode.vue';
import Refund from './refund.vue';
import OrderPay from '@/views/order/orderPay.vue'
import TaskOver from './taskOver.vue';
import WlInfo from './wlInfo.vue';

export default {
    components: { Refund, Qrcode, OrderPay, TaskOver, WlInfo },
    data() {
        return {
            data: {
                status: 3,
            },
            id: '',
            orderInfo: null,
            refundShow: false,
            nowRefundData: null,
            submitQrcode: null,
            payId: '',
            qrcodeShow: false,
            payShow: false,
            taskOverShow: false,
            wlShow:false,
            wlInfo:null
        }
    },
    // 监听属性 类似于data概念
    computed: {},
    // 监控data中的数据变化
    watch: {},
    // 方法集合
    methods: {
        showWlInfo(e){
            this.wlInfo = e
            this.wlShow = true
        },
        taskSuccess() {
            this.taskOverShow = false
            this.getOrderInfo()
        },
        changeStatus(e) {
            switch (e) {
                case 0:
                    return '待付款'
                    break;
                case 10:
                    return '待发货'
                    break;
                case 20:
                    return '待收货'
                    break;
                case 30:
                    return '已完成'
                    break;
                case 40:
                    return '已取消'
                    break;
                default:
                    break;
            }
        },
        refund(e) {
            this.nowRefundData = e
            this.refundShow = true
        },
        //取消订单
        canalOrder() {
            this.$confirm('是否确认取消该订单?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                this.$request(`/app-api/trade/order/cancel`, {
                    id: this.orderInfo.id
                }, 'delete').then(res => {
                    console.log(res);
                    if (res.data) {
                        this.$message({
                            message: '取消订单成功',
                            type: 'success'
                        });
                        this.getOrderInfo()
                    }
                })
            }).catch(() => {

            });
        },
        paySuccess() {
            this.qrcodeShow = false
            this.submitQrcode = null
            this.getOrderInfo()
        },
        payOrder(index) {
            this.submitQrcode = null
            this.payId = ''
            if (index == 1 || index == 2) {
                //微信支付
                this.$request('/app-api/pay/order/submit', {
                    id: this.orderInfo.payOrderId,
                    channelCode: 'yl_h5'
                }, 'post').then(submit => {
                    this.payId = this.orderInfo.payOrderId
                    this.submitQrcode = submit.data.displayContent
                    this.qrcodeShow = true
                })
            }
            if (index == 3) {
                //个人支付
                this.$request('/app-api/pay/order/submit', {
                    id: this.orderInfo.payOrderId,
                    channelCode: 'yl_b2c'
                }, 'post').then(submit => {
                    this.payId = this.orderInfo.payOrderId
                    window.open(submit.data.displayContent)
                    this.qrcodeShow = true
                })
            }
            if (index == 4) {
                //个人支付
                this.$request('/app-api/pay/order/submit', {
                    id: this.orderInfo.payOrderId,
                    channelCode: 'yl_b2b'
                }, 'post').then(submit => {
                    this.payId = this.orderInfo.payOrderId
                    window.open(submit.data.displayContent)
                    this.qrcodeShow = true
                })
            }
        },
        //订单再次支付
        goOrderPay() {
            this.$request('/app-api/pay/order/submit', {
                id: this.orderInfo.payOrderId,
                channelCode: 'yl_h5'
            }, 'post').then(submit => {
                this.payId = this.orderInfo.payOrderId
                this.submitQrcode = submit.data.displayContent
                this.qrcodeShow = true
            })
        },
        getOrderInfo() {
            this.refundShow = false
            this.$request('/app-api/trade/order/get-detail', {
                id: this.id
            }, 'get').then(res => {
                for (let i = 0; i < res.data.items.length; i++) {
                    res.data.items[i].select = false
                }
                this.orderInfo = res.data
            })
        },
        back() {
            this.$router.go(-1)
        }
    },
    // 生命周期 - 创建完成（可以访问当前this实例）
    created() {
        this.id = this.$route.query.id
        this.getOrderInfo()
    },
    // 生命周期 - 挂载完成（可以访问DOM元素）
    mounted() {

    },
    beforeCreate() { }, // 生命周期 - 创建之前
    beforeMount() { }, // 生命周期 - 挂载之前
    beforeUpdate() { }, // 生命周期 - 更新之前
    updated() { }, // 生命周期 - 更新之后
    beforeDestroy() { }, // 生命周期 - 销毁之前
    destroyed() { }, // 生命周期 - 销毁完成
    activated() { } // 如果页面有keep-alive缓存功能，这个函数会触发
}
</script>
<style lang='scss' scoped>
.order-button {
    border: 1px solid #999;
    padding: 5px;
    color: #999;
    margin-right: 10px;
}

.colorRed {
    color: #C83732;
}

.colorGlod {
    color: #c5aa7b;
}

.glodSpot {
    border-color: #c5aa7b !important;
}

.redSpot {
    border-color: #C83732 !important;
}

.order-no {
    width: 66%;
    height: 50px;
    line-height: 50px;
    padding: 1% 2%;
    background-color: #fafafa;
    margin: 10px 0;
}

.top-bag {
    font-size: 15px;
}

.back {
    color: #424242;
    background: rgba(226, 119, 31, .3);
    border: 1px solid transparent;
    width: 100px;
    height: 40px;
    line-height: 40px;
    cursor: pointer;
    font-size: 15px;
}
</style>