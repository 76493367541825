<!-- 支付 -->
<template>
    <div class='flexCol alit'>
        <!-- <h2>请选择支付方式</h2> -->
        <div class="pay-style flex justb">
            <div class="flexCol alit pay-type" :class="checkPayIndex == 1 ? 'pay-type-check' : ''"
                @click="checkPayIndex = 1">
                <img src="https://xlqy.obs.cn-southwest-2.myhuaweicloud.com/hotpotNew/order/%E6%94%AF%E4%BB%98-%E5%BE%AE%E4%BF%A1%E6%94%AF%E4%BB%98.png"
                    alt="">
                微信支付
            </div>
            <div class="flexCol alit pay-type" :class="checkPayIndex == 2 ? 'pay-type-check' : ''"
                @click="checkPayIndex = 2">
                <img src="https://xlqy.obs.cn-southwest-2.myhuaweicloud.com/hotpotNew/order/%E6%94%AF%E4%BB%98%E5%AE%9D%E6%94%AF%E4%BB%98.png"
                    alt="">
                支付宝支付
            </div>
            <div class="flexCol alit pay-type" :class="checkPayIndex == 3 ? 'pay-type-check' : ''"
                @click="checkPayIndex = 3">
                <img src="https://xlqy.obs.cn-southwest-2.myhuaweicloud.com/hotpotNew/order/%E4%B8%AA%E4%BA%BA-%E6%94%AF%E4%BB%98.png"
                    alt="">
                个人支付
            </div>
            <div class="flexCol alit pay-type" :class="checkPayIndex == 4 ? 'pay-type-check' : ''"
                @click="checkPayIndex = 4">
                <img src="https://xlqy.obs.cn-southwest-2.myhuaweicloud.com/hotpotNew/order/%E4%BC%81%E4%B8%9A%E6%94%AF%E4%BB%98.png"
                    alt="">
                企业支付
            </div>
        </div>
        <div class="flex justc" style="margin-bottom: 30px;">
            <button class="canal" @click="canal">取消</button>
            <button class="confim" @click="submit">确认</button>
        </div>

     
    </div>
</template>
<script>

export default {
    components: {},
    data() {
        return {
            checkPayIndex: 0,
        }
    },
    // 监听属性 类似于data概念
    computed: {},
    // 监控data中的数据变化
    watch: {},
    // 方法集合
    methods: {
        canal(){
            this.$emit('close')
        },
        submit(){
            this.$emit('goPay',this.checkPayIndex)
        }
    },
    // 生命周期 - 创建完成（可以访问当前this实例）
    created() {

    },
    // 生命周期 - 挂载完成（可以访问DOM元素）
    mounted() {

    },
    beforeCreate() { }, // 生命周期 - 创建之前
    beforeMount() { }, // 生命周期 - 挂载之前
    beforeUpdate() { }, // 生命周期 - 更新之前
    updated() { }, // 生命周期 - 更新之后
    beforeDestroy() { }, // 生命周期 - 销毁之前
    destroyed() { }, // 生命周期 - 销毁完成
    activated() { } // 如果页面有keep-alive缓存功能，这个函数会触发
}
</script>
<style lang='scss' scoped>
.confim {
    width: 200px;
    height: 50px;
    background: rgba(226, 119, 31, .3);
    border-radius: 0;
    color: #424242;
    border: none;
    margin-left: 40px;
    cursor: pointer;
}

.canal {
    width: 200px;
    height: 50px;
    background: #f3f4f5;
    border-radius: 0;
    border: none;
    cursor: pointer;
}

.pay-style {
    width: 900px;
    font-size: 17px;
    color: #494949;
    margin: 40px;

    img {
        width: 150px;
        height: 150px;
        margin-bottom: 15px;
    }

    .pay-type {
        border: 1px solid #f1f1f1;
        cursor: pointer;
        padding: 10px;
        border-radius: 10px;
    }

    .pay-type-check {
        box-shadow: 0px 15px 25px -4px rgba(150, 150, 150, 0.24);
        border: 1px solid #f79412;
    }

    .pay-type:hover {
        box-shadow: 0px 15px 25px -4px rgba(150, 150, 150, 0.24);
    }
}
</style>