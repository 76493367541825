<template>
    <div class="flex justc" style="margin-bottom: 10px;padding: 5px 0; box-shadow: 10px 5px 5px #ccc;">
        <div :class="homeIndex == 0 ? 'home' : 'hov'" style="margin-right: 10px" @click="goUrl('/', 0)">
            首页
        </div>
        <div :class="homeIndex == 1 ? 'home' : 'hov'" @click="goUrl('/origin', 1)" style="margin-right: 10px">
            原产地直供
        </div>
        <div :class="homeIndex == 2 ? 'home' : 'hov'" style="margin-right: 10px"
            @click="goUrl('/oneCountyOneGrade', 2)">
            一县一品
        </div>
        <!-- <div :class="homeIndex == 3 ? 'home' : 'hov'" style="margin-right: 10px" @click="goUrl('deepProcessing',3)">
				精深加工</div> -->
        <!-- <div class="hov" style="margin-right: 10px">进口食材</div> -->
        <div :class="homeIndex == 5 ? 'home' : 'hov'" style="margin-right: 10px" @click="goUrl('/hotPotIndex', 5)">
            火锅指数
        </div>
        <div :class="homeIndex == 7 ? 'home' : 'hov'" style="margin-right: 10px" @click="goUrl('/standard', 7)">行业标准
        </div>
        <div class="hq" style="margin-right: 10px">
            <el-dropdown size="default" trigger="hover" :hide-on-click="true" @command="goUrl">
                <div class="droptitle" :class="homeIndex == 6 ? 'home' : 'hov'">
                    行情分析
                </div>
                <template #dropdown>
                    <el-dropdown-menu>
                        <el-dropdown-item command="ljhq">辣椒行情</el-dropdown-item>
                        <el-dropdown-item command="cphq">产品行情</el-dropdown-item>
                        <el-dropdown-item command="qhrxhq">期货行情</el-dropdown-item>
                    </el-dropdown-menu>
                </template>
            </el-dropdown>
        </div>

        <div :class="homeIndex == 8 ? 'home' : 'hov'" style="margin-right: 10px" @click="goUrl('information', 8)">
            产业资讯</div>
        <div style="margin-right: 10px" class="">
            <!-- <el-dropdown size="default" trigger="hover" :hide-on-click="true">
                <div class="droptitle hov">人才体系</div> -->
            <!-- 招生  就业 人才档案  -->
            <!-- <template #dropdown>
                    <el-cascader-panel size="default" ref="cascade" v-model="value" :options="cascadeList"
                        :props="props" @change="getCas" />
                </template>
            </el-dropdown> -->
        </div>
    </div>

</template>
<script>
import { log } from 'util';

export default {
    data() {
        return {
            homeIndex: 0,
            cascadeList: [{
                label: '火锅师傅',
                value: 'sf', //搜索字段key
                children: [{
                    value: 'zs', //搜索字段 value 招生  就业 人才档案
                    label: '招生',
                },
                {
                    value: 'jy',
                    label: '就业',
                },
                {
                    value: 'rc',
                    label: '人才档案',
                },
                {
                    value: 'rz',
                    label: '认证报名',
                }, {
                    value: 'rz',
                    label: '火锅人才网',
                },
                ],
            },]
        }
    },
    watch: {
        $route(to, from) {
            console.log('to');
            this.changeRouter()
        }
    },
    created() {
        this.changeRouter()
    },
    methods: {
        changeRouter() {
            let route = this.$router.currentRoute.path
            switch (route) {
                case '/':
                    //首页
                    this.homeIndex = 0
                    break;
                case '/origin':
                    //源产地直供
                    this.homeIndex = 1
                    break;
                case '/oneCountyOneGrade':
                    //火锅指数
                    this.homeIndex = 2
                    break;
                case '/hotPotIndex':
                    //火锅指数
                    this.homeIndex = 5
                    break;
                case '/standard':
                    //行业标准
                    this.homeIndex = 7
                    break;
                case '/information':
                    //行业标准
                    this.homeIndex = 8
                    break;
                default:
                    break;
            }

        },
        goUrl(e, index) {
            this.homeIndex = index
            let route = this.$router.currentRoute.path
            if (route == e) {
                return;
            }
            this.$router.push(e)
            // switch (e) {
            //     case '/':
            //         this.$router.push('/')
            //         break;
            //     case 'origin':
            //         this.$router.push('/origin')
            //         break;
            //     case 'hotPotIndex':
            //         this.$router.push('/hotPotIndex')
            //         break;
            //     case 'standard':
            //         this.$router.push('/standard')
            //         break;
            //     default:
            //         break;
            // }
        }
    }
}
</script>
<style>
.hov {
    width: 110px;
    height: 50px;
    text-align: center;
    font-size: 16px;
    font-weight: 500;
    color: #000;
    cursor: pointer;
    line-height: 50px;
}

.hov:hover {
    background: #B20E1F;
    font-size: 16px;
    font-weight: 500;
    color: #fff;
    text-align: center;
}

.home {
    width: 110px;
    height: 50px;
    background: #B20E1F;
    font-size: 16px;
    font-weight: 500;
    color: #fff;
    text-align: center;
    line-height: 50px;
    cursor: pointer;
}
</style>