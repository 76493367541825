<!--  -->
<template>
    <div class='flexCol alit'>
        <div class="page-style">
            <div class="qbsp">全部商品 <a style="color: #c83732;">{{ carCount }}</a></div>
            <div class="list-style">
                <div class="flex top-menu" style="width: 100%;">
                    <div class="flex alit justc" style="width: 10%;margin-left: 20px;">
                        <el-checkbox style="margin-right: 5px;" v-model="seletAll"
                            @change="changeSelectAll"></el-checkbox>全选
                    </div>
                    <div class="" style="width: 30%;">商品名称</div>
                    <div class="" style="width: 12%;">商品规格</div>
                    <div class="" style="width: 12%;">单价</div>
                    <div class="" style="width: 12%;">数量</div>
                    <div class="" style="width: 12%;">小计</div>
                    <div class="" style="width: 12%;">操作</div>
                </div>
                <div class="" v-for="(item, index) in carList" :key="item.shopId">
                    <div class="store-bg">
                        <el-checkbox style="margin-right: 10px;" v-model="carList[index].select"
                            @change="changeStoreSelect($event, item.shopId)"></el-checkbox>店铺：{{ item.shopName
                        }}
                    </div>
                    <div class="flex alit" v-for="(ite, inde) in item.children" :key="ite.id"
                        style="margin-left: 20px;text-align: center;margin: 20px 0 20px 20px;">
                        <div class="flex alit" style="width: 10%;">
                            <el-checkbox style="margin-right: 5px;" v-model="carList[index].children[inde].select"
                                @change="changeCheckBox"></el-checkbox>
                            <img :src="ite.spu.picUrl" alt="">
                        </div>
                        <div class="" style="width: 30%;cursor: pointer;" @click="goGoodsInfo(ite.spu)">{{ ite.spu.name
                            }}
                        </div>
                        <div class="" style="width: 12%;">{{ ite.sku.unitName }}</div>
                        <div class="" style="width: 12%;">￥{{ ite.sku.collectionPrice / 100 }}</div>
                        <div class="" style="width: 12%;">
                            <el-input-number size="mini" v-model="ite.count" :max="ite.sku.stock"
                                :min="ite.sku.sellNum"></el-input-number>
                        </div>
                        <div class="" style="width: 12%;">￥{{ (ite.count * ite.sku.collectionPrice) / 100 }}</div>
                        <div class="" style="width: 12%;">
                            <div class="" style="cursor: pointer;" @click="delShopCar([ite.id])"><i class="el-icon-delete"
                                    style="font-size: 18px;"></i></div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="bottom-info flex justb alit">
                <div class="flex">
                    <div class="" style="margin: 0 40px;cursor: pointer;">删除</div>
                    <div class="">已选商品<a>{{ selectCount }}</a>件</div>
                </div>

                <div class="flex alit">
                    <div class="">
                        <div class="">合计(不含运费)：<a style="font-size: 24px;color:#c83732">￥{{ (money / 100).toFixed(2)
                                }}</a>
                        </div>
                        <div class="" style="text-align: right;margin-top: 5px;">优惠券:￥0.00</div>
                    </div>
                    <div class="submit" @click="submit">结算</div>
                </div>
            </div>
        </div>


    </div>
</template>

<script>

export default {
    components: {},
    data() {
        return {
            carList: [],
            carCount: 0,
            seletAll: false, //全选
            selectCount: 0, //已选数量
            money: 0 //已选金额
        }
    },
    // 监听属性 类似于data概念
    computed: {},
    // 监控data中的数据变化
    watch: {
        carList: {
            handler: function (newVal, oldVal) {
                this.selectCount = 0
                this.money = 0
                let all = true
                for (let i = 0; i < this.carList.length; i++) {
                    let storeAll = true
                    for (let j = 0; j < this.carList[i].children.length; j++) {
                        if (!this.carList[i].children[j].select) {
                            storeAll = false
                        } else {
                            this.selectCount += 1
                            this.money += this.carList[i].children[j].sku.collectionPrice * this.carList[i].children[j].count
                        }
                    }
                    this.carList[i].select = storeAll
                    if (!storeAll) {
                        all = false
                    }
                }
                this.seletAll = all
            },
            deep: true
        }
    },
    // 方法集合
    methods: {
        submit() {
            let list = []
            for (let i = 0; i < this.carList.length; i++) {
                for (let j = 0; j < this.carList[i].children.length; j++) {
                    if (this.carList[i].children[j].select) {
                        console.log(this.carList[i].children[j]);
                        list.push({
                            skuId: this.carList[i].children[j].sku.id,
                            count: this.carList[i].children[j].count,
                            cartId: this.carList[i].children[j].id
                        })
                    }
                }
            }
            this.$router.push({
                path: '/order',
                query: {
                    data: JSON.stringify({
                        items: list,
                        deliveryType: 1,
                        pointStatus: false
                    })

                }
            })
        },
        changeCheckBox() {
            this.$forceUpdate()
        },
        goGoodsInfo(item) {
            this.$router.push({
                path: '/goodsDetail',
                query: item
            })
        },
        //全选店铺
        changeStoreSelect(e, shopId) {
            for (let i = 0; i < this.carList.length; i++) {
                if (this.carList[i].shopId == shopId) {
                    this.carList[i].select = e
                    for (let j = 0; j < this.carList[i].children.length; j++) {
                        this.carList[i].children[j].select = e
                    }
                }
            }
        },
        //全选
        changeSelectAll(e) {
            for (let i = 0; i < this.carList.length; i++) {
                this.carList[i].select = e
                for (let j = 0; j < this.carList[i].children.length; j++) {
                    this.carList[i].children[j].select = e
                }
            }
        },
        //删除购物车数据
        delShopCar(item) {
            this.$confirm('是否从继续购物车中移除?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                this.$request('/app-api/trade/cart/delete', {
                    ids: item
                }, 'delete').then(res => {
                    this.$message({
                        message: '移除成功！',
                        type: 'success'
                    });
                    this.changeCar()
                    this.$emit('changeCar')
                })
            }).catch(() => {
            });
        },
        //获取购物车
        changeCar() {
            this.$request('/app-api/trade/cart/list', {}, 'get').then(res => {
                this.carCount = res.data.validList.length
                let da = []
                let flag = []
                for (let i = 0; i < res.data.validList.length; i++) {
                    if (flag.includes(res.data.validList[i].shopId)) {
                        for (let j = 0; j < da.length; j++) {
                            if (da[j].shopId == res.data.validList[i].shopId) {
                                res.data.validList[i].select = false
                                da[j].children.push(res.data.validList[i])
                            }
                        }
                    } else {
                        da.push({
                            shopName: res.data.validList[i].shopName,
                            shopId: res.data.validList[i].shopId,
                            children: [res.data.validList[i]],
                            select: false
                        })
                        flag.push(res.data.validList[i].shopId)
                    }
                }
                this.carList = da
            })
        },
    },
    // 生命周期 - 创建完成（可以访问当前this实例）
    created() {
        this.changeCar()
    },
    // 生命周期 - 挂载完成（可以访问DOM元素）
    mounted() {

    },
    beforeCreate() { }, // 生命周期 - 创建之前
    beforeMount() { }, // 生命周期 - 挂载之前
    beforeUpdate() { }, // 生命周期 - 更新之前
    updated() { }, // 生命周期 - 更新之后
    beforeDestroy() { }, // 生命周期 - 销毁之前
    destroyed() { }, // 生命周期 - 销毁完成
    activated() { } // 如果页面有keep-alive缓存功能，这个函数会触发
}
</script>
<style lang='scss' scoped>
.bottom-info {
    width: 100%;
    height: 80px;
    background: #f1f2f7;
    margin-top: 30px;
    font-size: 16px;
    color: #333;

    .submit {
        width: 200px;
        text-align: center;
        font-size: 20px;
        color: #fff;
        background-color: #c83732;
        line-height: 80px;
        margin-left: 30px;
    }
}

.qbsp {
    font-size: 20px;
    font-weight: 900;
    margin: 20px 0;
}

.list-style {
    border: 1px solid #f1f2f7;

    .store-bg {
        background: rgba(226, 119, 31, .3);
        height: 60px;
        line-height: 60px;
        padding-left: 20px;
    }

    .top-menu {
        height: 54px;
        line-height: 54px;
        background: #f1f2f7;
        font-size: 16px;
        text-align: center;
    }

    img {
        width: 80px;
        height: 80px;
    }
}

.page-style {
    width: 1250px;
    min-height: 500px;
    margin-bottom: 30px;
}
</style>