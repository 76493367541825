<!--  -->
<template>
    <div class='flexCol alit'>
        <el-form ref="addressForm" :model="addressForm" :rules="rules" label-width="140px">
            <el-form-item label="所在地区：" prop="areaId">
                <el-cascader style="width: 400px;" v-model="addressForm.areaId" :options="addressTree"
                    :props="addProps"></el-cascader>
            </el-form-item>
            <el-form-item label="详细地址：" prop="detailAddress">
                <el-input style="width: 400px;" type="text" v-model="addressForm.detailAddress"></el-input>
            </el-form-item>
            <el-form-item label="收货人姓名：" prop="name">
                <el-input type="text" style="width: 400px;" v-model="addressForm.name"></el-input>
            </el-form-item>
            <el-form-item label="手机号码：" prop="mobile">
                <el-input type="text" style="width: 400px;" v-model="addressForm.mobile"></el-input>
            </el-form-item>
            <el-form-item label="" prop="">
                <el-checkbox v-model="addressForm.defaultStatus">默认地址</el-checkbox>
            </el-form-item>
        </el-form>
        <div class="flex justc" style="margin-top: 10px;">
            <button class="canal" @click="init">取消</button>
            <button class="confim" @click="createAddress">确认</button>
        </div>
    </div>
</template>

<script>

export default {
    components: {},
    data() {
        const checkPhone = (rule, value, callback) => {
            console.log(value);

            if (value === "") {
                callback(new Error("请输入手机号码"));
            } else {
                let reg = /^1[13456789]\d{9}$/;
                if (!reg.test(value)) {
                    callback(new Error("手机号格式错误"));
                } else {
                    callback();
                }
            }
        };
        return {
            addressTree: [],
            addressForm: {
                areaId: '',
                detailAddress: '',
                name: '',
                phone: '',
                defaultStatus: false
            },
            rules: {
                name: [
                    { required: true, message: '请输入收货人姓名', trigger: 'blur' },
                ],
                areaId: [
                    { required: true, message: '请选择收货地址', trigger: 'change' },
                ],
                detailAddress: [
                    { required: true, message: '请输入收货详细地址', trigger: 'blur' },
                ],
                mobile: [
                    { required: true, validator: checkPhone, trigger: 'blur' },
                ],
            },
            addProps: {
                value: 'id',
                label: 'name',
                children: 'children',
                emitPath: false
            }
        }
    },
    props: {
        editAddressInfo: {
            type: Object
        }
    },
    // 监听属性 类似于data概念
    computed: {},
    // 监控data中的数据变化
    watch: {},
    // 方法集合
    methods: {
        getAddTree() {
            this.$request('/app-api/system/area/tree', {}, 'get').then(res => {
                for (let i = 0; i < res.data.length; i++) {
                    for (let j = 0; j < res.data[i].children.length; j++) {
                        for (let k = 0; k < res.data[i].children[j].children.length; k++) {
                            res.data[i].children[j].children[k].children = undefined
                        }
                    }
                }
                this.addressTree = res.data
            })
        },
        createAddress() {
            this.$refs['addressForm'].validate((valid) => {
                if (valid) {
                    if (this.addressForm.id) {
                        //更新
                        this.$request('/app-api/member/address/update', this.addressForm, 'put').then(res => {
                            this.$message({
                                message: '编辑成功！',
                                type: 'success'
                            });
                            this.init()
                        })
                    } else {
                        //新增
                        this.$request('/app-api/member/address/create', this.addressForm, 'post').then(res => {
                            this.$message({
                                message: '新增成功！',
                                type: 'success'
                            });
                            this.init()
                        })
                    }

                }
            })
        },
        init() {
            this.addressForm = {
                areaId: '',
                detailAddress: '',
                name: '',
                phone: '',
                defaultStatus: false
            }
            this.$emit('closeAddress')
            console.log('123123');
        }
    },
    // 生命周期 - 创建完成（可以访问当前this实例）
    created() {
        console.log(this.editAddressInfo);
        if (this.editAddressInfo.id) {
            this.addressForm = this.editAddressInfo
        }
    },
    // 生命周期 - 挂载完成（可以访问DOM元素）
    mounted() {
        this.getAddTree()
    },
    beforeCreate() { }, // 生命周期 - 创建之前
    beforeMount() { }, // 生命周期 - 挂载之前
    beforeUpdate() { }, // 生命周期 - 更新之前
    updated() { }, // 生命周期 - 更新之后
    beforeDestroy() { }, // 生命周期 - 销毁之前
    destroyed() { }, // 生命周期 - 销毁完成
    activated() { } // 如果页面有keep-alive缓存功能，这个函数会触发
}
</script>
<style lang='scss' scoped>
.confim {
    width: 200px;
    height: 50px;
    background: rgba(226, 119, 31, .3);
    border-radius: 0;
    color: #424242;
    border: none;
    margin-left: 40px;
    cursor: pointer;
}

.canal {
    width: 200px;
    height: 50px;
    background: #f3f4f5;
    border-radius: 0;
    border: none;
    cursor: pointer;
}
</style>