<!--  -->
<template>
    <div class=''>
        <div class="list-style flex justb" v-for="(item, index) in addressList" :key="index"
            :class="checkIndex == index ? 'address-check' : ''" @click="checkIndex = index">
            <div class="">
                <div class="flex">
                    <div class="address-title">姓名：</div>
                    <div class="address-desc">{{ item.name }} {{ item.mobile }}</div>
                </div>
                <div class="flex" style=" margin-top: 10px;">
                    <div class="address-title">地址：</div>
                    <div class="address-desc">{{ item.areaName }} {{ item.detailAddress }}</div>
                </div>
            </div>
            <div class="">
                <div class="bj" @click="ednitAddress(item)">编辑</div>
                <div class="mr" v-if="item.defaultStatus">默认地址</div>
            </div>
        </div>
        <div class="flex justc" style="margin-top: 10px;">
            <button class="canal" @click="canalDialog">取消</button>
            <button class="confim" @click="changeAddress">确认</button>
        </div>

        <el-dialog title="编辑收货地址" :visible.sync="addAddressShow" :append-to-body="true">
            <addAddress v-if="addAddressShow" @closeAddress="closeAddress" :editAddressInfo="editAddressInfo"></addAddress>
        </el-dialog>
    </div>
</template>

<script>
import addAddress from './addAddress.vue';
export default {
    components: { addAddress },
    data() {
        return {
            checkIndex: 0,
            addressList: [],
            addAddressShow: false,
            editAddressInfo:{}
        }
    },
    // 监听属性 类似于data概念
    computed: {},
    // 监控data中的数据变化
    watch: {},
    // 方法集合
    methods: {
        closeAddress(){
            this.addAddressShow = false
            this.getAddressList()
        },
        ednitAddress(e){
            this.editAddressInfo = e
            this.addAddressShow = true
        },
        getAddressList() {
            this.$request('/app-api/member/address/list', {}, 'get').then(res => {
                console.log(res);
                this.addressList = res.data
            })
        },
        canalDialog(){
            this.$emit('calal')
        },
        changeAddress(){
            this.$emit('changeAddress',this.addressList[this.checkIndex])
        }
    },
    // 生命周期 - 创建完成（可以访问当前this实例）
    created() {
        this.getAddressList()
    },
    // 生命周期 - 挂载完成（可以访问DOM元素）
    mounted() {

    },
    beforeCreate() { }, // 生命周期 - 创建之前
    beforeMount() { }, // 生命周期 - 挂载之前
    beforeUpdate() { }, // 生命周期 - 更新之前
    updated() { }, // 生命周期 - 更新之后
    beforeDestroy() { }, // 生命周期 - 销毁之前
    destroyed() { }, // 生命周期 - 销毁完成
    activated() { } // 如果页面有keep-alive缓存功能，这个函数会触发
}
</script>
<style lang='scss' scoped>
.confim {
    width: 200px;
    height: 50px;
    background: rgba(226, 119, 31, .3);
    border-radius: 0;
    color: #424242;
    border: none;
    margin-left: 40px;
    cursor: pointer;
}

.canal {
    width: 200px;
    height: 50px;
    background: #f3f4f5;
    border-radius: 0;
    border: none;
    cursor: pointer;
}

.address-check {
    background: rgba(197, 170, 123, .26);
    border: 1px solid #c5aa7b !important;
}

.list-style {
    border: 1px solid #f1f2f7;
    padding: 20px;
    margin-bottom: 10px;
    font-size: 14px;
    cursor: pointer;

    .mr {
        width: 70px;
        height: 25px;
        text-align: center;
        color: #fff;
        background-color: #c5aa7b;
        cursor: pointer;
        line-height: 25px;
        margin-top: 5px;
    }

    .bj {
        color: #c5aa7b;
        text-align: right;
    }

    .address-desc {
        color: #333;
    }

    .address-title {
        color: #999;
    }
}
</style>