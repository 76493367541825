// 安装 npm i esdk-obs-browserjs

import ObsClient from 'esdk-obs-browserjs' //引入obs实例

export default function upload(file) {
	// 创建ObsClient实例
	var obsClient = new ObsClient({
		access_key_id: 'VW5TJSQNOVPNSBNECEAP', // 你的ak
		secret_access_key: 'ctQz7PnlDmmT4seP45ABUT3CVJf8XLgSGjulZQ2U', // 你的sk
		server: 'https://obs.cn-southwest-2.myhuaweicloud.com' // 你的endPoint
	})
	console.log(file);
	return new Promise((resolve, reject) => {
		var time = new Date()
		var addr = time.getFullYear() + '' + (time.getMonth() + 1) + '' + time.getDate()
		addr = 'hotpot/settledMerchant/' + addr + '/'
		var str = file.name.substr(file.name.lastIndexOf('.'))
		var nameStr = addr + time.getTime() + str
		obsClient.putObject({
			Bucket: 'xlqy', //桶名
			Key: nameStr, //路径+文件名称
			SourceFile: file, //文件  必须是file对象或者blob对象
		}, function(err, result) {
			if (err) {
				console.error('Error-->' + err); //错误处理
			} else {
				resolve('https://xlqy.obs.cn-southwest-2.myhuaweicloud.com/' + nameStr)
			}
		});
	})
}