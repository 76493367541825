<!--  -->
<template>
    <div class='' v-if="configInfo">
        <el-form :model="form" :rules="formRule" ref="ruleForm" label-width="100px">
            <el-form-item label="售后方式：" prop="way">
                <el-select v-model="form.way" placeholder="请选择售后方式">
                    <el-option label="仅退款" value="10"></el-option>
                    <el-option label="退货退款" value="20"></el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="申请原因：" prop="applyReason">
                <el-select v-model="form.applyReason" placeholder="请选择申请原因">
                    <el-option :label="item" :value="item"
                        v-for="(item, index) in form.way == 20 ? configInfo.afterSaleReturnReasons : configInfo.afterSaleRefundReasons"
                        :key="index"></el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="补充描述：">
                <el-input type="textarea" v-model="form.applyDescription" style="width: 80%;"
                    placeholder="请输入售后描述"></el-input>
            </el-form-item>
            <el-form-item label="上传凭证：">
                <div class="flex">
                    <div style="position: relative;" class="" v-for="(item, index) in uploadImages" :key="index">
                        <img :src="item" :preview-src-list="uploadImages"
                            style="width: 178px;height: 178px;margin-right: 10px;" alt="">
                        <div class="flex justc alit image-cz" style="">
                            <i class="el-icon-zoom-in" @click="nowImageIndex = index, showViewer = true"></i>
                            <i class="el-icon-delete" style="margin-left: 10px;"
                                @click="uploadImages.splice(index, 1)"></i>
                        </div>
                    </div>
                    <el-upload class="avatar-uploader" action="#" v-if="uploadImages.length < 3" :show-file-list="false"
                        :http-request="uploadImage">
                        <i class="el-icon-plus avatar-uploader-icon"></i>
                    </el-upload>
                </div>
            </el-form-item>
        </el-form>
        <div slot="footer" class="flex justb">
            <div class=""></div>
            <div class=""> <el-button @click="$emit('canal')">取 消</el-button>
                <el-button type="primary" @click="submitRefund">确 定</el-button>
            </div>

        </div>
        <el-image-viewer style="z-index: 999999999999;" :initialIndex="nowImageIndex" :on-close="closeViewer"
            v-if="showViewer" :url-list="uploadImages"></el-image-viewer>
    </div>
</template>

<script>
// 导入组件
import ElImageViewer from "element-ui/packages/image/src/image-viewer";
export default {
    components: { ElImageViewer },
    props: {
        optionsData: {
            type: Object
        }
    },
    data() {
        return {
            nowImageIndex: 0,
            showViewer: false,
            formLabelWidth: 100,
            form: {
                way: '',
                applyReason: '',
                applyDescription: ''
            },
            configInfo: null,
            uploadImages: [],
            formRule: {
                way: [
                    { required: true, message: '请选择售后方式', trigger: 'change' }
                ],
                applyReason: [
                    { required: true, message: '请选择申请原因', trigger: 'change' }
                ],
            }
        }
    },
    // 监听属性 类似于data概念
    computed: {},
    // 监控data中的数据变化
    watch: {},
    // 方法集合
    methods: {
        //提交售后申请
        submitRefund() {
            this.$emit('submitSucc')
            this.$refs['ruleForm'].validate((valid) => {
                if (valid) {
                    this.$request('/app-api/trade/after-sale/create', {
                        orderItemId: this.optionsData.id,
                        refundPrice: this.optionsData.price,
                        way: this.form.way,
                        applyReason: this.form.applyReason,
                        applyDescription: this.form.applyDescription,
                        applyPicUrls: this.uploadImages
                    }, 'post').then(res => {

                    })
                } else {
                    console.log('error submit!!');
                    return false;
                }
            });



        },
        closeViewer() {
            this.showViewer = false
        },
        getConfig() {
            this.$request('/app-api/trade/config/get', {}, 'get').then(res => {
                console.log(res);
                this.configInfo = res.data
            })
        },
        uploadImage(e) {
            console.log(e);

            this.utils.upload(e.file).then(res => {
                console.log(res);
                this.uploadImages.push(res)
            })
        }
    },
    // 生命周期 - 创建完成（可以访问当前this实例）
    created() {

    },
    // 生命周期 - 挂载完成（可以访问DOM元素）
    mounted() {
        console.log('加载了');

        this.getConfig()
    },
    beforeCreate() { }, // 生命周期 - 创建之前
    beforeMount() { }, // 生命周期 - 挂载之前
    beforeUpdate() { }, // 生命周期 - 更新之前
    updated() { }, // 生命周期 - 更新之后
    beforeDestroy() { }, // 生命周期 - 销毁之前
    destroyed() { }, // 生命周期 - 销毁完成
    activated() { } // 如果页面有keep-alive缓存功能，这个函数会触发
}
</script>
<style lang='scss' scoped>
.image-cz {
    position: absolute;
    top: 0;
    left: 0;
    width: 178px;
    height: 178px;

    i {
        font-size: 0px;
    }
}

.image-cz:hover {
    i {
        color: #fff;
        font-size: 25px;
        cursor: pointer;
    }

    background-color: rgba(0, 0, 0, 0.3);
}
</style>