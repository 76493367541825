<!--  -->
<template>
  <div class='flex justc'>
    <div class="leftMenu">
      <div class="leftMenuBox">
        <el-menu :default-active="defaultActive" active-text-color="#C5AA7B" router>
          <el-menu-item index="userInfo">
            <span class="title">
              <i class="el-icon-user"></i>
              个人信息
            </span>
          </el-menu-item>
          <!-- <el-menu-item index="">
            <div class="title flex alit">
              <i class="el-icon-bell"></i>
              我的消息
              <span class="tip" v-if="userInfo.notRead">{{
              userInfo.notRead
               }}</span>
            </div>
          </el-menu-item> -->
          <el-menu-item index="myOrder">
            <span class="title">
              <i class="iconfont icon-dingdan" style="font-size: 20px;margin-right: 8px;"></i>
              我的订单
            </span>
          </el-menu-item>
          <!-- <el-menu-item index="qaList">
            <span class="title">
              <i class="iconfont icon-zhishiwenda" style="font-size: 20px;margin-right: 8px;"></i>
              我的问答
            </span>
          </el-menu-item>
          <el-menu-item index="orderAfterSale">
            <span class="title">
              <i class="iconfont icon-shouhou" style="font-size: 20px;margin-right: 8px;"></i>
              售后订单
            </span>
          </el-menu-item>
          <el-menu-item index="myEvaluate">
            <span class="title">
              <i class="iconfont icon-daipingjia20" style="font-size: 20px;margin-right: 8px;"></i>
              我的评价
            </span>
          </el-menu-item>
          <el-menu-item index="signingAddress">
            <span class="title">
              <i class="iconfont icon-shouhuodizhi" style="font-size: 20px;margin-right: 8px;"></i>
              收货地址
            </span>
          </el-menu-item>
          <el-menu-item index="favorites">
            <span class="title">
              <i class="iconfont icon-shoucang" style="font-size: 20px;margin-right: 8px;"></i>
              我的收藏
            </span>
          </el-menu-item>
          <el-menu-item index="browseRecords">
            <span class="title">
              <i class="iconfont icon-zuji" style="font-size: 20px;margin-right: 8px;"></i>
              浏览足迹
            </span>
          </el-menu-item>
          <el-menu-item index="couponPackage">
            <span class="title">
              <i class="iconfont icon-a-youhuiquankaquanduozhangkaquan" style="font-size: 20px;margin-right: 8px;"></i>
              我的卡券
            </span>
          </el-menu-item>
          <el-menu-item index="changePhone">
            <span class="title">
              <i class="iconfont icon-genghuanshoujihao" style="font-size: 20px;margin-right: 8px;"></i>
              更换手机号
            </span>
          </el-menu-item> -->
        </el-menu>
      </div>
    </div>
    <div class="" :class="$route.path == '/myOrder' ? 'userCenterInfo2' : 'userCenterInfo'">
      <router-view></router-view>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      defaultActive: 'userInfo'
    }
  },
  // 监听属性 类似于data概念
  computed: {},
  // 监控data中的数据变化
  watch: {},
  // 方法集合
  methods: {

  },
  // 生命周期 - 创建完成（可以访问当前this实例）
  created() {

  },
  // 生命周期 - 挂载完成（可以访问DOM元素）
  mounted() {

  },
  beforeCreate() { }, // 生命周期 - 创建之前
  beforeMount() { }, // 生命周期 - 挂载之前
  beforeUpdate() { }, // 生命周期 - 更新之前
  updated() { }, // 生命周期 - 更新之后
  beforeDestroy() { }, // 生命周期 - 销毁之前
  destroyed() { }, // 生命周期 - 销毁完成
  activated() { } // 如果页面有keep-alive缓存功能，这个函数会触发
}
</script>
<style lang='scss' scoped>
.userCenterInfo2{
  width: 60vw;
  min-height: 620px;
}
.userCenterInfo {
  width: 60vw;
  border: 1px solid #e5e5e5;
  min-height: 620px;
}

.leftMenu {
  width: 186px;
  border: 1px solid #e5e5e5;
  margin-right: 30px;
  height: 620px;
  .leftMenuBox {
    :deep(.el-menu) {
      border-right: none;
    }

    :deep(.el-menu-item) {
      padding: 0 !important;

      // display: block;
      .title {
        width: 100%;
        height: 40px;
        padding: 0 8px 0 0;
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
      }

      .tip {
        height: 18px;
        line-height: 18px;
        border-radius: 9px;
        color: #ffffff;
        font-size: 12px;
        padding: 0 5px;
        background-color: #c8ff00;
        position: absolute;
        // right: -18px;
        // top: -8px;
        top: 2px;
        right: 35px;
      }
    }

    :deep(.el-menu-item:focus, .el-menu-item:hover) {
      background: none;
    }

    :deep(.is-active) {
      .title {
        border-left: 4px solid #c8ff00;
        box-sizing: border-box;
      }
    }
  }
}
</style>