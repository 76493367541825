//时间戳转换为 YYYY-MM-DD hh:ss:mm
function changeTime(e) {
  let date = new Date(e);
  let year = date.getFullYear();
  let month = date.getMonth() + 1;
  let day = date.getDate();
  month = month < 10 ? "0" + month : month;
  day = day < 10 ? "0" + day : day;
  let hour = date.getHours();
  let secends = date.getSeconds();
  let mins = date.getMinutes();
  hour = hour < 10 ? "0" + hour : hour;
  secends = secends < 10 ? "0" + secends : secends;
  mins = mins < 10 ? "0" + mins : mins;
  return (
    year + "-" + month + "-" + day + " " + hour + ":" + mins + ":" + secends
  );
}

// 安装 npm i esdk-obs-browserjs
import ObsClient from 'esdk-obs-browserjs' //引入obs实例
function upload(file) {
  // ObsClient.getPolicyEncode()
  // 创建ObsClient实例
  var obsClient = new ObsClient({
    access_key_id: "VW5TJSQNOVPNSBNECEAP", // 你的ak
    secret_access_key: "ctQz7PnlDmmT4seP45ABUT3CVJf8XLgSGjulZQ2U", // 你的sk
    server: "https://obs.cn-southwest-2.myhuaweicloud.com", // 你的endPoint
  });
  return new Promise((resolve, reject) => {
    var time = new Date();
    var addr =
      time.getFullYear() + "" + (time.getMonth() + 1) + "" + time.getDate();
    addr = "hotpot/settledMerchant/" + addr + "/";
    var str = file.name.substr(file.name.lastIndexOf("."));
    var nameStr = addr + time.getTime() + str;
    obsClient.putObject(
      {
        Bucket: "xlqy", //桶名
        Key: nameStr, //路径+文件名称
        SourceFile: file, //文件  必须是file对象或者blob对象
      },
      function (err, result) {
        if (err) {
          console.error("Error-->" + err); //错误处理
        } else {
          resolve(
            "https://xlqy.obs.cn-southwest-2.myhuaweicloud.com/" + nameStr
          );
        }
      }
    );
  });
}

export default {
  changeTime,
  upload,
};
