<template>
    <div class="flexCol alit" style="background-color: #f5f5f5;">
        <div class="top-store flex alit justc">
            <div class="flex alit justb" style="width: 1250px;">
                <div class="flex alit" style="cursor: pointer;" @click="goStore" v-if="goodInfo">
                    <img class="store-logo " :src="goodInfo.shopInfo.shopLogo" alt="">
                    <div class="">
                        <div class="store-title">{{ goodInfo.shopInfo.shopName }}</div>
                        <div class="flex">
                            <div class="flex alit">
                                <div class="store-info-title">商品种类：</div>
                                <div class="store-info-number">{{ goodInfo.shopInfo.categorySum }}</div>
                            </div>
                            <div class="flex alit" style="margin-left: 10px;">
                                <div class="store-info-title">已售：</div>
                                <div class="store-info-number">{{ goodInfo.shopInfo.salesCountSum }}</div>
                            </div>
                        </div>
                    </div>
                </div>
                <button class="back" @click="$router.go(-1)">
                    返回
                </button>
            </div>
        </div>
        <div class="good-info flex" v-if="goodInfo">
            <div style="background-color: #fff;margin-top: 20px;">
                <div class="thumb-example flex" v-if="goodInfo.sliderPicUrls">
                    <!-- swiper2 Thumbs -->
                    <swiper class="swiper gallery-thumbs" :options="swiperOptionThumbs" ref="swiperThumbs">
                        <swiper-slide v-for="item in goodInfo.sliderPicUrls">
                            <img :src="item" style="width: 100%;height: 100%;" alt="">
                        </swiper-slide>
                        <!-- <div class="swiper-button-next " slot="button-next"></div>
                        <div class="swiper-button-prev " slot="button-prev"></div> -->
                    </swiper>
                    <!-- swiper1 -->
                    <swiper class="swiper gallery-top" :options="swiperOptionTop" ref="swiperTop">
                        <swiper-slide v-for="item in goodInfo.sliderPicUrls">
                            <img :src="item" style="width: 100%;height: 100%;" alt="">
                        </swiper-slide>
                    </swiper>
                </div>
            </div>
            <div class="flexCol" style="width: 45%; margin-left: 40px;">
                <div class="good-name">{{ goodInfo.name }}</div>
                <div class="good-desc">{{ goodInfo.introduction }}</div>
                <div class="flex justb" style="margin-top: 20px;">
                    <div class="tj-price flex">
                        <div class="flex">
                            <div class="money-title" style="font-weight: 900;color: red;">单价</div>
                            <div class="money-price" style="font-weight: 900;color: red;">￥</div>
                            <div class="money-yuan" style="font-weight: 900;color: red;">{{ nowCheckSku.unitPrice / 100
                                }}
                            </div>
                            <div class="money-unit" style="font-weight: 900;color: red;">/{{ nowCheckSku.unitName }}
                            </div>
                        </div>
                        <div class="flex" style="margin-left: 10px;">
                            <div class="money-title" style="color: #c83d21;">集采价</div>
                            <div class="money-price" style="color: #c83d21;">￥</div>
                            <div class="money-yuan" style="color: #c83d21;">{{ nowCheckSku.collectionPrice / 100 }}
                            </div>
                            <div class="money-unit" style="color: #c83d21;">/{{ nowCheckSku.unitName }}</div>
                        </div>
                    </div>
                    <div class="flex alit" style="cursor: pointer;"
                        @click="goodInfo.favoriteStatus == 0 ? favorite() : favoriteDelete()">
                        <img src="https://xlqy.obs.cn-southwest-2.myhuaweicloud.com/hotpotNew/shopInfo/%E6%94%B6%E8%97%8F.png"
                            style="width: 20px;height: 20px;" alt="" v-if="goodInfo.favoriteStatus == 1">
                        <img v-else
                            src="https://xlqy.obs.cn-southwest-2.myhuaweicloud.com/hotpotNew/shopInfo/%E6%94%B6%E8%97%8F%20%281%29.png"
                            style="width: 20px;height: 20px;" alt="">
                        <div class="">收藏</div>
                    </div>
                </div>
                <div class="fengexian"></div>
                <div class="psxx" @click="goLogin">配送信息: {{ isLogin ? goodInfo.serviceTime : '点我登录后查看配送信息' }} <br />
                    <a v-if="isLogin">配送至：{{ goodInfo.receivingAddress }}{{ goodInfo.receivingDetailAddress }}</a>
                </div>
                <div class="fengexian"></div>
                <div class="">
                    <div class="goods-info-title">规格:</div>
                    <div class="" v-for="(item, index) in skusList" style="margin-left: 10px;">
                        <div class="sku-title"> {{ item.name }}</div>
                        <div class="flex" style="flex-wrap: wrap;">
                            <div class="sku-value" v-for="ite in item.values" @click="changeSelectSku(ite.id, index)"
                                :class="selectSku.includes(ite.id) ? 'sku-value-check' : ''">
                                {{ ite.name }}
                            </div>
                        </div>
                    </div>

                    <div class="goods-info-title">数量:</div>
                    <div class="flex alit">
                        <!-- @change="handleChange" -->
                        <el-input-number v-model="buyNum" :min="nowCheckSku.sellNum" :max="nowCheckSku.stock"
                            label="描述文字"></el-input-number>
                        <div class="flex" style="font-size: 14px;margin-left: 20px;">
                            库存<a style="color: #c83732;font-weight: 900;margin-left: 3px;">{{ nowCheckSku.stock }}</a>
                        </div>
                    </div>
                    <div class="add-car" @click="addCar" v-if="!goodInfo.virtualcardType">加入采购车</div>
                    <div class="buy" @click="buy">立即购买</div>
                </div>
            </div>
        </div>

        <!-- 商品其他信息 -->
        <goods-other-info v-if="goodInfo" :goodInfo="goodInfo"></goods-other-info>

        <el-dialog title="选择收货地址" :visible.sync="addressShow" :modal-append-to-body="true">
            <addressDialog @changeAddress="changeAddress" @calal="addressShow = false"></addressDialog>
        </el-dialog>

    </div>
</template>
<script>
import 'swiper/dist/css/swiper.css'
import { swiper, swiperSlide } from 'vue-awesome-swiper'
import goodsOtherInfo from './goodsOtherInfo.vue'
import addressDialog from '../userCenter/address/addressDialog.vue';
export default {
    components: {
        swiper,
        swiperSlide, goodsOtherInfo, addressDialog
    },
    data() {
        return {
            addressShow: false,
            buyNum: 1, //购买数量
            isLogin: localStorage.getItem('accessToken') ? true : false, //判断是否登录
            id: '', //商品id
            goodInfo: null, //商品详情
            skusList: [], //规格列表
            nowCheckSku: {}, //当前选择的sku信息
            selectSku: [], //选择的sku信息
            swiper: '',
            swiperOptionTop: {
                // loop: true,
                loopedSlides: 2, // looped slides should be the same
                spaceBetween: 10,

            },
            swiperOptionThumbs: {
                direction: 'vertical',
                // loop: true,
                loopedSlides: 2, // looped slides should be the same
                spaceBetween: 10,
                centeredSlides: true,
                slidesPerView: 'auto',
                touchRatio: 0.2,
                slideToClickedSlide: true,
                navigation: {
                    nextEl: '.swiper-button-next',
                    prevEl: '.swiper-button-prev'
                }
            },
            addressId: ''
        }
    },
    created() {
        this.id = this.$route.query.id
        this.getGoodsDetail()
        window.scrollTo({
            top: 0
        })
    },
    mounted() {

    },
    methods: {
        changeAddress(e) {
            this.addressId = e.id
            this.getGoodsDetail()
            this.addressShow = false
        },
        //取消收藏
        favoriteDelete() {
            this.$request('/app-api/product/favorite/delete', {
                type: 1,
                spuId: this.goodInfo.id,
                shopId: this.goodInfo.shopInfo.id,
            }, 'post').then(res => {
                if (res.data) {
                    this.$message({
                        message: '取消收藏成功!',
                        type: 'success'
                    });
                }
                this.getGoodsDetail()
            })
        },
        //收藏
        favorite() {
            this.$request('/app-api/product/favorite/create', {
                type: 1,
                spuId: this.goodInfo.id,
                shopId: this.goodInfo.shopInfo.id,
            }, 'post').then(res => {
                if (res.data) {
                    this.$message({
                        message: '收藏成功!',
                        type: 'success'
                    });
                }
                this.getGoodsDetail()
            })
        },
        goStore() {
            this.$router.push('/store?id=' + this.goodInfo.shopInfo.id)
        },
        //加入购物车
        addCar() {
            this.$request('/app-api/trade/cart/add', {
                skuId: this.nowCheckSku.id,
                count: this.buyNum,
                shopId: this.goodInfo.shopInfo.id
            }, 'post').then(res => {
                console.log(res);
                this.$message({
                    message: '添加成功',
                    type: 'success'
                });
                this.$emit('changeCar')
            })
        },
        //立即购买
        buy() {
            this.$router.push({
                path: '/order',
                query: {
                    // data: JSON.stringify(res.data)
                    data: JSON.stringify({
                        items: [{
                            skuId: this.nowCheckSku.id,
                            count: this.buyNum
                        }],
                        deliveryType: 1,
                        pointStatus: false
                    })

                }
            })
            // this.$request('/app-api/trade/order/settlement', {
            //     items: [{
            //         skuId: this.nowCheckSku.id,
            //         count: this.buyNum
            //     }],
            //     deliveryType: 1,
            //     pointStatus: false
            // }, 'post').then(res => {
            //     if (res) {

            //     }
            // })
        },
        //改变sku选择
        changeSelectSku(id, index) {
            if (this.selectSku[index] == id) {
                //如果判断到选择的一致  不处理
                return;
            }
            this.selectSku[index] = id
            this.$forceUpdate()

            //对选择的sku做处理 把选择的sku放入当前选择的内
            for (let i = 0; i < this.goodInfo.skus.length; i++) {
                let flag = true   //这里判断是否和选择的sku id是否吻合
                for (let j = 0; j < this.goodInfo.skus[i].properties.length; j++) {
                    if (this.goodInfo.skus[i].properties[j].valueId != this.selectSku[j]) {
                        flag = false
                    }
                }
                if (flag) {
                    this.nowCheckSku = this.goodInfo.skus[i]
                    this.buyNum = this.goodInfo.skus[i].sellNum
                }
            }

        },
        //去登录
        goLogin() {
            if (!this.isLogin) {
                this.$router.push('/login')
            } else {
                this.addressShow = true
            }
        },
        //获取商品详情
        getGoodsDetail() {
            this.$request('/app-api/product/spu/get-detail', {
                id: this.id,
                addressId: this.addressId
            }, 'get').then(res => {
                this.goodInfo = res.data
                this.getSwiper()
                this.skusList = this.convertProductPropertyList(res.data.skus)
                this.nowCheckSku = res.data.skus[0]
                this.buyNum = res.data.skus[0].sellNum
                console.log(this.skusList);
                for (let i = 0; i < this.skusList.length; i++) {
                    this.selectSku.push(this.skusList[i].values[0].id)
                }
            })
        },
        //初始化swiper
        getSwiper() {
            this.$nextTick(() => {
                const swiperTop = this.$refs.swiperTop.swiper
                const swiperThumbs = this.$refs.swiperThumbs.swiper
                swiperTop.controller.control = swiperThumbs
                swiperThumbs.controller.control = swiperTop
            })
        },
        //处理sku值
        convertProductPropertyList(skus) {
            let result = [];
            for (const sku of skus) {
                if (!sku.properties) {
                    continue
                }
                for (const property of sku.properties) {
                    // ① 先处理属性
                    let resultProperty = result.find(item => item.id === property.propertyId)
                    if (!resultProperty) {
                        resultProperty = {
                            id: property.propertyId,
                            name: property.propertyName,
                            values: []
                        }
                        result.push(resultProperty)
                    }
                    // ② 再处理属性值
                    let resultValue = resultProperty.values.find(item => item.id === property.valueId)
                    if (!resultValue) {
                        resultProperty.values.push({
                            id: property.valueId,
                            name: property.valueName
                        })
                    }
                }
            }
            return result;
        }

    }
}
</script>

<style scoped>
.el-input {
    width: auto !important;
}
</style>
<style lang="scss" scoped>
.buy {
    color: #424242;
    width: 100%;
    height: 50px;
    padding: 0;
    text-align: center;
    line-height: 50px;
    cursor: pointer;
    border-radius: 5px;
    border: 1px solid #333333;
}

.add-car {
    color: #424242;
    background: rgba(226, 119, 31, .3);
    width: 100%;
    height: 50px;
    padding: 0;
    text-align: center;
    line-height: 50px;
    cursor: pointer;
    border-radius: 5px;
    margin: 20px 0 10px 0;
}

.goods-info-title {
    font-size: 14px;
    margin-bottom: 10px;
}

.sku-value-check {
    color: #c5aa7b !important;
    border-color: transparent;
    box-shadow: 0 0 10px #0000001a;
}

.sku-value {
    display: inline-block;
    height: 30px;
    line-height: 30px;
    text-align: center;
    padding: 0 20px;
    margin: 10px 8px;
    font-size: 14px;
    color: #666;
    border: 1px solid #e4e5e6;
    cursor: pointer;
    border-radius: 10px;
}

.sku-title {
    font-size: 13px;
    color: #a7a6a6;
}

.good-info {
    width: 1250px;
    background-color: #fff;
    padding: 30px 10px;
    margin-top: 20px;

    .psxx {
        font-size: 12px;
        color: red;
        cursor: pointer;
    }

    .good-desc {
        color: #a7a7a7;
        // white-space: nowrap;
        // overflow: hidden;
        // text-overflow: ellipsis;
    }

    .good-name {
        color: #333;
        font-weight: 700;
        font-size: 30px;
        margin-bottom: 15px;
    }

    .fengexian {
        height: 1px;
        background-color: #e9e9e9fb;
        margin: 20px 0;
    }

    .thumb-example {
        height: 550px;
        background-color: #fff;
        width: 610px;
    }

    .swiper {
        .swiper-slide {
            width: 500px;
        }

        &.gallery-top {
            height: 500px;
            width: 500px;
        }

        &.gallery-thumbs {
            height: 490px;
            width: 100px;
            padding: 0;
            margin-top: 10px;
            background-color: #fff;
            margin-right: 10px;
        }

        &.gallery-thumbs .swiper-slide {
            cursor: pointer;
            width: 100px;
            height: 100px;
            opacity: 0.4;
        }

        &.gallery-thumbs .swiper-slide-active {
            opacity: 1;
        }
    }
}

.top-store {
    width: 100%;
    height: 100px;
    background-color: #fff;
    position: relative;



    .store-info-number {
        font-size: 12px;
        color: #333;
    }

    .store-info-title {
        color: #999;
        font-size: 12px;
    }

    .store-title {
        font-size: 18px;
        color: #333;
        margin-bottom: 10px;
        font-weight: 900;
    }

    .store-logo {
        width: 60px;
        height: 60px;
        margin-right: 10px;
    }
}
</style>