<!--  -->
<template>
    <div class='' style="padding: 80px;">
        <div class="flex">
            <img :src="userInfo.avatar" style="width: 150px;height: 150px;" alt="">
            <div class="info flexCol justc">
                <div class="">昵称：{{ userInfo.nickname }}</div>
                <div class="" style="margin-top: 40px;">绑定手机号：{{ userInfo.mobile }}<a class="change">更换</a></div>
            </div>
        </div>
    </div>
</template>

<script>

export default {
    name: 'UserInfo',
    components: {},
    data() {
        return {
            userInfo: JSON.parse(localStorage.getItem('userInfo'))
        }
    },
    // 监听属性 类似于data概念
    computed: {},
    // 监控data中的数据变化
    watch: {},
    // 方法集合
    methods: {

    },
    // 生命周期 - 创建完成（可以访问当前this实例）
    created() {

    },
    // 生命周期 - 挂载完成（可以访问DOM元素）
    mounted() {

    },
    beforeCreate() { }, // 生命周期 - 创建之前
    beforeMount() { }, // 生命周期 - 挂载之前
    beforeUpdate() { }, // 生命周期 - 更新之前
    updated() { }, // 生命周期 - 更新之后
    beforeDestroy() { }, // 生命周期 - 销毁之前
    destroyed() { }, // 生命周期 - 销毁完成
    activated() { } // 如果页面有keep-alive缓存功能，这个函数会触发
}
</script>
<style lang='scss' scoped>
.info {
    font-size: 16px;
    color: #666;
    margin-left: 60px;
}
.change{
    color: #c5aa7b;
    cursor: pointer;
    margin-left: 10px;
}
</style>