import Vue from "vue";
import VueRouter from "vue-router";
import HomeView from "../views/HomeView.vue"; //首页
import index from "../views/index.vue"; //全局布局
import login from "../views/userCenter/login.vue"; //登录

//-----------个人中心板块-----------
import center from "../views/userCenter/center.vue"; //个人中心
import userInfo from "../views/userCenter/center/userInfo.vue"; //个人信息
import myOrder from "../views/userCenter/center/myOrder.vue"; //个人信息
//-----------个人中心板块结束-------------

import register from "../views/userCenter/register.vue"; //注册
import origin from "../views/origin.vue"; //源产地直供
import goodsDetail from "../views/goods/goodsDetail.vue"; //商品详情
import order from "../views/order/order.vue"; //商品详情
import orderPay from "../views/order/orderPay.vue"; //直接支付
import orderDetail from "../views/order/orderDetail.vue"; //商品详情
import classfiySearch from "../views/classfiySearch.vue"; //根据分类查询商品列表
import shopCar from "../views/order/shopCar.vue"; //采购车
import test from "../views/test.vue"; //采购车
Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "index",
    component: index,
    children: [
      {
        path: "/",
        name: "HomeView",
        component: HomeView,
      },
      {
        path: "/test",
        name: "Test",
        component: test,
      },
      {
        path: "/login",
        name: "Login",
        component: login,
      },
      {
        path: "/center",
        name: "Center",
        component: center,
        children: [
          {
            path: "/userInfo",
            name: "UserInfo",
            component: userInfo,
          },
          {
            path: "/myOrder",
            name: "MyOrder",
            component: myOrder,
          },
        ],
      },
      {
        path: "/register",
        name: "Register",
        component: register,
      },
      {
        path: "/origin",
        name: "Origin",
        component: origin,
        meta: {
          keepAlive: true,
        },
      },
      {
        path: "/goodsDetail",
        name: "GoodsDetail",
        component: goodsDetail,
      },
      {
        path: "/order",
        name: "Order",
        component: order,
      },
      {
        path: "/orderDetail",
        name: "OrderDetail",
        component: orderDetail,
      },
      // {
      //   path: "/orderPay",
      //   name: "OrderPay",
      //   component: orderPay,
      // },
      {
        path: "/classfiySearch",
        name: "ClassfiySearch",
        component: classfiySearch,
        meta: {
          logoText: "",
          searchVisible: true,
          keepAlive: true,
        },
      },
      {
        path: "/shopCar",
        name: "ChopCar",
        component: shopCar,
      },
      //火锅指数
      {
        path: "/hotPotIndex",
        name: "hotPotIndex",
        component: () => import("@/views/hotPotIndex/hotPotIndex"),
        meta: {
          logoText: "",
          searchVisible: true,
          keepAlive: true,
        },
      },
      // 行业标准
      {
        path: "/standard",
        name: "standard",
        component: () => import("@/views/standard/standard"),
        meta: {
          logoText: "",
          searchVisible: true,
          keepAlive: true,
        },
      },
      // 产品行情
      {
        path: "/cphq",
        name: "cphq",
        component: () => import("@/views/quotationsAnalysis/cphq"),
        meta: {
          logoText: "",
          searchVisible: true,
        },
      },
      // 辣椒行情
      {
        path: "/ljhq",
        name: "ljhq",
        component: () => import("@/views/quotationsAnalysis/ljhq"),
        meta: {
          logoText: "",
          searchVisible: true,
        },
      },
      // 期货日线行情
      {
        path: "/qhrxhq",
        name: "qhrxhq",
        component: () => import("@/views/quotationsAnalysis/qhrxhq"),
        meta: {
          logoText: "",
          searchVisible: true,
        },
      },
      // 一县一品
      {
        path: "/oneCountyOneGrade",
        name: "oneCountyOneGrade",
        component: () => import("@/views/oneCountyOneGrade/oneCountyOneGrade"),
        meta: {
          logoText: "",
          searchVisible: true,
          keepAlive: true,
        },
      },
      {
        path: "/information",
        name: "information",
        component: () => import("@/views/information/information"),
        meta: {
          logoText: "",
          searchVisible: true,
          keepAlive: true,
        },
      },
      {
        path: "/newsInfo",
        name: "newsInfo",
        component: () => import("@/views/information/newsInfo"),
        meta: {
          logoText: "",
          searchVisible: true,
        },
      },
      {
        path: "/newsList",
        name: "newsList",
        component: () => import("@/views/information/newsList"),
        meta: {
          logoText: "",
          searchVisible: true,
        },
      },
      {
        path: "/store",
        name: "store",
        component: () => import("@/views/store/store"),
        meta: {
          logoText: "",
          searchVisible: true,
          keepAlive: true,
        },
      },
    ],
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    } else {
      return {
        x: 0,
        y: 0,
      };
    }
  },
});

export default router;
