<!--  -->
<template>
    <div class=''>
        <div class="flex alit" style="width: 100%;background-color: rgba(226,119,31,.3);font-size: 14px;height: 50px;">
            <div class="" style="width: 10%;text-align: center;"></div>
            <div class="" style="width: 30%;">&nbsp;&nbsp;&nbsp;商品</div>
            <div class="" style="width: 12%;text-align: center;">商品规格</div>
            <div class="" style="width: 10%;text-align: center;">数量</div>
            <div class="" style="width: 10%;text-align: center;">单价</div>
            <div class="" style="width: 10%;text-align: center;">状态</div>
        </div>
        <div class="flex alit" style="width: 100%;font-size: 14px;margin: 10px 0;"
            v-for="(item, index) in orderInfo.items" :key="index" @click="changeSelect(index)">
            <div class="" style="width: 10%;text-align: center;" >
                <el-checkbox v-model="orderInfo.items[index].select" v-if="item.status == 20" @change="orderInfo.items[index].select = !orderInfo.items[index].select"></el-checkbox>
            </div>
            <div class="flex alit" style="width: 30%;">
                <img :src="item.picUrl" style="width: 100px;height: 100px;margin-right: 10px;margin-left: 10px;" alt="">
                {{ item.spuName }}
            </div>
            <div class="" style="width: 12%;text-align: center;">{{ item.properties[0].valueName }}</div>
            <div class="" style="width: 10%;text-align: center;">{{ item.count }}</div>
            <div class="" style="width: 10%;text-align: center;">￥{{ item.price / 100 }}</div>
            <div class="" style="width: 10%;text-align: center;">{{ item.status == 30 ? '已收货' : '待收货' }}</div>
        </div>
        <div class="flex justc" style="margin-bottom: 30px;">
            <button class="canal" @click="canal">取消</button>
            <button class="confim" @click="submit">确认收货</button>
        </div>
    </div>
</template>

<script>

export default {
    components: {},
    props: {
        orderInfo: {
            type: Object
        }
    },
    data() {
        return {

        }
    },
    // 监听属性 类似于data概念
    computed: {},
    // 监控data中的数据变化
    watch: {},
    // 方法集合
    methods: {
        submit(){
            let ids = []
            for (let i = 0; i < this.orderInfo.items.length; i++) {
                if(this.orderInfo.items[i].select){
                    ids.push(this.orderInfo.items[i].id)
                }                
            }
            this.$request(`/app-api/trade/order/receive?itemIds=${ids}`, {}, 'put').then(res=>{
                console.log(res);
                if(res.data){
                    this.$emit('taskSuccess')
                }
            })
        },
        canal(){
            this.$emit('canal')
        },
        changeSelect(e) {
            console.log(this.orderInfo);
            if(this.orderInfo.items[e].status == 20){
                this.orderInfo.items[e].select = !this.orderInfo.items[e].select
                this.$forceUpdate()
            }
           
        }
    },
    // 生命周期 - 创建完成（可以访问当前this实例）
    created() {

    },
    // 生命周期 - 挂载完成（可以访问DOM元素）
    mounted() {

    },
    beforeCreate() { }, // 生命周期 - 创建之前
    beforeMount() { }, // 生命周期 - 挂载之前
    beforeUpdate() { }, // 生命周期 - 更新之前
    updated() { }, // 生命周期 - 更新之后
    beforeDestroy() { }, // 生命周期 - 销毁之前
    destroyed() { }, // 生命周期 - 销毁完成
    activated() { } // 如果页面有keep-alive缓存功能，这个函数会触发
}
</script>
<style lang='scss' scoped>
.confim {
    width: 200px;
    height: 50px;
    background: rgba(226, 119, 31, .3);
    border-radius: 0;
    color: #424242;
    border: none;
    margin-left: 40px;
    cursor: pointer;
}

.canal {
    width: 200px;
    height: 50px;
    background: #f3f4f5;
    border-radius: 0;
    border: none;
    cursor: pointer;
}
</style>