<template>
    <div class="flexCol alit">
        <div class="order-style" style="margin-bottom: 30px;">
            <div class="qr flex justb">
                <div class="">请选择收货地址</div>
                <div class="" style="color: #c5aa7b;font-size: 14px;cursor: pointer;" @click="addAddressShow = true">
                    新增地址</div>
            </div>

            <div class="" style="margin: 20px;color: #999;" v-if="!addressInfo">暂无收货地址,请先添加收货地址</div>
            <div class="" style="margin: 20px;" v-else>
                <div class="">收货人信息：</div>
                <div class="flex justb" style="margin-top: 20px;">
                    <div class="flex alit">
                        <div class="address-name">{{ addressInfo.name }}</div>
                        <div class="" style="margin: 0 40px;">{{ addressInfo.areaName }} {{
                            addressInfo.detailAddress }}</div>
                        <div class="">{{ addressInfo.mobile }}</div>
                    </div>
                    <div class="flex alit justc address-change" @click="addressShow = true">
                        <img src="https://xlqy.obs.cn-southwest-2.myhuaweicloud.com/hotpotNew/goods/%E5%88%87%E6%8D%A2.png"
                            alt="">
                        切换
                    </div>
                </div>
            </div>
        </div>
        <div class="order-style" v-if="orderData">
            <div class="qr">请确认订单信息</div>
            <div class="flex top-menu" style="width: 100%;">
                <div class="flex alit justc" style="width: 10%;margin-left: 20px;">
                    商品图片
                </div>
                <div class="" style="width: 30%;">商品名称</div>
                <div class="" style="width: 12%;">商品规格</div>
                <div class="" style="width: 12%;">单价</div>
                <div class="" style="width: 12%;">数量</div>
                <div class="" style="width: 12%;">物流</div>
                <div class="" style="width: 12%;">小计</div>

            </div>

            <div class="flex alit" v-for="(ite, inde) in orderData.items" :key="ite.id"
                style="margin-left: 20px;text-align: center;margin: 20px 0 20px 20px;">
                <div class="flex alit" style="width: 10%;">

                    <img :src="ite.picUrl" alt="">
                </div>
                <div class="" style="width: 30%;cursor: pointer;" @click="goGoodsInfo(ite)">{{ ite.spuName }}
                </div>
                <div class="" style="width: 12%;">{{ ite.properties[0].valueName }}</div>
                <div class="" style="width: 12%;">￥{{ ite.price / 100 }}</div>
                <div class="" style="width: 12%;">
                    {{ ite.count }}
                </div>
                <div class="" style="width: 12%;">
                    {{ changeChargeMode(ite.chargeMode) }}
                </div>
                <div class="" style="width: 12%;">￥{{ (ite.price * ite.count) / 100 }}</div>
            </div>
        </div>

        <div class="other-info" v-if="orderData">
            <el-input type="textarea" placeholder="如有其他要求,请备注信息"></el-input>
        </div>
        <div class="heji" v-if="orderData">
            <!-- <div class="" style="margin-bottom: 10px;">预估总配送费：<a style="color: #c5aa7b;font-weight: 900;">￥{{
                orderData.price.deliveryPrice }}</a>
            </div> -->
            <div class="">店铺合计：<a style="color: #c5aa7b;font-weight: 900;">￥{{ orderData.price.totalPrice / 100 }}</a>
            </div>
        </div>
        <div class="order-style flex bottom-price" v-if="orderData">
            <div class="">预估总配送费：+￥{{ orderData.price.deliveryPrice / 100 }}</div>
            <div class="">原价：￥{{ orderData.price.totalPrice / 100 }}</div>
            <div class="">VIP减免：-￥{{ orderData.price.vipPrice / 100 }}</div>
            <div class="">实际支付金额：<a style="font-weight: 900;color: #c5aa7b;">￥{{ orderData.price.payPrice / 100 }}</a>
            </div>
        </div>
        <div class="flex justc" style="width: 1200px;margin-bottom: 60px;" v-if="orderData">
            <button class="css-button-arrow--red bottom-submit" @click="goOrderPay">立即下单</button>
            <!-- <button class="css-button-arrow--red bottom-submit">申请采购</button> -->
            <!-- <button class="css-button-arrow--red bottom-submit">先货后款</button> -->
        </div>

        <el-dialog title="选择收货地址" :visible.sync="addressShow" :modal-append-to-body="true">
            <addressDialog @changeAddress="changeAddress" @calal="addressShow = false"></addressDialog>
        </el-dialog>
        <el-dialog title="新增收货地址" :visible.sync="addAddressShow">
            <addAddress @closeAddress="closeAddress"></addAddress>
        </el-dialog>

        <el-dialog title="请选择支付方式" :visible.sync="payShow">
            <orderPay @close="payShow = false" @goPay="payOrder"></orderPay>
        </el-dialog>

        <el-dialog title="支付" :visible.sync="qrcodeShow" width="400px">
            <qrcode v-if="qrcodeShow" :text="submitQrcode" :payId="payId" style="width: 300px;"
                @paySuccess="paySuccess"></qrcode>
        </el-dialog>
    </div>
</template>
<script>
import addressDialog from '../userCenter/address/addressDialog.vue';
import addAddress from '../userCenter/address/addAddress.vue';
import orderPay from './orderPay.vue';
import Qrcode from '@/components/qrcode.vue'
export default {
    components: {
        addressDialog, addAddress, orderPay, Qrcode
    },
    data() {
        return {
            orderData: null,
            addressShow: false,//收货地址弹窗
            addAddressShow: false, //新增收货地址弹窗
            payShow: false, //支付弹窗
            addressInfo: null,
            submitQrcode: '',
            qrcodeShow: false,
            payId: ''
        }
    },
    created() {
        // this.orderData = JSON.parse(this.$route.query.data)

        this.getAddressList()
    },
    methods: {
        changeChargeMode(e) {
            switch (e) {
                case 1:
                case 2:
                case 3:
                    return '实付'
                    break;
                case 4:
                    return '包邮'
                    break;
                case 5:
                    return '客户到付(不包邮)'
                    break;
                case 6:
                    return '商家自配'
                    break;
                default:
                    break;
            }
        },
        paySuccess() {
            this.$router.replace('/myOrder')
        },
        //直接支付
        payOrder(index) {
            var data = JSON.parse(this.$route.query.data)
            console.log(data);

            this.$request('/app-api/trade/order/create', {
                commitType: 0,
                addressId: this.addressInfo.id,
                items: data.items,
                deliveryType: data.deliveryType,
                pointStatus: data.pointStatus,
            }, 'post').then(res => {
                if (index == 1 || index == 2) {
                    //微信支付
                    this.$request('/app-api/pay/order/submit', {
                        id: res.data.payOrderId,
                        channelCode: 'yl_h5'
                    }, 'post').then(submit => {
                        console.log(submit);
                        this.payId = res.data.payOrderId
                        this.submitQrcode = submit.data.displayContent
                        this.qrcodeShow = true
                    })
                }
                if (index == 3) {
                    //个人支付
                    this.$request('/app-api/pay/order/submit', {
                        id: res.data.payOrderId,
                        channelCode: 'yl_b2c'
                    }, 'post').then(submit => {
                        this.payId = res.data.payOrderId
                        window.open(submit.data.displayContent)
                        this.qrcodeShow = true
                    })
                }
                if (index == 4) {
                    //个人支付
                    this.$request('/app-api/pay/order/submit', {
                        id: res.data.payOrderId,
                        channelCode: 'yl_b2b'
                    }, 'post').then(submit => {
                        this.payId = res.data.payOrderId
                        window.open(submit.data.displayContent)
                        this.qrcodeShow = true
                    })
                }
            })


        },
        changeAddress(e) {
            console.log(e);
            this.addressInfo = e
            this.settlement(e.id)
            this.addressShow = false
        },
        settlement(addressId) {
            var data = JSON.parse(this.$route.query.data)
            this.$request('/app-api/trade/order/settlement', {
                items: data.items,
                deliveryType: data.deliveryType,
                pointStatus: data.pointStatus,
                addressId: addressId
            }, 'post').then(res => {
                if (res) {
                    this.orderData = res.data
                }
            })
        },
        //直接去支付页面
        goOrderPay() {
            this.payShow = true
            // this.$router.push('orderPay')
        },
        closeAddress(e) {
            this.addAddressShow = false
            this.getAddressList()
        },
        getAddressList() {
            this.$request('/app-api/member/address/list', {}, 'get').then(res => {
                this.addressInfo = res.data[0]
                if (res.data.length > 0) {
                    this.settlement(res.data[0].id)
                }
            })
        }
    }
}
</script>
<style lang="scss">
.address-change {
    background: rgba(180, 94, 24, .4);
    width: 100px;
    height: 40px;
    cursor: pointer;

    img {
        width: 20px !important;
        height: 20px !important;
        margin-right: 5px;
    }
}

.address-name {
    width: 100px;
    height: 40px;
    line-height: 40px;
    text-align: center;
    color: #b39259;
    background-color: #c5aa7b1a;
}

.bottom-submit {
    width: 200px;
    color: #424242;
    background: rgba(180, 94, 24, .4);
    margin: 50px;
}

.bottom-price {
    font-size: 14px;
    color: #333;
    height: 60px;
    line-height: 60px;

    div {
        margin-left: 50px;
    }
}

.heji {
    font-size: 16px;
    text-align: right;
    color: #333;
    width: 1250px;
    margin: 20px 0;
}

.other-info {
    width: 1190px;
    background: #fafafa;
    padding: 30px;
}

.order-style {
    width: 1250px;
    border: 1px solid #f1f2f7;

    .top-menu {
        height: 54px;
        line-height: 54px;
        background: rgba(180, 94, 24, .3);
        font-size: 16px;
        text-align: center;
    }

    img {
        width: 80px;
        height: 80px;
    }

    .qr {
        line-height: 80px;
        padding-left: 30px;
        background-color: #fafafa;
        padding-right: 30px;
    }
}
</style>