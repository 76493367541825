<template>
	<div class="">
		九龙坡区:
		<div class="" v-for="item in quyuData['九龙坡区']">
			{{item.title}} {{item.phone}} {{item.address}} {{item.gb}}
		</div>
		<br/>
		沙坪坝区
		<div class="" v-for="item in quyuData['沙坪坝区']">
			{{item.title}} {{item.phone}} {{item.address}} {{item.gb}}
		</div>
		
			<br/>
			渝中区
		<div class="" v-for="item in quyuData['渝中区']">
			{{item.title}} {{item.phone}} {{item.address}} {{item.gb}}
		</div>
		
			<br/>北部新区
		<div class="" v-for="item in quyuData['北部新区']">
			{{item.title}} {{item.phone}} {{item.address}} {{item.gb}}
		</div>
		
			<br/>南岸区
		<div class="" v-for="item in quyuData['南岸区']">
			{{item.title}} {{item.phone}} {{item.address}} {{item.gb}}
		</div>
			<br/>江北区
		<div class="" v-for="item in quyuData['江北区']">
			{{item.title}} {{item.phone}} {{item.address}} {{item.gb}}
		</div>
			<br/>渝北区
		<div class="" v-for="item in quyuData['渝北区']">
			{{item.title}} {{item.phone}} {{item.address}} {{item.gb}}
		</div>
			<br/>两江新区
		<div class="" v-for="item in quyuData['两江新区']">
			{{item.title}} {{item.phone}} {{item.address}} {{item.gb}}
		</div>
	</div>
</template>

<script>
	export default{
		data(){
			return{
				quyuData: {
						'九龙坡区': [{
							title: '刘一手总店',
							phone: '023-68695390',
							address: '九龙坡区石桥铺科园三街68号',
							gb: '贵宾专属联系：杨志江 13127624195'
						}, {
							title: '莺花渡火锅(万象城店)',
							phone: '023-67460666',
							address: '九龙坡区万象城南区演艺广场L2',
							gb: '贵宾专属联系：梁颖 15923376951'
						}, {
							title: '二筒老火锅(杨家坪动物园店)',
							phone: '18375945542',
							address: '九龙坡区西郊路30号',
							gb: '贵宾专属联系：卓晓兵 13677680888'
						}, {
							title: '周师兄杨家坪店',
							phone: '18680965959',
							address: '花半里路6号附33号(保利时代广场旁)',
							gb: '贵宾专属联系：莫春燕 15002332894'
						}],
						'沙坪坝区': [{
							title: '刘一手万达店',
							phone: '023-65099197',
							address: '沙坪坝区经纬大道万达广场4楼4051号',
							gb: '贵宾专属联系：杨志江 13127624195'
						}, {
							title: '刘一手磁器口店',
							phone: '023-65376467',
							address: '沙坪坝区磁童路沙磁1938童新一村22号附1号',
							gb: '贵宾专属联系：杨志江 13127624195'
						}, {
							title: '刘一手磁器口二店',
							phone: '023-65090636',
							address: '沙坪坝区磁童路沙磁1938童新一村22号',
							gb: '贵宾专属联系：杨志江 13127624195'
						}, {
							title: '莺花渡火锅(金沙天街店)',
							phone: '19122067712',
							address: '沙坪坝区金沙天街B馆L5层',
							gb: '贵宾专属联系：梁颖 15923376951'
						}],
						'渝中区': [{
							title: '刘一手臻品店',
							phone: '023-63033910',
							address: '渝中区民生路9号',
							gb: '贵宾专属联系：杨志江 13127624195'
						}, {
							title: '刘一手好吃街店',
							phone: '023-63515550',
							address: '渝中区八一路好吃街183号4楼',
							gb: '贵宾专属联系：杨志江 13127624195'
						}, {
							title: '刘一手东水门店',
							phone: '13808322579',
							address: '渝中区朝天门街道朝东路137号(东水门A4栋二楼)',
							gb: '贵宾专属联系：杨志江 13127624195'
						}, {
							title: '莺花渡火锅(陆海国际门)',
							phone: '023-63322217',
							address: '渝中区化龙桥陆海国际印象城L6',
							gb: '贵宾专属联系：梁颖 15923376951'
						}, {
							title: '德庄火锅(时代天街店)',
							phone: '023-68590075、19112833566',
							address: '渝中区长江二路174号(近长城宾馆、马家堡车站)',
							gb: '贵宾专属联系：李继红 13983149690'
						}, {
							title: '记忆叁城火锅',
							phone: '13677680888',
							address: '渝中区朝东路121号附6号',
							gb: '贵宾专属联系：卓晓兵 13677680888'
						}, {
							title: '小天鹅火锅(洪鼎店)',
							phone: '023-63039901',
							address: '渝中区洪崖洞景区4楼',
							gb: '贵宾专属联系：陈志均 13883741096'
						}, {
							title: '小天鹅火锅(传统店)',
							phone: '023-63661940',
							address: '渝中区洪崖洞景区4楼',
							gb: '贵宾专属联系：陈志均 13883741096'
						}, {
							title: '小天鹅火锅(英利店)',
							phone: '023-63039901',
							address: '渝中区解放碑英利大融城6楼',
							gb: '贵宾专属联系：陈志均 13883741096'
						}, {
							title: '重庆巴将军非遗原味火锅(解放碑店)',
							phone: '023-63917608',
							address: '渝中区民权路17号附3楼',
							gb: '贵宾专属联系：周楠 18983762287'
						}, {
							title: '杨光会鲜货火锅大坪店',
							phone: '023-68890227',
							address: '渝中区时代天街10栋sp6-0259',
							gb: '贵宾专属联系：廖勇 18580723860'
						}],
						'北部新区': [{
							title: '莺花渡火锅(蓝湖郡店)',
							phone: '023-67391555',
							address: '北部新区蓝湖郡商业街龙宁路29号',
							gb: '贵宾专属联系：梁颖 15923376951'
						}, {
							title: '家一加火锅(绿地翠谷店)',
							phone: '15608344999',
							address: '北部新区经开园湖影街4号附3—5、附7—9号',
							gb: '贵宾专属联系：张晨怡 17384088088'
						}, {
							title: '德庄火锅(龙头寺店)',
							phone: '023-63118688',
							address: '北部新区东湖南路333号(新溉路与东湖南路交汇处)中渝.爱都会一栋',
							gb: '贵宾专属联系：李继红 13983149690'
						}],
						'南岸区': [{
							title: '莺花渡火锅(长嘉汇店)',
							phone: '19922924311',
							address: '南岸区长嘉汇购物公园L3层',
							gb: '贵宾专属联系：梁颖 15923376951'
						}, {
							title: '德庄火锅(茶园悦地店)',
							phone: '023-62629065',
							address: '南岸区通江大道186号附1号悦地购物中心4楼',
							gb: '贵宾专属联系：李继红 13983149690'
						}, {
							title: '德庄火锅(东原1891店)',
							phone: '023-62876888、18008383425',
							address: '南滨路东原1891D馆3楼',
							gb: '贵宾专属联系：李继红 13983149690'
						}, {
							title: '龙门九号火锅',
							phone: '023-62933899',
							address: '南岸区龙门浩老街96号(原美国使馆酒吧)',
							gb: '贵宾专属联系：唐小雁 13272636821'
						}, {
							title: '锅锅筵水八块老火锅',
							phone: '023-62625998',
							address: '南岸区南滨路两江壹号D1-2栋2楼',
							gb: '贵宾专属联系：晏海燕 18225441028'
						}, {
							title: '渝宗火锅长嘉汇店',
							phone: '19122548257',
							address: '南岸区泰昌路64号',
							gb: '贵宾专属联系：蹇乐川 15111875537'
						}, {
							title: '朝天门火锅长嘉汇店',
							phone: '13983851688',
							address: '南岸区泰昌路64号4楼',
							gb: '贵宾专属联系：沈桃 13983851688'
						}, {
							title: '杨光会鲜货火锅南坪总店',
							phone: '023-62399106',
							address: '南岸区协信城2栋1-2门市',
							gb: '贵宾专属联系：廖勇 18580723860'
						}, {
							title: '周师兄南坪万达店',
							phone: '13440082111',
							address: '南坪珊瑚路1号(民生银行旁)',
							gb: '贵宾专属联系：莫春燕 15002332894'
						}],
						'江北区': [{
							title: '莺花渡火锅(江北嘴店)',
							phone: '18183065338',
							address: '北滨二路三洞桥PAPK3楼(近国金中心)',
							gb: '贵宾专属联系：梁颖 15923376951'
						}, {
							title: '家一加火锅(鎏嘉码头店)',
							phone: '18584507888',
							address: '江北区北滨一路185号附10号3-2',
							gb: '贵宾专属联系：张晨怡 17384088088'
						}, {
							title: '二筒老火锅(观音桥总店)',
							phone: '13677680888',
							address: '江北区建兴北路(宏福俊悦大厦)',
							gb: '贵宾专属联系：卓晓兵 13677680888'
						}, {
							title: '百宗杀牛场火锅',
							phone: '023-67967698',
							address: '江北区北城2路25号',
							gb: '贵宾专属联系：钱经理 13541852585'
						}, {
							title: '醉相亲老火锅',
							phone: '023-67966066',
							address: '江北区江北嘴汇川门路6号附8号',
							gb: '贵宾专属联系：唐传生 13983357996'
						}, {
							title: '渝宗火锅珠江店',
							phone: '023-67116006',
							address: '江北区北城路珠江太阳城D区76号附12号',
							gb: '贵宾专属联系：蹇乐川 15111875537'
						}, {
							title: '周师兄大石坝店',
							phone: '18717056730',
							address: '红石路东原悦荟购物中心5楼(大石坝地铁站旁)',
							gb: '贵宾专属联系：莫春燕 15002332894'
						}],
						"渝北区": [{
								"title": "莺花渡火锅(爱融荟店)",
								"phone": "023-67361777",
								"address": "渝北区新南路429号爱融荟城A区L2层",
								"gb": "贵宾专属联系：梁颖 15923376951"
							},
							{
								"title": "家一加火锅(中央公园店)",
								"phone": "17318212208",
								"address": "渝北区仙桃街道居易天城8号楼东侧第二层175—182号",
								"gb": "贵宾专属联系：张晨怡 17384088088"
							},
							{
								"title": "二筒老火锅(龙脊店)",
								"phone": "023-81906677",
								"address": "渝北区龙脊广场",
								"gb": "贵宾专属联系：卓晓兵 13677680888"
							},
							{
								"title": "豪渝火锅",
								"phone": "023-63109177",
								"address": "渝北区黄龙路33号1幢1-1号",
								"gb": "贵宾专属联系：李美芬 18379299826"
							},
							{
								"title": "渝宗火锅黄泥磅店",
								"phone": "023-67081223",
								"address": "渝北区紫康路148号",
								"gb": "贵宾专属联系：蹇乐川 15111875537"
							},
							{
								"title": "渝宗火锅水晶店",
								"phone": "023-63211010",
								"address": "渝北区青枫南路静苑3号",
								"gb": "贵宾专属联系：蹇乐川 15111875537"
							}
						],
						"两江新区": [{
								"title": "家一加火锅(星汇财富店)",
								"phone": "15502347666",
								"address": "两江新区云杉南路6号附50号",
								"gb": "贵宾专属联系：张晨怡 17384088088"
							},
							{
								"title": "家一加火锅(一奥天地店)",
								"phone": "17302312966",
								"address": "两江新区鸳鸯街道金渝大道87号附68号",
								"gb": "贵宾专属联系：张晨怡 17384088088"
							},
							{
								"title": "家一加火锅(照母山金科店)",
								"phone": "17723001888",
								"address": "两江新区鸳鸯街道湖山路195号附21号",
								"gb": "贵宾专属联系：张晨怡 17384088088"
							}
						]
				
						// ,{
						// 	title:'',
						// 	phone:'',
						// 	address:'',
						// 	gb:''
						// }
					}
				
			}
		}
	}
</script>

<style>
</style>